import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiMainService } from '@shared/services/api.main.service';
import { CookiesService } from '@shared/services/cookie.service';
import { NotificationService } from '@shared/services/notification.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { IGuide } from '@shared/models/upload/upload.models';
import { FullNameComponent } from '@shared/grid/full-name/full-name.component';
import { CurrencyComponent } from '@shared/grid/currency/currency.component';
import { DateTimeComponent } from '@shared/grid/date-time/date-time.component';
import { forkJoin } from 'rxjs';
import { IconGridComponent } from '@shared/grid/icon/icon.component';
import { RolesComponent } from '@shared/grid/roles/roles.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimeService } from '@shared/services/time.service';
import { RoleService } from '@shared/services/role.service';
import { catchError } from 'rxjs/operators';
import { DialogService } from 'primeng';
import * as fromModals from '@components/dialog-components';
import { ALL_ROLES } from '@shared/constans/default';
import { DeletingActionComponent } from '@shared/grid/deleting-action/deleting-action.component';
import { LanguageSubjectService } from 'app/service/language.subject.service';

@Component({
  selector: 'ec-view-single-company',
  templateUrl: './view-single-company.component.html',
  styleUrls: ['./view-single-company.component.scss'],
})
export class ViewSingleCompanyComponent implements OnInit {
  @ViewChild('operatorsRef') operatorsRef;

  public company: any = {};
  public loaded = false;
  public user: any = {};
  public currentGuide: any = {};
  public sending: boolean = false;
  public loadExport: boolean = false;
  maxDate: Date = new Date();
  gridOptions: GridOptions = {
    suppressCellSelection: true,
    suppressHorizontalScroll: true,
    suppressRowTransform: true,
  };

  rowData: any = [];

  selected: IGuide;
  defaultColDef: ColDef = {
    sortable: true,
    filter: false,
    resizable: false,
    unSortIcon: false,
    sortingOrder: ['asc', 'desc'],
    suppressMovable: true,
  };
  columnDefs: ColDef[] = [
    {
      headerName: 'Operator',
      field: 'operator',
      cellRendererFramework: FullNameComponent,
      cellRendererParams: 'user',
      valueGetter: (params) => {
        return `${params.data.operator.firstName} ${params.data.operator.lastName}`;
      },
    },
    {
      headerName: 'Course Name',
      field: 'guide.name',
    },
    {
      headerName: 'Price',
      field: 'invoice',
      width: 100,
      cellRendererFramework: CurrencyComponent,
      suppressSizeToFit: true,
      cellRendererParams: 'user',
      valueGetter: (params) => {
        return `${params.data.invoice.currency} ${params.data.invoice.amount}`;
      },
    },
    {
      headerName: 'Date',
      field: 'updated_at',
      width: 150,
      suppressSizeToFit: true,
      cellRendererFramework: DateTimeComponent,
      cellRendererParams: {
        format: 'MMM dd, yyyy',
      },
    },
    {
      headerName: 'Status',
      field: 'status.type',
      width: 150,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Certification Status',
      field: 'certificationStatus.type',
    },
  ];
  employeesColumnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      cellRendererFramework: IconGridComponent,
      cellRendererParams: 'user',
      valueGetter: (params) => {
        return `${params.data.firstName} ${params.data.lastName}`;
      },
    },
    {
      headerName: 'Email',
      field: 'email',
    },
    {
      headerName: 'Roles',
      field: 'roles',
      cellRendererFramework: RolesComponent,
      onCellClicked: this.roleCellClicked.bind(this),
      cellStyle: {
        cursor: 'pointer',
      },
      tooltipField: 'Click ror manage roles',
    },
    {
      headerName: 'Last Activity',
      field: 'lastActivity',
      width: 110,
      suppressSizeToFit: true,
      cellRendererFramework: DateTimeComponent,
      cellRendererParams: {
        format: 'MMM dd, yyyy',
      },
    },
    {
      headerName: 'Suspend action',
      valueGetter: (params) => {
        return params.data;
      },
      width: 140,
      cellRendererFramework: DeletingActionComponent,
    },
  ];
  datesForm: FormGroup;
  public guest: any;
  public roleSelected: any;
  public allRoles = ALL_ROLES;
  private language: string = localStorage.getItem('Language')
  ? localStorage.getItem('Language')
  : 'no';
  constructor(
    private apiService: ApiMainService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookiesService,
    private notification: NotificationService,
    private formBuilder: FormBuilder,
    private dateService: TimeService,
    public role: RoleService,
    public dialogService: DialogService,
    private languageSubjectService: LanguageSubjectService
  ) {}

  ngOnInit(): void {
    this.guest = this.role.user;
    if (this.role.adminRole() && !this.role.masterRole()) {
      this.allRoles.splice(0, 1);
    }
    this.route.params.subscribe((params) => {
      this.loaded = false;
      forkJoin(
        this.apiService.getCompany(+params.id),
        this.apiService.getUserGuideList({ companyId: +params.id })
      ).subscribe((result) => {
        if (!(result instanceof HttpErrorResponse)) {
          this.company = result[0].data;
          this.rowData = result[1];
          console.log(this.company);
        }
        this.loaded = true;
      });
    });
    this.storageChange();
  }

  roleCellClicked(e) {
    if (this.permissions()) {
      this.roleSelected = e.data || e;
      this.allRoles.map(
        (r) => (r.checked = this.roleSelected.roles.some((v) => v.id === r.id))
      );
    }
  }

  permissions() {
    return (
      this.role.masterRole() ||
      (this.role.adminRole() && +this.guest.companyId === +this.company.id)
    );
  }

  changeRoleStatus(e, r) {
    if (!e.target.checked && this.roleSelected.roles.length === 1) {
      this.notification.send(
        this.language === 'en'
          ? 'User should have at least one role!'
          : 'Brukeren må ha minst en rolle',
        'warning'
      );
      return (this.roleSelected = null);
    }
    this.apiService
      .manageUserRole({
        userId: this.roleSelected.id,
        roleId: r.id,
        adding: e.target.checked,
      })
      .subscribe((res) => {
        console.log(res);
        console.log(this.language);
        if (!(res instanceof HttpErrorResponse)) {
          this.notification.send(
            e.target.checked
              ? this.language === 'en'
                ? `User ${this.roleSelected.firstName} ${this.roleSelected.lastName} successfully get role: ${r.type}!`
                : `Bruker ${this.roleSelected.firstName} ${this.roleSelected.lastName} fikk en rolle ${r.type}`
              : this.language === 'en'
              ? `Role ${r.type} for user ${this.roleSelected.firstName} ${this.roleSelected.lastName} removed!`
              : `Rollen ${r.type} til bruker ${this.roleSelected.firstName} ${this.roleSelected.lastName} ble fjernet.`,
            e.target.checked ? 'info' : 'warning'
          );
          if (e.target.checked) {
            this.roleSelected.roles.push({ id: r.id });
          } else {
            this.roleSelected.roles.splice(
              this.roleSelected.roles.findIndex((role) => role.id === r.id),
              1
            );
            console.log(this.roleSelected.roles);
          }
          this.company.employees[
            this.company.employees.findIndex(
              (emp) => emp.id === this.roleSelected.id
            )
          ] = this.roleSelected;
          // this.operatorsRef.rowData = this.company.employees;
          this.operatorsRef.gridList.api.setRowData(this.company.employees);
          console.log(this.operatorsRef, res);
        }
      });
  }

  hideTooltip() {
    this.roleSelected = null;
  }

  initForm() {
    this.datesForm = this.formBuilder.group({
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
    });
  }

  exportSelected() {
    this.loadExport = true;
    console.log(this.datesForm.value);
    this.apiService
      .exportInvoices({
        startDate: this.dateService.formatting(
          this.datesForm.get('startDate').value
        ),
        endDate: this.dateService.formatting(
          this.datesForm.get('endDate').value
        ),
        search: '',
        companyId: this.company.id,
      })
      .subscribe((blob) => {
        if (!(blob instanceof HttpErrorResponse)) {
          const a = document.createElement('a');
          document.body.appendChild(a);

          a.setAttribute('style', 'display: none');
          const downloadUrl = URL.createObjectURL(blob);
          a.href = downloadUrl;
          const form = this.datesForm.value;
          a.download = `${this.formatDate(form.startDate)}-${this.formatDate(
            form.endDate
          )}-${new Date().getTime()}.xlsx`;
          a.click();
          this.notification.send(
            this.language === 'en'
              ? 'Report file generated successfully and sent via email!'
              : 'Rapportfilen ble generert og sendt på e-post.',
            'info'
          );
          window.URL.revokeObjectURL(downloadUrl);
          document.body.removeChild(a);
        }
        this.loadExport = false;
        this.resetDates();
      });
  }

  resetDates() {
    this.datesForm = null;
  }

  formatDate(date: string): string {
    const d: Date = new Date(date);
    return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
  }

  get roleAllowed() {
    return (
      this.role.masterRole() ||
      (this.role.adminRole() && +this.guest.companyId === +this.company.id)
    );
  }

  generateLink() {
    this.apiService.generateLink(this.company.id).subscribe((res) => {
      if (!(res instanceof HttpErrorResponse)) {
        console.log(res.data);
        this.dialogService.open(fromModals.SharedLinkComponent, {
          data: res.data,
          styleClass: 'primeng-dialog-container',
          dismissableMask: true,
        });
      }
    });
  }

  public viewUser(user) {
    this.router.navigate(['/', 'console', 'operator', user.id]);
  }

  public viewProcess(process) {
    this.router.navigate(['/', 'system', 'process', process.id]);
  }
  private storageChange() {
    this.languageSubjectService.watchStorage().subscribe((lang: string) => {
      this.language = lang;
    });
  }
}

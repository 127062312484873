<table class="card-table">
  <tr>
    <th>{{'person-card.name' | translate}}:</th>
    <td>{{ person.name }}</td>
  </tr>
  <tr>
    <th>{{'person-card.phone' | translate}}:</th>
    <td>{{ person.phone || 'grid.title.not-filled' | translate }}</td>
  </tr>
  <tr>
    <th>{{'person-card.email' | translate}}:</th>
    <td>{{ person.email || 'grid.title.not-filled' | translate }}</td>
  </tr>
</table>

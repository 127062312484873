<ec-modal #ConfirmationModal
           [header]="'Confirmation'"
           [closable]="false"
           [showHeader]="false"
           [includeFooter]="false">
  <div class="confirmation-content">
    <div class="icon">
      <img src="../../../assets/icons/vector.svg" alt="">
    </div>
    <div class="card-body">
      {{ confirmAction.message }}
    </div>
    <div class="footer">
      <button class="btn save-btn" (click)="onAccept()">
        Yes
      </button>
      <button class="btn save-btn" (click)="onReject()">
        No
      </button>
    </div>
  </div>
</ec-modal>

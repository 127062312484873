import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageSubjectService {
  private storageSubject = new Subject<string>();
  constructor() {}

  watchStorage(): Observable<any> {
    return this.storageSubject.asObservable();
  }

  setEng() {
    localStorage.removeItem('Language');
    localStorage.setItem('Language', 'en');
    this.storageSubject.next('en');
  }
  setNor() {
    localStorage.removeItem('Language');
    localStorage.setItem('Language', 'no');
    this.storageSubject.next('no');
  }
}

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IRole } from '@shared/interfaces/IResponse';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'ec-controls-cell',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent implements ICellRendererAngularComp {
  value: IRole[] = [];
  all = [
    {
      id: 1,
      type: 'Oslo Admin',
      checked: false
    },
    {
      id: 2,
      type: 'Company Admin',
      checked: false
    },
    {
      id: 4,
      type: 'Operator',
      checked: false
    }
  ];
  selected: any;
  hovered: boolean = false;
  @ViewChild('element') element: ElementRef;
  position: { top: string, left: string } = {
    top: '0',
    left: '0',
  };
  @Input() key: string = 'notes';
  params: any;

  agInit(params: any): void {
    this.params = params;
    if (this.params) {
      this.value = this.params.value.roles;
    }
  }

  refresh(): boolean {
    return false;
  }

  show(value) {
    //   this.selected = this.element.nativeElement.offsetParent.offsetParent.classList.contains('ag-row-selected');
    //   this.hovered = true;
    //   this.all.map(r => r.checked = this.value.some(v => v.id === r.id));
    //   const rect = this.element.nativeElement.getBoundingClientRect();
    //   const  windowHeight = document.documentElement.clientHeight;
    //   const  toolTipHeight = this.toolTip.nativeElement.scrollHeight;
    //   const  formula = rect.top + toolTipHeight + 35;
    //
    //   if (formula > windowHeight) {
    //     this.position = {
    //       top: `${rect.top - toolTipHeight + 11}px`,
    //       left: `${rect.left - 275}px`,
    //     };
    //   } else {
    //     this.position = {
    //       top: `${rect.top + 35}px`,
    //       left: `${rect.left - 275}px`,
    //     };
    //   }
  }

  hide() {
    console.log('hide....');
    event.stopImmediatePropagation();
    this.hovered = false;
  }

}

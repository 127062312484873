import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { Subscription } from 'rxjs';
import {ConfirmationService} from '../../service/confirmation.service';
import {Confirmation} from '@shared/interfaces/Confirmation';

@Component({
  selector:    'ec-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls:   [ './confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements AfterViewInit, OnDestroy {

  @ViewChild('ConfirmationModal', { static: true }) confirmationModal: ModalComponent;

  private subscription: Subscription = new Subscription();

  public confirmAction: Confirmation = {};

  constructor(
    private confirmationService: ConfirmationService
  ) { }

  public onAccept() {
    if (this.confirmAction.accept) {
      this.confirmAction.accept();
    }
    this.close();
  }

  public onReject() {
    if (this.confirmAction.reject) {
      this.confirmAction.reject();
    }
    this.close();
  }

  private close() {
    this.confirmationModal.close();
  }

  private open() {
    this.confirmationModal.open();
  }

  ngAfterViewInit() {
    // tslint:disable-next-line:no-unused-expression
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.confirmationService.status$
      .subscribe(props => {
        this.confirmAction = props;
        this.open();
      });
  }

  ngOnDestroy() {
    // tslint:disable-next-line:no-unused-expression
    this.subscription && this.subscription.unsubscribe();
  }

}

import { Component, OnInit } from '@angular/core';
import { IGuide } from '@shared/models/upload/upload.models';
import { ApiMainService } from '@shared/services/api.main.service';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'ec-view-single-guide-page',
  templateUrl: './view-single-guide-page.component.html',
  styleUrls: ['./view-single-guide-page.component.scss']
})
export class ViewSingleGuidePageComponent implements OnInit {
  public guide: IGuide;
  public loaded = false;
  pdfSrc: string;
  zoomStep: number = 0.25;
  numPages: number = 0;
  reset: boolean = false;
  page: number;
  angle: number;
  zoom: number = 1;
  constructor(
    private apiService: ApiMainService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getGuide(this.route.snapshot.params.guideId);
    this.page = this.route.snapshot.params.pageNumber;
  }

  getGuide(id: number) {
    this.apiService.getGuide(id).subscribe((result) => {
      if (!(result instanceof HttpErrorResponse)) {
        this.guide = result.data;
        this.pdfSrc = this.guide.resource[0].path;
      }
      this.loaded = true;
    });
  }

  onLoad(event) {
    this.numPages = event.numPages;
  }

  rotate(direction: number) {
    this.angle += direction;
  }

  zoomIn() {
    if (this.zoom < 5) {
      this.zoom += this.zoomStep;
    }
  }

  zoomOut() {
    if (this.zoom > 0.25) {
      this.zoom -= this.zoomStep;
    }
  }

  resetZoom() {
    this.zoom = 1;
    this.reset = false;
  }

  toggleReset(state: boolean) {
    if (this.zoom === 1) {
      return;
    }
    this.reset = state;
  }

  first() {
    this.page = 1;
  }

  incrementPage(): number {
    this.page = +this.page + 1;
    return this.page;
  }

  decrementPage(): boolean {
    if (this.page) {
      this.page -= 1;
      return true;
    }
    return false;
  }

  last(page: number) {
    this.page = page;
  }

}

import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.staging';
import { IResponse } from '@shared/interfaces/IResponse';
import { NotificationService } from '@shared/services/notification.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private api: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private notification: NotificationService
  ) {
    console.log('environment.apiUrl: ', environment);
  }

  public get<T>(url: string, options: any = {}): Observable<any> {
    return this.http
      .get<IResponse>(
        `${this.api}${url}`,
        Object.assign(options, { observe: 'response' })
      )
      .pipe(
        catchError((error: HttpErrorResponse) => this.catchError(error)),
        map((response: HttpResponse<IResponse>) => {
          return response.body;
        })
      );
  }

  public delete<T>(url: string, options: any = {}): Observable<any> {
    return this.http
      .delete<IResponse>(
        `${this.api}${url}`,
        Object.assign(options, { observe: 'response' })
      )
      .pipe(
        catchError((error: HttpErrorResponse) => this.catchError(error)),
        map((response: HttpResponse<IResponse>) => {
          return response.body;
        })
      );
  }

  post(path: string, body: any, options: any = {}): Observable<any> {
    return this.http
      .post<IResponse>(
        `${this.api}${path}`,
        body,
        Object.assign(options, { observe: 'response' })
      )
      .pipe(
        catchError((error: HttpErrorResponse) => this.catchError(error)),
        map((response: HttpResponse<IResponse>) => {
          return response.body;
        })
      );
  }

  public put<T>(
    url: string,
    body: any | null,
    options: any = {}
  ): Observable<any> {
    return this.http
      .put<IResponse>(
        `${this.api}${url}`,
        body,
        Object.assign(options, { observe: 'response' })
      )
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.catchError(error)
        ),
        map((response: HttpResponse<IResponse>) => {
          return response.body;
        })
      );
  }

  patch(path: string, body: any, options: any = {}): Observable<any> {
    return this.http
      .patch<IResponse>(
        `${this.api}${path}`,
        body,
        Object.assign(options, { observe: 'response' })
      )
      .pipe(
        catchError((error: HttpErrorResponse) => this.catchError(error)),
        map((response: HttpResponse<IResponse>) => {
          return response.body;
        })
      );
  }

  private catchError(error: any) {
    this.notification.send(error.error?.message || error.message || 'Something went wrong...', 'error');
    return throwError(error);
  }
}

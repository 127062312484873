<div class="header">
  <div class="collapsed-sidebar-button" (click)="navBarService.toggle()">
    <i class="pi pi-bars"></i>
  </div>
  <div class="header-search">
    <input
      [placeholder]="placeholder || 'search.nothing' | translate"
      [(ngModel)]="filter.search"
      [readonly]="!placeholder"
      id="search"
      type="text"
    />
  </div>
  <div class="header-profile">
    <div class="left" title="{{'tooltips.system.header.console' | translate}}">
      <div
        [routerLink]="['/', 'system', role.isMaster() ? 'console' : 'list']"
        class="icon blocks"
      ></div>
    </div>
    <div class="primeng-dropdown-container">
      <div class="primeng-dropdown-text">
        <div class="logo">
          <div>
            <img class="header-company-logo" *ngIf="logo" [src]="logo" alt="" />
          </div>
        </div>
        <div class="hr"></div>
        <div>
          <span>{{company.name}}</span>
        </div>
      </div>
      <p-menu #menu [model]="items" [popup]="true"></p-menu>
      <button
        (click)="openMenu(menu, $event)"
        class="primeng-dropdown"
        pButton
        type="button"
      ></button>
    </div>
  </div>
</div>

<!-- header="Confirmation" -->
<p-confirmDialog
  acceptButtonStyleClass="primeng-confirm-button-accept"
  header="{{'modals.log-out.title' | translate}}"
  icon="pi pi-exclamation-triangle"
  rejectButtonStyleClass="primeng-confirm-button-reject"
  styleClass="primeng-confirm"
  acceptLabel="{{'modals.log-out.acceptLabel' | translate}}"
  rejectLabel="{{'modals.log-out.rejectLabel' | translate}}"
></p-confirmDialog>

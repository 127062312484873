import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular/main';

@Component({
  selector: 'ec-full-name-cell',
  templateUrl: './full-name.component.html',
})
export class FullNameComponent implements ICellRendererAngularComp {
  value;
  private params: any;

  agInit(params: any): void {
    this.params = params;
    if (this.params && this.params.value) {
      this.value = this.params.value;
    }
  }

  refresh(): boolean {
    return false;
  }
}

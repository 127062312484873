export interface IState {
  userId: number;
  guideId: number;
  statusId: number; // 1, 2, 3, 4, 5 [ 'PENDING', 'STARTED', 'PAUSED', 'FAILED', 'COMPLETED'
  page: number;
  time: number;
}

export enum guideStatus {
  'ZERO', 'PENDING', 'STARTED', 'PAUSED', 'FAILED', 'COMPLETED'
}

export class State {
  userId: number;
  guideId: number;
  statusId: guideStatus;

  zoom: number = 1;
  angle: number = 0;

  constructor(guideId: number, userId: number, page: number, timestamp: number) {
    this.userId = userId;
    this.guideId = guideId;

    this.page = page || 1;
    this.start = timestamp || new Date().getTime();
  }

  private _page: number;

  get page(): number {
    return this._page;
  }

  set page(page: number) {
    this._page = page;
  }

  private _start: number;

  get start(): number {
    return this._start;
  }

  set start(timestamp: number) {
    this._start = timestamp;
  }

  setStatus(statusId: guideStatus) {
    this.statusId = statusId;
  }

  getState(status?: guideStatus): IState {
    return {
      guideId: this.guideId,
      userId: this.userId,
      time: this.getTime(),
      statusId: status ? status : this.statusId,
      page: this.page,
    };
  }

  getTime(): number {
    const now = new Date().getTime();
    const time = Math.ceil((now - this.start) / 1000);
    this.start = now;
    return time;
  }

  first() {
    this.page = 1;
  }

  incrementPage(): number {
    this.page = +this.page + 1;
    return this.page;
  }

  decrementPage(): boolean {
    if (this.page) {
      this.page -= 1;
      return true;
    }
    return false;
  }

  last(page: number) {
    this.page = page;
  }
}

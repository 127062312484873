import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ConfirmationService} from '../../../service/confirmation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ec-button-delete',
  templateUrl: './button-delete.component.html',
  styleUrls: ['./button-delete.component.scss']
})
export class ButtonDeleteComponent implements OnInit {

  @Output() delete: EventEmitter<any> = new EventEmitter<any>();

  private params: any;

  constructor(
    private confirm: ConfirmationService,
    private translate: TranslateService
  ) { }

  public agInit(params: any): void {
    this.params = params;
  }

  public onDelete() {
    event.stopImmediatePropagation();
    this.confirm.confirm({
      message: this.translate.instant('default_confirm'),
      accept: () => this.params.clicked(this.params.value)
    });
  }

  ngOnInit(): void {
  }

}

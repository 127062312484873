import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IGuide } from '@shared/models/upload/upload.models';

@Component({
  selector: 'ec-single-guide-details',
  templateUrl: './single-guide-details.component.html',
  styleUrls: ['./single-guide-details.component.scss'],
})
export class SingleGuideDetailsComponent implements OnInit {
  @Input() data: IGuide;
  @Input() close: boolean = true;
  @Input() button: string;
  @Input() icon: string;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onAction: EventEmitter<any> = new EventEmitter();

  @Input() visible: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  show() {
    if (!this.visible) {
      this.visible = true;
    }
  }

  hide() {
    if (this.visible) {
      this.visible = false;
      this.onClose.emit(null);
    }
  }

  action(event: any) {
    this.onAction.emit(event);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ActivityComponent } from '@components/tabs/activity/activity.component';
import { DetailsComponent } from '@components/tabs/details/details.component';
import { IGuide } from '@shared/models/upload/upload.models';

export interface ITab {
  title: string;
  component: any;
}

@Component({
  selector: 'ec-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @Input() guide: IGuide;
  @Input() activity: boolean = false;
  @Input() tabs: ITab[] = [
    {
      title: 'details',
      component: DetailsComponent
    },
    {
      title: 'activity',
      component: ActivityComponent
    }
  ];
  selected: ITab;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.activity) {
      this.tabs.pop();
    }

    this.selected = this.tabs[0];
  }

  onSelect(index: ITab) {
    this.selected = index;
  }
}

import { Injectable } from '@angular/core';
import apiRoutes from '@shared/constans/apiRoutes';

import { HttpService } from '@shared/services/http.service';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpService, private cookie: CookieService) {}

  updateUser(data) {
    return this.http.put(apiRoutes.user.updateUser, data).pipe(
      map(resp => {
        return resp;
      }),
      catchError((err: Error) => {
        console.log(err);
        return of(err);
      })
    );
  }

  updateCompany(data) {
    return this.http.put(apiRoutes.company.updateCompany, data).pipe(
      map(resp => {
        return resp;
      }),
      catchError((err: Error) => {
        console.log(err);
        return of(err);
      })
    );
  }

  getCompany(id: number) {
    return this.http.get(apiRoutes.company.getCompany + `${id}`).pipe(
      map(resp => {
        return resp;
      }),
      catchError((err: Error) => {
        console.log(err);
        return of(err);
      })
    );
  }

  createCompany(data) {
    return this.http
      .post(apiRoutes.company.createCompany, data, {
        headers: this.getHeaders()
      })
      .pipe(
        map(response => {
          console.log(response);
          return response;
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        })
      );
  }
  getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.cookie.get('Authorization')
    });
  }
}

export default {
  auth: {
    login: '/api/login',
    check: '/api/check',
    logout: '/api/logout',
    registration: '/api/registration',
    operatorRegistration: '/api/registration/operator',
    refreshToken: '/api/refresh',
    isUserAuthenticated: '/api/check',
    vatCheck: '/api/company/vat',
    emailCheck: '/api/user/email',
    changePassword: '/api/user/password ',
  },
  user: {
    updateUser: '/api/user',
    restore: '/api/user/forgot',
    switchLanguage: '/api/user/lang',
  },
  company: {
    getCompany: '/api/company/',
    updateCompany: '/api/company/',
    createCompany: '/api/company',
  },
  guide: {
    create: '/api/guide/',
    update: '/api/guide',
    remove: '/api/upload/',
    upload: '/api/upload/guide/resource/',
    single: '/api/guide',
    guide: '/api/user/guide',
    list: '/api/user/guide/list',
    categoryList: '/api/guide/category',
    priceList: '/api/settings/price/list',
  },
  invite: {
    invite: '/api/user/invite',
    register: '/api/registration/invite',
    companies: '/api/company/titles',
  },
  equipment: {
    remove: '/api/equipment/',
    upload: '/api/upload/profile',
  },
  question: {
    remove: '/api/guide/question/',
    saveAnswers: '/api/user/guide/answer',
  },
};

import { Injectable } from '@angular/core';
import { CookiesService } from '@shared/services/cookie.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CompanySubjectService {
  private logo: Observable<any>;
  public companyLogoSbj$: BehaviorSubject<any> = new BehaviorSubject((JSON.parse(this.cookie.get('Company')) || {}).logo);

  constructor(
    private cookie: CookiesService
  ) {
  }

  public setCompanyLogo(
    logo
  ) {
    this.logo = logo;
    this.companyLogoSbj$.next(this.logo);
  }
}

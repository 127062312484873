import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'ec-company-status',
  templateUrl: './company-status.component.html',
  styleUrls: ['./company-status.component.scss'],
})
export class CompanyStatusComponent implements OnInit {
  form: FormGroup;

  @Input() master;
  @Input() companyId: number;
  @Input() companies = [];
  @Output() enableSelect: EventEmitter<any> = new EventEmitter();
  @Output() disableSelect: EventEmitter<any> = new EventEmitter();
  @Output() status: EventEmitter<any> = new EventEmitter();
  @Output() id: EventEmitter<any> = new EventEmitter();
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.formInit();
    this.companyStatus();
    this.selectedCompanyId();
  }

  private formInit() {
    this.form = this.formBuilder.group({
      companyStatus: [1, Validators.required],
      companyId: [this.master ? null : this.companyId, [Validators.required]],
    });
  }

  enable() {
    this.form.get('companyId').enable();
    this.form.get('companyId').patchValue(this.companyId);
    this.enableSelect.emit(null);
  }
  disable() {
    this.form.get('companyId').patchValue(null);
    this.form.get('companyId').disable();
    this.disableSelect.emit(null);
  }
  private companyStatus() {
    this.form
      .get('companyStatus')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((status: number) => this.status.emit(+status));
  }

  private selectedCompanyId() {
    this.form
      .get('companyId')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((id: number) => this.id.emit(+id));
  }
}

import { Directive, HostListener } from '@angular/core';

@Directive({ selector: '[ecDigitsOnly]' })
export class DigitsOnlyDirective {
  constructor() {}

  @HostListener('keypress', ['$event'])
  public onInputKeyPress(event): void {
    // tslint:disable-next-line: deprecation
    const theEvent = event || window.event;
    let key;
    if (theEvent.type === 'paste') {
      key = theEvent.clipboardData.getData('text/plain');
    } else {
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /^[0-9]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  }
  @HostListener('paste', ['$event'])
  public onInputPaste(event): void {
    // tslint:disable-next-line: deprecation
    const theEvent = event || window.event;
    let key;
    if (theEvent.type === 'paste') {
      key = theEvent.clipboardData.getData('text/plain');
    }
    const regex = /^[0-9]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  }
}

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import apiRoutes from '@shared/constans/apiRoutes';
import { CookiesService } from '@shared/services/cookie.service';

import { HttpService } from '@shared/services/http.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InviteOperatorService {
  constructor(private http: HttpService, private cookie: CookiesService) {}

  public inviteUser(data) {
    return this.http.post(apiRoutes.invite.invite, data).pipe(
      map((response) => {
        console.log(response);
        return response;
      }),
      catchError((err: Error) => {
        console.log(err);
        return of(err);
      })
    );
  }

  public registrationByEmail(data) {
    const inviteData = this.operatorForm(data);
    return this.http
      .post(apiRoutes.invite.register, inviteData, {
        headers: this.inviteHeaders(data),
      })
      .pipe(
        map((resp) => {
          console.log(resp);
          return resp;
        })
      );
  }

  public companyList() {
    return this.http
      .get(apiRoutes.invite.companies, {
        headers: this.authorizationHeader(),
      })
      .pipe(
        map((resp) => {
          console.log(resp);
          return resp.data;
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        })
      );
  }

  private inviteHeaders(data) {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      invitation: this.inviteToken(data),
    });
  }

  private authorizationHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.cookie.get('Authorization'),
    });
  }

  private operatorForm(form): IInviteForm {
    if (form.company) {
      return {
        firstName: form.firstName,
        lastName: form.lastName,
        birthday: form.birthday,
        password: form.password,
        email: form.email,
        company: {
          vat: form.company.vat,
          name: form.company.name,
          contacts: form.company.contacts,
        },
        contacts: [
          {
            phone: form.contacts[0].phone,
            email: form.contacts[0].email,
          },
        ],
      };
    }
    return {
      firstName: form.firstName,
      lastName: form.lastName,
      birthday: form.birthday,
      password: form.password,
      email: form.email,
      contacts: [
        {
          phone: form.contacts[0].phone,
          email: form.contacts[0].email,
        },
      ],
    };
  }

  private inviteToken(form): string {
    return form.inviteId;
  }
}

interface IInviteForm {
  firstName: string;
  lastName: string;
  birthday: any;
  password: string;
  email?: string;
  company?: {
    vat?: any;
    name?: string;
    contacts?: any;
  };
  contacts: [
    {
      phone: any;
      email: any;
    }
  ];
}

<div *ngIf="selected" class="details">
  <div class="row" *ngIf="selected.created_at">
    <div class="col w-50">
      {{'details-component.date' | translate}}:
    </div>
    <div class="col w-50">{{selected.created_at | date: 'dd-MM-yyyy'}}</div>
  </div>
  <div
    class="row"
    title="{{'tooltips.system.right-side-bar.pages' | translate}}"
  >
    <div class="col w-50">
      {{'settings.block.guides.sidebar.pages' | translate}}:
    </div>
    <div class="col w-50">{{selected.pages}}</div>
  </div>
  <div
    class="row"
    title="{{'tooltips.system.right-side-bar.nominated-time' | translate}}"
  >
    <div class="col w-50">
      {{'details-component.nominated-time' | translate}}:
    </div>
    <div class="col w-50">{{selected.time | time}}</div>
  </div>
  <div
    class="row"
    *ngIf="selected.questions"
    title="{{'tooltips.system.right-side-bar.question' | translate}}"
  >
    <div class="col w-50">
      {{'details-component.questions' | translate}}:
    </div>
    <div class="col w-50">{{selected.questions?.length}}</div>
  </div>
  <div class="row">
    <div class="col w-50">
      {{'settings.block.guides.sidebar.category' | translate}}:
    </div>
    <div class="col w-50">{{selected.category?.type}}</div>
  </div>
  <ng-container *ngIf="selected.equipments">
    <div class="row">
      <div class="col">
        {{'details-component.equipment' | translate}}:
      </div>
    </div>
    <div
      *ngFor="let equipment of selected.equipments"
      class="row equipment-details"
    >
      <div
        class="details-logo"
        *ngIf="equipment.logo"
        title="{{'tooltips.system.right-side-bar.img' | translate}}"
      >
        <img [src]="equipment.logo" alt="Equipment logo" />
      </div>
      <div class="col w-50 linked" *ngIf="equipment.externalLink">
        <a [href]="equipment.externalLink" target="_blank"
          >{{equipment.name}} {{equipment.model}}</a
        >
      </div>
      <div class="col w-50" *ngIf="!equipment.externalLink">
        <span>{{equipment.name}} {{equipment.model}}</span>
      </div>
    </div>
  </ng-container>
</div>

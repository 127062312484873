import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IGuide } from '@shared/models/upload/upload.models';

@Component({
  selector: 'ec-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {

  @Input() selected: IGuide;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    console.log(this.selected);
  }

  goToCourse() {
    if (this.selected.passingProcess) {
      localStorage.setItem('start_page', `${this.selected.passingProcess.page}`);
    } else {
      localStorage.setItem('start_page', `1`);
    }
    this.router.navigate(['/', 'system', 'guide', this.selected.id]);
  }
}

import { Component, Input, OnInit } from '@angular/core';

export interface ITile {
  name: string;
  icon?: string;
}

@Component({
  selector: 'ec-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
})
export class TileComponent implements OnInit {
  @Input() tile: ITile;

  constructor() {
  }

  ngOnInit(): void {
  }

}

export const tilesConfig: ITile[] = [
  {
    icon: `
    <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M93.9688 63.6562H87.9062V48.5C87.9062 46.8268 86.5482 45.4688 84.875 45.4688H51.5312V33.3438H57.5938C59.267 33.3438 60.625 31.9858 60.625 30.3125V12.125C60.625 10.4518 59.267 9.09375 57.5938 9.09375H39.4062C37.733 9.09375 36.375 10.4518 36.375 12.125V30.3125C36.375 31.9858 37.733 33.3438 39.4062 33.3438H45.4688V45.4688H12.125C10.4517 45.4688 9.09375 46.8268 9.09375 48.5V63.6562H3.03125C1.358 63.6562 0 65.0143 0 66.6875V84.875C0 86.5482 1.358 87.9062 3.03125 87.9062H21.2188C22.892 87.9062 24.25 86.5482 24.25 84.875V66.6875C24.25 65.0143 22.892 63.6562 21.2188 63.6562H15.1562V51.5312H45.4688V63.6562H39.4062C37.733 63.6562 36.375 65.0143 36.375 66.6875V84.875C36.375 86.5482 37.733 87.9062 39.4062 87.9062H57.5938C59.267 87.9062 60.625 86.5482 60.625 84.875V66.6875C60.625 65.0143 59.267 63.6562 57.5938 63.6562H51.5312V51.5312H81.8438V63.6562H75.7812C74.108 63.6562 72.75 65.0143 72.75 66.6875V84.875C72.75 86.5482 74.108 87.9062 75.7812 87.9062H93.9688C95.642 87.9062 97 86.5482 97 84.875V66.6875C97 65.0143 95.642 63.6562 93.9688 63.6562Z" fill="#BF292A"/>
    </svg>
    `,
    name: 'companies',
  },
  {
    icon: `
      <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48.4996 44.9855C60.922 44.9855 70.9923 34.9152 70.9923 22.4928C70.9923 10.0703 60.922 0 48.4996 0C36.0772 0 26.0068 10.0703 26.0068 22.4928C26.0068 34.9152 36.0772 44.9855 48.4996 44.9855Z" fill="#BF292A"/>
        <path d="M48.4996 56.2319C25.9839 56.2319 7.73145 74.4844 7.73145 97H89.2677C89.2677 74.4844 71.0152 56.2319 48.4996 56.2319Z" fill="#BF292A"/>
      </svg>
    `,
    name: 'operators',
  },
  {
    icon: `
    <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M64.667 0V19.4H84.067L64.667 0Z" fill="#BF292A"/>
      <path d="M54.9668 29.1V0H12.9336V97H84.0668V29.1H54.9668Z" fill="#BF292A"/>
    </svg>
    `,
    name: 'courses',
  },
  // {
  //   icon: `
  //   <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <path d="M96.9999 0H53.8887V32.3333H96.9999V0Z" fill="#BF292A"/>
  //     <path d="M43.1112 0H0V53.8888H43.1112V0Z" fill="#BF292A"/>
  //     <path d="M43.1112 64.6667H0V97H43.1112V64.6667Z" fill="#BF292A"/>
  //     <path d="M96.9999 43.1112H53.8887V97H96.9999V43.1112Z" fill="#BF292A"/>
  //   </svg>
  //   `,
  //   name: 'dashboard',
  // },
  {
    icon: `
    <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M76.3792 41.5184V27.8799C76.3792 12.5076 63.872 0 48.4995 0C33.1269 0 20.6197 12.5076 20.6197 27.8799V41.5184H8.07129V97H88.9293V41.5184H76.3792ZM63.1024 41.5184H33.8966V27.8799C33.8966 19.8279 40.4477 13.277 48.4993 13.277C56.5511 13.277 63.1022 19.8279 63.1022 27.8799V41.5184H63.1024Z" fill="#BF292A"/>
    </svg>
    `,
    name: 'roles',
  },
  {
    icon: `
    <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path d="M83.0059 2.49209C81.5313 1.81272 79.7938 2.07709 78.5878 3.1639L72.224 8.79857C72.0509 8.95265 71.7897 8.95173 71.6175 8.79673L62.9588 1.05242C61.3928 -0.350805 59.022 -0.350805 57.456 1.05242L48.8051 8.78963C48.6311 8.94508 48.3681 8.94508 48.194 8.78963L39.5422 1.05242C37.9762 -0.350576 35.6054 -0.350576 34.0394 1.05242L25.3795 8.79673C25.2062 8.95173 24.9443 8.95242 24.7701 8.79834L18.4006 3.1639C16.7107 1.66117 14.1226 1.81272 12.6198 3.50255C11.946 4.26034 11.5773 5.24122 11.5851 6.25511V90.7451C11.5699 93.01 13.3937 94.8583 15.6586 94.8734C16.6731 94.8803 17.654 94.5107 18.412 93.8363L24.7758 88.2017C24.9489 88.0476 25.2101 88.0485 25.3823 88.2035L34.041 95.9478C35.607 97.351 37.9778 97.351 39.5438 95.9478L48.1956 88.2106C48.3697 88.0552 48.6327 88.0552 48.8067 88.2106L57.4585 95.9476C59.0248 97.3501 61.3953 97.3501 62.9613 95.9476L71.6217 88.2033C71.795 88.0483 72.0568 88.0476 72.2311 88.2017L78.6006 93.8361C80.2911 95.3382 82.8791 95.1854 84.3811 93.4949C85.0538 92.7378 85.4221 91.7581 85.4147 90.7451V6.25511C85.4363 4.63201 84.4889 3.15198 83.0059 2.49209ZM26.7653 32.9088H49.5989C50.6118 32.9088 51.4331 33.7301 51.4331 34.743C51.4331 35.756 50.6118 36.5773 49.5989 36.5773H26.7653C25.7523 36.5773 24.9311 35.756 24.9311 34.743C24.9311 33.7301 25.7523 32.9088 26.7653 32.9088ZM70.2345 64.0915H26.7653C25.7523 64.0915 24.9311 63.2702 24.9311 62.2572C24.9311 61.2442 25.7523 60.4229 26.7653 60.4229H70.2345C71.2475 60.4229 72.0688 61.2442 72.0688 62.2572C72.0688 63.2702 71.2475 64.0915 70.2345 64.0915ZM70.2345 50.3344H26.7653C25.7523 50.3344 24.9311 49.5131 24.9311 48.5001C24.9311 47.4871 25.7523 46.6658 26.7653 46.6658H70.2345C71.2475 46.6658 72.0688 47.4871 72.0688 48.5001C72.0688 49.5131 71.2475 50.3344 70.2345 50.3344Z" fill="#BF292A"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="97" height="97" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    `,
    name: 'invoice',
  },
  {
    icon: `
    <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M92.9584 76.7916H82.1954C80.4454 70.9676 75.0941 66.6875 68.7084 66.6875C62.3227 66.6875 56.9754 70.9676 55.2214 76.7916H4.0416C1.8106 76.7916 0 78.6024 0 80.8334C0 83.0644 1.8106 84.875 4.0416 84.875H55.2212C56.9712 90.699 62.3225 94.9791 68.7082 94.9791C75.0939 94.9791 80.4412 90.699 82.1952 84.875H92.9582C95.1932 84.875 96.9998 83.0644 96.9998 80.8334C96.9998 78.6024 95.1934 76.7916 92.9584 76.7916ZM68.7084 86.8959C65.3659 86.8959 62.6459 84.1759 62.6459 80.8334C62.6459 77.4909 65.3659 74.7709 68.7084 74.7709C72.0509 74.7709 74.7709 77.4909 74.7709 80.8334C74.7709 84.1759 72.0507 86.8959 68.7084 86.8959Z" fill="#BF292A"/>
      <path d="M92.9584 12.125H82.1954C80.4412 6.301 75.0941 2.02087 68.7084 2.02087C62.3227 2.02087 56.9754 6.301 55.2212 12.125H4.0416C1.8106 12.125 0 13.9356 0 16.1666C0 18.3976 1.8106 20.2082 4.0416 20.2082H55.2212C56.9754 26.0324 62.3225 30.3125 68.7084 30.3125C75.0943 30.3125 80.4414 26.0324 82.1954 20.2084H92.9584C95.1934 20.2084 97 18.3978 97 16.1668C97 13.9358 95.1934 12.125 92.9584 12.125ZM68.7084 22.2291C65.3659 22.2291 62.6459 19.5091 62.6459 16.1666C62.6459 12.8241 65.3659 10.1041 68.7084 10.1041C72.0509 10.1041 74.7709 12.8241 74.7709 16.1666C74.7709 19.5091 72.0507 22.2291 68.7084 22.2291Z" fill="#BF292A"/>
      <path d="M92.9584 44.4584H41.7788C40.0246 38.6344 34.6775 34.3543 28.2918 34.3543C21.9061 34.3543 16.5588 38.6344 14.8048 44.4584H4.0416C1.8106 44.4584 0 46.269 0 48.5C0 50.731 1.8106 52.5416 4.0416 52.5416H14.8046C16.5588 58.3656 21.9059 62.6457 28.2916 62.6457C34.6773 62.6457 40.0246 58.3656 41.7786 52.5416H92.9582C95.1932 52.5416 96.9998 50.731 96.9998 48.5C97 46.269 95.1934 44.4584 92.9584 44.4584ZM28.2916 54.5625C24.9491 54.5625 22.2291 51.8425 22.2291 48.5C22.2291 45.1575 24.9491 42.4375 28.2916 42.4375C31.6341 42.4375 34.3541 45.1575 34.3541 48.5C34.3541 51.8425 31.6341 54.5625 28.2916 54.5625Z" fill="#BF292A"/>
    </svg>
    `,
    name: 'settings',
  },
];

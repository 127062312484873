import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ec-guide-information',
  templateUrl: './guide-information.component.html',
  styleUrls: ['./guide-information.component.scss']
})
export class GuideInformationComponent implements OnInit {
  @Input() title: string;
  @Input() button: string = 'start';
  @Input() buttonDisabled: boolean;
  @Output() onClose: EventEmitter<null> = new EventEmitter(null);
  @Output() onAction: EventEmitter<null> = new EventEmitter(null);

  constructor() {
  }

  ngOnInit(): void {
  }

  action() {
    this.onAction.emit();
  }

  close() {
    this.onClose.emit();
  }
}

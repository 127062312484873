import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment } from '@angular/router';
import { RoleService } from '@shared/services/role.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, CanLoad {
  constructor(
    private roleService: RoleService,
    private cookie: CookieService,
  ) {
  }

  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean> | Promise<boolean> | boolean {
    let access: boolean = false;
    const expectedRole = route.data.expectedRole;
    this.roleService.user.roles.some(role => {
      return (access = role.id === expectedRole);
    });
    return access;
  }

  canActivate(route, segments): boolean {
    const expectedRole = [];
    const rolesId = this.roleService.roles.map(role => role.id);
    expectedRole.push(route.data.expectedRole);
    return rolesId.some(userRole => expectedRole.includes(userRole));
  }
}

import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular/main';

@Component({
  selector: 'ec-icon-cell',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconGridComponent implements ICellRendererAngularComp {
  value: string;
  className: string = 'list';
  private params: any;

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    if (this.params.colDef.cellRendererParams && !this.params.colDef.cellRendererParams.success) {
      this.className = this.params.colDef.cellRendererParams;
    } else {
      this.className = this.value ?
        this.params.colDef.cellRendererParams.success :
        this.params.colDef.cellRendererParams.fail
      this.value = '';
    }
  }

  refresh(): boolean {
    return false;
  }
}

<div class="load-container load-appearance" *ngIf="!loaded || loadExport">
  <div class="content">
    <ec-preloader></ec-preloader>
  </div>
</div>
<div *ngIf="loaded">
  <h3>
    {{'view-singe-company.title.info'| translate}}
  </h3>

  <div>
    <div class="flex-block info-container">
      <div class="right-column logo-link-block">
        <img
          [src]="company.logo || 'assets/images/default_company.png'"
          alt=""
          class="logo"
        />
        <button
          (click)="generateLink()"
          *ngIf="roleAllowed"
          title="{{'tooltips.system.my-company.link' | translate}}"
        >
          {{'view-singe-company.title.link'| translate}}
        </button>
      </div>
      <div class="info-block">
        <div class="title-block">
          <div class="main-fields-container">
            <p class="main-title">
              {{'view-singe-company.title.name'| translate}}
            </p>
            <p class="main-title">
              <span>{{ company.name }}</span>
            </p>
          </div>
          <div class="main-fields-container">
            <p class="main-title">
              {{'view-singe-company.title.vat'| translate}}
            </p>
            <p class="main-title">
              <span>
                {{ company.vat || 'grid.title.not-filled' | translate }}
              </span>
            </p>
          </div>
          <div class="main-fields-container">
            <p class="main-title">
              {{'view-singe-company.title.type'| translate}}
            </p>
            <p class="main-title">
              <span>
                {{ (company.companyType || {}).type || 'grid.title.not-filled' |
                translate }}
              </span>
            </p>
          </div>
          <div class="main-fields-container">
            <p class="main-title">
              {{'view-singe-company.title.payment-status'| translate}}
            </p>
            <p class="main-title">
              <span>
                {{ (company.paymentStatus || {}).type || 'grid.title.not-filled'
                | translate }}
              </span>
            </p>
          </div>
          <div class="main-fields-container">
            <p class="main-title">
              {{'view-singe-company.title.bussines-status'| translate}}
            </p>
            <p class="main-title">
              <span>
                {{ (company.businessStatus || {}).type ||
                'grid.title.not-filled' | translate }}
              </span>
            </p>
          </div>
          <div class="main-fields-container">
            <p class="main-title">
              {{'view-singe-company.title.bussines-type'| translate}}
            </p>
            <p class="main-title">
              <span>
                {{ (company.businessType || {}).type || 'grid.title.not-filled'
                | translate }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="flex-block table">
      <div class="right-column">
        <!-- <div *ngIf="company.person" class="title-block">
          <h4>
            {{'view-singe-company.title.company-person'| translate}}
          </h4>
          <ec-person-card [person]="company.person"></ec-person-card>
          <div class="divider"></div>
        </div> -->
        <div class="title-block">
          <h4>
            {{'view-singe-company.title.company-contacts'| translate}}
          </h4>

          <div *ngFor="let c of company.contacts; let i = index;">
            <ec-contact-card [contact]="c"></ec-contact-card>
            <div class="divider"></div>
          </div>
        </div>

        <div *ngIf="company.locations.length" class="title-block">
          <h4>{{'view-singe-company.title.company-locations'| translate}}</h4>

          <div *ngFor="let l of company.locations; let i = index;">
            <ec-location-card [location]="l"></ec-location-card>
            <div class="divider"></div>
          </div>
        </div>

        <div *ngIf="company.web.length" class="title-block">
          <h4>{{'view-singe-company.title.company-web'| translate}}</h4>

          <div *ngFor="let w of company.web; let i = index;">
            <ec-web-card [web]="w"></ec-web-card>
            <div class="divider"></div>
          </div>
        </div>
      </div>
      <div class="full-width">
        <div class="title-block">
          <ec-data-grid
            #operatorsRef
            (onDoubleRowClick)="viewUser($event)"
            [columnDefs]="employeesColumnDefs"
            [defaultColDef]="defaultColDef"
            [gridOptions]="gridOptions"
            [paginationSize]="7"
            [rowData]="company.employees"
            [height]="500"
            [title]="'employees'"
          >
          </ec-data-grid>
        </div>
        <div class="full-width title-block">
          <div class="export" *ngIf="roleAllowed && rowData.length">
            <button (click)="initForm()" *ngIf="!datesForm">
              {{'action-button.export'| translate}}
            </button>
            <form
              [formGroup]="datesForm"
              (ngSubmit)="exportSelected()"
              class="date"
              *ngIf="datesForm"
            >
              <div class="date-min">
                <p-calendar
                  [monthNavigator]="true"
                  placeholder="{{'view-singe-company.title.from-date' | translate}}"
                  [readonlyInput]="true"
                  [maxDate]="maxDate"
                  class="primeng-auth-calendar"
                  formControlName="startDate"
                  dateFormat="dd-mm-yy"
                  inputStyleClass="primeng-input primeng-input-invite"
                  styleClass="primeng"
                ></p-calendar>
              </div>
              <div class="date-max">
                <p-calendar
                  [monthNavigator]="true"
                  placeholder="{{'view-singe-company.title.from-date' | translate}}"
                  [readonlyInput]="true"
                  [disabled]="datesForm.get('startDate').invalid"
                  [minDate]="datesForm.get('startDate').value"
                  [maxDate]="maxDate"
                  class="primeng-auth-calendar"
                  formControlName="endDate"
                  dateFormat="dd-mm-yy"
                  inputStyleClass="primeng-input primeng-input-invite"
                  styleClass="primeng"
                ></p-calendar>
              </div>

              <button
                *ngIf="datesForm"
                type="submit"
                [disabled]="datesForm.invalid || loadExport"
              >
                {{'action-button.export'| translate}}
              </button>
            </form>
          </div>
          <ec-data-grid
            (onDoubleRowClick)="viewProcess($event)"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [gridOptions]="gridOptions"
            [paginationSize]="7"
            [rowData]="rowData"
            [height]="500"
            [title]="'invoices'"
          >
          </ec-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  #toolTip
  (ecClickOutside)="hideTooltip()"
  *ngIf="roleSelected"
  class="tool-tip"
>
  <span class="close" (click)="hideTooltip()"> X </span>
  <p class="inner flex-block inner-title">
    {{'roles-container.title' | translate}}{{ roleSelected.firstName }} {{
    roleSelected.lastName }}
  </p>
  <p class="inner flex-block" *ngFor="let v of allRoles">
    {{v.translate | translate}}
    <input
      type="checkbox"
      [checked]="v.checked"
      (change)="changeRoleStatus($event, v)"
    />
  </p>
</div>

<div class="profile-wrapper">
  <div class="profile-form">
    <div class="system-form-title">
      <div class="first-column">
        <span>Generation link</span>
      </div>
    </div>
    <div>
      <label class="explantation-text"
        >Copy link and paste into e-mail, SMS etc. and send to Operator:</label
      >
    </div>
    <div class="link">
      <input
        type="text"
        [ngModel]="config.data"
        #linkInput
        spellcheck="false"
      />
    </div>

    <div class="system-form-actions">
      <button (click)="ok(linkInput)" type="button">
        Copy
      </button>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanLoad,
  CanActivate,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree, CanActivateChild
} from '@angular/router';
import { ApiAuthService } from '@shared/services/api.auth.service';
import {Observable, throwError} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: ApiAuthService
  ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return this.authService.isAuth()
      .then((isLogedIn: any) => {
        if (!isLogedIn || isLogedIn instanceof HttpErrorResponse) {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        }
        return isLogedIn;
      })
      .catch((error: Error) => {
        this.authService.logout();
        this.router.navigate(['/login']);
        return throwError(error);
      });
  }
}

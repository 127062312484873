import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ec-person-card',
  templateUrl: './person-card.component.html',
  styleUrls: ['./person-card.component.scss'],
})
export class PersonCardComponent implements OnInit {
  @Input() person: any = {};

  constructor() {
  }

  ngOnInit(): void {
  }

}

<div class="ec-system-container">
  <div class="ec-system-title">
    <span>{{'company-edit.title' | translate}}</span>
  </div>
  <form [formGroup]="companyForm" (ngSubmit)="submitForm()" *ngIf="loaded">
    <div class="ec-system-form">
      <!-- <div class="ec-system-form-group"> -->
      <input
        type="file"
        class="file-input-hidden"
        id="file-input"
        [disabled]="companyForm.disabled"
        (change)="onFileChange($event)"
        accept="image/*"
      />
      <div class="logo-block">
        <img
          class="logo-mini uploaded-img"
          *ngIf="temporary || companyForm.get('logo').value"
          [src]="temporary || companyForm.get('logo').value"
          alt=""
        />
        <img
          *ngIf="!companyForm.disabled"
          title="Upload Logo"
          class="logo-mini upload-logo"
          (click)="activateInput()"
          src="assets/icons/actions/upload-512.png"
          alt=""
        />
      </div>
      <!-- </div> -->
      <div class="ec-system-form-group">
        <input
          type="text"
          placeholder="{{'company-edit.placeholder.company-name' | translate}}"
          formControlName="name"
        />
        <span
          class="form-error"
          *ngIf="
                  companyForm.get('name').invalid &&
                  companyForm.get('name').touched
                "
        >
          <span *ngIf="companyForm.get('name').errors['required']">
            {{'company-edit.error.company-name-required' | translate}}
          </span>
        </span>
      </div>
      <div class="ec-system-form-group">
        <input
          type="text"
          placeholder="{{'company-edit.placeholder.company-vat' | translate}}"
          formControlName="vat"
          class="company-vat"
          readonly
        />
      </div>

      <div
        formArrayName="contacts"
        *ngFor="let item of formContacts.controls; let i = index"
        class="ec-system-form-group-array"
      >
        <div [formGroupName]="i">
          <div class="ec-system-form-group">
            <input
              type="text"
              placeholder="{{'company-edit.placeholder.company-phone' | translate}}"
              formControlName="phone"
              [textMask]="{mask: mask}"
              [ecUnmask]="numberUnmask"
            />
            <span class="form-error" *ngIf="isTouched(item,'phone')">
              <span *ngIf="hasError(item,'phone')">
                {{'company-edit.error.company-phone-required' | translate}}
              </span>
            </span>
          </div>
          <div class="ec-system-form-group">
            <input
              type="text"
              placeholder="{{'company-edit.placeholder.company-email' | translate}}"
              formControlName="email"
            />
            <span class="form-error" *ngIf="isTouched(item,'email')">
              <span *ngIf="hasError(item,'email')">
                {{'company-edit.error.company-email-required' | translate}}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div
        formArrayName="locations"
        *ngFor="let item of formLocations.controls; let i = index"
        class="ec-system-form-group-array"
      >
        <div [formGroupName]="i">
          <div class="ec-system-form-group">
            <input
              type="text"
              placeholder="{{'company-edit.placeholder.company-country' | translate}}"
              formControlName="country"
            />
            <span class="form-error" *ngIf="isTouched(item,'country')">
              <span *ngIf="hasError(item,'country')">
                {{'company-edit.error.company-country-required' | translate}}
              </span>
            </span>
          </div>
          <div class="ec-system-form-group">
            <input
              type="text"
              placeholder="{{'company-edit.placeholder.company-street' | translate}}"
              formControlName="street"
            />
            <span class="form-error" *ngIf="isTouched(item,'street')">
              <span *ngIf="hasError(item,'street')">
                {{'company-edit.error.company-street-required' | translate}}
              </span>
            </span>
          </div>
          <div class="ec-system-form-group">
            <input
              type="text"
              placeholder="{{'company-edit.placeholder.company-city' | translate}}"
              formControlName="city"
            />
            <span class="form-error" *ngIf="isTouched(item,'city')">
              <span *ngIf="hasError(item,'city')">
                {{'company-edit.error.company-city-required' | translate}}
              </span>
            </span>
          </div>
          <div class="ec-system-form-group">
            <input
              type="text"
              placeholder="{{'company-edit.placeholder.company-zip' | translate}}"
              formControlName="zip"
            />
            <span class="form-error" *ngIf="isTouched(item,'zip')">
              <span *ngIf="hasError(item,'zip')">
                {{'company-edit.error.company-zip-required' | translate}}
              </span>
            </span>
          </div>
        </div>
      </div>
      <label class="label">{{'budget.individual' | translate}}</label>
      <div
        formGroupName="highPriceBudget"
        class="ec-system-form-group-array discount"
      >
        <div class="ec-system-form-group inline-label price">
          <span>{{'company.label.high-price' | translate}}</span>
          <input
            type="text"
            readonly
            disabled
            placeholder="{{priceList[0].amount}}"
          />
          <span>NOK</span>
        </div>
        <div class="ec-system-form-group inline-label">
          <input
            formControlName="discount"
            type="number"
            min="0"
            max="100"
            placeholder="{{'company.placeholder.discount' | translate}}"
          />
          <span>%</span>
        </div>
      </div>
      <div
        formGroupName="lowPriceBudget"
        class="ec-system-form-group-array discount"
      >
        <div class="ec-system-form-group inline-label price">
          <span>{{'company.label.low-price' | translate}}</span>
          <input
            type="text"
            readonly
            disabled
            placeholder="{{priceList[1].amount}}"
          />
          <span>NOK</span>
        </div>
        <div class="ec-system-form-group inline-label">
          <input
            formControlName="discount"
            type="number"
            min="0"
            max="100"
            placeholder="{{'company.placeholder.discount' | translate}}"
          />
          <span>%</span>
        </div>
      </div>
      <!-- LEFT FOR THE FUTURE -->
      <div class="ec-system-form-group ec-system-form-group-checkbox">
        <label for="freeBudget"
          >{{'company.label.freeBudget' | translate}}</label
        >
        <p-checkbox
          class="primeng-checkbox"
          [binary]="true"
          formControlName="freeBudget"
          id="freeBudget"
          (onChange)="toggleBudget()"
        ></p-checkbox>
      </div>
      <div formGroupName="highPriceBudget" class="ec-system-form-group-array">
        <div class="ec-system-form-group inline-label">
          <input formControlName="quantity" type="number" min="1" />
          <span
            >{{'company.label.quantity-first' |
            translate}}{{priceList[0].amount}}{{'company.label.quantity-second'
            | translate}}</span
          >
        </div>
        <span class="form-error" *ngIf="hp.get('quantity').invalid">
          <span *ngIf="hp.get('quantity').errors['required']"
            >{{'company.error.quantity-required' | translate}}</span
          >
        </span>
      </div>

      <div formGroupName="lowPriceBudget" class="ec-system-form-group-array">
        <div class="ec-system-form-group inline-label">
          <input formControlName="quantity" type="number" min="1" />
          <span
            >{{'company.label.quantity-first' |
            translate}}{{priceList[1].amount}}{{'company.label.quantity-second'
            | translate}}</span
          >
        </div>
        <span
          class="form-error"
          *ngIf="!isFreeBudget && lp.get('quantity').invalid"
        >
          <span *ngIf="lp.get('quantity').errors['required']"
            >{{'company.error.quantity-required' | translate}}</span
          >
        </span>
      </div>
    </div>
    <div class="ec-system-form-group multiple-buttons modal">
      <button type="button" (click)="cancel()">
        {{'action-button.cancel' | translate}}
      </button>
      <button type="submit" [disabled]="companyForm.invalid || sending">
        <span *ngIf="!sending">{{'action-button.save' | translate}}</span>
        <ec-preloader *ngIf="sending"></ec-preloader>
      </button>
    </div>
  </form>
</div>

import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { DataGridComponent } from '@components/data-grid/data-grid.component';
import { DEFAULT_SEARCH_SET } from '@shared/constans/default';
import { DateTimeComponent } from '@shared/grid/date-time/date-time.component';
import { FullNameComponent } from '@shared/grid/full-name/full-name.component';
import { IconGridComponent } from '@shared/grid/icon/icon.component';
import { IGuide } from '@shared/models/upload/upload.models';
import { ApiMainService, IGetGuide } from '@shared/services/api.main.service';
import { RoleService } from '@shared/services/role.service';
import { UserService } from '@shared/services/user.service';
import { SingleGuideDetailsComponent } from '@sys-comp/view-single-guide/single-guide-details/single-guide-details.component';
import { ColDef, GridOptions } from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {SearchingSubjectService} from '../../../../service/searching.subject.service';

@Component({
  selector: 'ec-last-added-list',
  templateUrl: './last-added-list.component.html',
  styleUrls: ['./last-added-list.component.scss']
})
export class LastAddedListComponent implements OnInit, OnDestroy {
  public loaded = false;
  private subscription: Subscription;
  public filter = DEFAULT_SEARCH_SET;
  title: string = 'last-added';
  public rowData: any = [];
  opened: boolean = false;
  @Output() selectedRow: EventEmitter<IGuide> = new EventEmitter<IGuide>();
  @ViewChild('details') details: SingleGuideDetailsComponent;
  @ViewChild('grid') grid: DataGridComponent;

  gridOptions: GridOptions = {
    suppressCellSelection: true,
    suppressHorizontalScroll: true,
    suppressRowTransform: true
  };

  selected: IGuide;
  defaultColDef: ColDef = {
    sortable: true,
    filter: false,
    resizable: false,
    unSortIcon: false,
    sortingOrder: ['asc', 'desc'],
    suppressMovable: true
  };

  columnDefs: ColDef[] = [
    {
      headerName: 'Course Name',
      field: 'name',
      cellRendererFramework: IconGridComponent,
      cellRendererParams: 'list'
    },
    {
      headerName: 'Category',
      field: 'category.type'
    },
    {
      headerName: 'Added by',
      field: 'creator',
      cellRendererFramework: FullNameComponent
    },
    {
      headerName: 'Last modified',
      field: 'updated_at',
      cellRendererFramework: DateTimeComponent,
      cellRendererParams: {
        format: 'dd MMM yyyy'
      }
    }
  ];

  constructor(
    private searchingService: SearchingSubjectService,
    private apiMainService: ApiMainService
  ) {
  }

  ngOnInit(): void {
    this.subscription = this.searchingService.filterSbj$
      .subscribe(filter => {
        console.log('CompanyListComponent', filter);
        this.filter = filter;
        this.getGuideList()
          .subscribe((response) => {
            console.log(response);
            this.rowData = response;
            this.loaded = true;
          });
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getGuideList() {
    return this.apiMainService.getGuideList(this.filter);
  }

  onRowSelected(guide: IGuide) {
    this.selectedRow.emit(guide);
    this.opened = true;
    this.selected = guide;
  }

  close() {
    if (this.opened) {
      this.opened = false;
      this.grid.close();
    }
  }
}

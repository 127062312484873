<div class="guide-details">
  <div class="guide-details-title">
    <h3>{{'details.title.' + title | translate}}</h3>
  </div>
  <div class="guide-details-list">
    <ng-content select="[description]"></ng-content>
  </div>
  <div class="guide-details-form">
    <ng-content select="[form]"></ng-content>
  </div>
</div>

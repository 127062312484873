import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@auth/services/auth.service';
import { CookiesService } from '@shared/services/cookie.service';
import { from, Observable, of, throwError } from 'rxjs';
import { NotificationService } from '@shared/services/notification.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';
  constructor(
    private notificationService: NotificationService,
    private cookieService: CookiesService,
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handleBearer(request, next));
  }

  private handleBearer(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    const token = this.cookieService.get('Authorization');
    const authRequest = request.clone({
      headers: request.headers.set('Authorization', token),
    });
    const result = next.handle(authRequest).toPromise();
    return result
      .then((data) => {
        return data;
      })
      .catch(async (error) => {
        if (error.status === 401 && !request.url.includes('/refresh')) {
          return await this.authService
            .refreshToken()
            .toPromise()
            .then((response) => {
              if (response && !(response instanceof HttpErrorResponse)) {
                const renewToken = this.cookieService.get('Authorization');
                const renewAuthRequest = request.clone({
                  headers: request.headers.set('Authorization', renewToken),
                });
                return next
                  .handle(renewAuthRequest)
                  .toPromise()
                  .then((res) => {
                    return res;
                  });
              }
            });
        } else if (request.url.includes('/refresh')) {
          return this.router.navigate(['/']).then(() => {
            if (token) {
              this.authService.logout().subscribe();
            }
            this.notificationService.send(
              this.language === 'en' ? 'Need to login!' : 'Du må logge på',
              'warning'
            );
            return of(error).toPromise();
          });
        } else {
          console.log('Interceptor else .....', error);
          if (
            request.url.includes('/refresh') ||
            request.url.includes('/logout')
          ) {
            return this.router.navigate(['/']).then(() => {
              console.log('Throwing ERROR');
              this.notificationService.send(
                this.language === 'en' ? 'Need to login!' : 'Du må logge på',
                'warning'
              );
              return of(error).toPromise();
            });
          } else {
            return throwError(error).toPromise();
          }
        }
      });
  }
}

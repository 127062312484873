import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular/main';

@Component({
  selector: 'ec-category-cell',
  templateUrl: './category.component.html',
})
export class CategoryComponent implements ICellRendererAngularComp {
  value: { id: number, type: string };
  private params: any;

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
  }

  refresh(): boolean {
    return false;
  }
}

import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { InviteOperatorService } from 'app/system/services/invite-operator.service';
import { CookiesService } from '@shared/services/cookie.service';
import { LanguageSubjectService } from 'app/service/language.subject.service';

@Component({
  selector: 'ec-invite-operator-modal',
  templateUrl: './invite-operator-modal.component.html',
  styleUrls: ['./invite-operator-modal.component.scss'],
})
export class InviteOperatorModalComponent implements OnInit {
  tabs = [];
  activeItem: MenuItem;
  public inviteOperator: boolean = true;
  public createOperator: boolean = false;
  companies = [];
  master: boolean;
  loaded: boolean = false;
  companyId: number;
  private language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';
  constructor(
    private inviteService: InviteOperatorService,
    private cookie: CookiesService,
    private languageSubjectService: LanguageSubjectService
  ) {}

  ngOnInit(): void {
    this.tabs = this.initTabs();
    this.companyList();
    this.jsonParseRoleFunction();
    this.storageChange();
  }
  initTabs() {
    return [
      {
        label: this.language === 'en' ? 'Invite operator' : 'Inviter operatør',
        command: () => this.loadComponent(),
      },
      {
        label:
          this.language === 'en' ? 'Register operator' : 'Registrer operatør',
        command: () => this.loadComponent(),
      },
    ];
  }
  private loadComponent() {
    this.inviteOperator = !this.inviteOperator;
    this.createOperator = !this.createOperator;
  }

  private companyList() {
    this.inviteService.companyList().subscribe((res) => {
      this.companies = res;
      this.loaded = true;
    });
  }
  // JSON Parser for getting user Role
  private jsonParseRoleFunction() {
    const profile = JSON.parse(this.cookie.get('User'));
    this.master = this.checkRoles(profile.roles);
    this.companyId = profile.company.id;
  }

  private checkRoles(roles): boolean {
    const master = [1];
    if (roles.some((role) => master.includes(role.id))) {
      return true;
    }
    return false;
  }
  private storageChange() {
    this.languageSubjectService.watchStorage().subscribe((lang: string) => {
      this.language = lang;
      this.tabs = this.initTabs();
    });
  }
}

<div #sidebar>
  <div class="nav">
    <div
      *ngFor="let route of routes"
      [routerLink]="['/', 'system', route.name]"
      class="nav-link"
      routerLinkActive="current"
      title="{{route.tooltip | translate}}"
    >
      <div [ngClass]="route.name" class="nav-icon"></div>
      <div class="nav-title">
        {{'system.sidebar.title.' + route.name | translate}}
      </div>
    </div>
  </div>
  <div class="sidebar-tutorial">
    <a href="https://youtu.be/ky5YVwapvDg" target="_blank"
      >{{'tutorial.take-course' | translate}}</a
    >
    <a
      href="https://youtu.be/g7CInSqmbhM"
      target="_blank"
      *ngIf="service.isOperator()"
      >{{'tutorial.administrate-account' | translate}}</a
    >
    <a
      href="https://youtu.be/8OFnM2rkNE8"
      target="_blank"
      *ngIf="service.adminRole()"
      >{{'tutorial.company-admin' | translate}}</a
    >
  </div>
  <ec-language-changer
    class="language-changer-system"
    title="{{'tooltips.system.sidebar.language-changer' | translate}}"
  ></ec-language-changer>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { IAnswer } from '@shared/services/question.service';

@Component({
  selector: 'ec-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss']
})
export class AnswerComponent implements OnInit {
  @Input() index: number;
  @Input() answer: IAnswer;
  id: string = '';

  constructor() {
  }

  ngOnInit(): void {
    this.id = 'answer' + this.index;
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { AsyncValidator } from '@shared/validators/async-vat.validator';
import { PrimeNGLocalDataService } from '@shared/configurations/primeng/local-data.service';
import { NotificationService } from '@shared/services/notification.service';
import { AuthService } from '@auth/services/auth.service';

@Component({
  selector: 'ec-create-operator',
  templateUrl: './create-operator.component.html',
  styleUrls: ['./create-operator.component.scss'],
})
export class CreateOperatorComponent implements OnInit {
  @Input() companies;
  @Input() master;
  @Input() companyId: number;

  form: FormGroup;
  sending: boolean = false;
  companyStatus: number = 1;
  public mask = [
    '+',
    /\d/,
    /\d/,
    ' ',
    /[0-9]/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ];
  numberUnmask = /\D/g;
  private language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public primeNGLocalDataService: PrimeNGLocalDataService,
    public ref: DynamicDialogRef,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.primeNGLocalDataService.changeLocale();
    this.formInit();
  }

  private formInit(): void {
    this.form = this.formBuilder.group({
      email: [
        null,
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
        AsyncValidator.emailValidator(this.authService),
      ],
      firstName: [null, [Validators.required, Validators.minLength(3)]],
      lastName: [null, [Validators.required, Validators.minLength(3)]],
      birthday: [new Date('01-01-1990'), [Validators.required]],
      companyId: [this.master ? null : this.companyId],
      contacts: this.formBuilder.array([]),
    });
    this.contactsFormBuilder();
  }
  // Contacts
  private contactsFormBuilder() {
    const control = this.f.controls.contacts as FormArray;
    control.push(
      this.formBuilder.group({
        phone: [null, [Validators.minLength(8)]],
      })
    );
  }

  submit() {
    const form = this.controlRemoving(this.f.value);
    if (this.f.valid) {
      this.sending = true;
      this.authService.operatorRegistration(form).subscribe((res) => {
        this.sending = false;
        if (!(res instanceof HttpErrorResponse)) {
          return this.response(res);
        }
      });
    }
  }

  // Form
  get f() {
    return this.form;
  }
  // Contacts
  get c() {
    return this.f.get('contacts') as FormArray;
  }
  // Validators
  public hasError(control, field): boolean {
    return control.get(field).hasError('required');
  }

  public isTouched(control, field): boolean {
    return control.get(field).touched;
  }

  enableSelect(): void {
    this.f.get('companyId').enable();
    this.c.controls[0].get('phone').clearValidators();
    this.c.controls[0].get('phone').updateValueAndValidity();
  }
  disableSelect(): void {
    this.f.get('companyId').disable();
    this.c.controls[0].get('phone').setValidators([Validators.required]);
    this.c.controls[0].get('phone').updateValueAndValidity();
  }

  id(event: number) {
    this.f.get('companyId').patchValue(+event);
  }

  status(event: number) {
    this.companyStatus = event;
  }

  cancel(): void {
    this.ref.close();
  }
  // Response after success call
  private response(response): void {
    this.notification.send(
      this.language === 'en'
        ? 'Invitation sent via email!'
        : 'Invitasjonen ble sendt på e-post',
      'info'
    );
    this.ref.close();
  }
  private controlRemoving(control) {
    Object.entries(control).forEach((key) => {
      if (key[0] === 'contacts') {
        if (!key[1][0].phone) {
          delete control[key[0]];
        }
      }
    });
    return control;
  }
}

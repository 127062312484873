import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { NotificationService } from '@shared/services/notification.service';
import { InviteOperatorService } from 'app/system/services/invite-operator.service';
import { AuthService } from '@auth/services/auth.service';
import { AsyncValidator } from '@shared/validators/async-vat.validator';

@Component({
  selector: 'ec-invite-operator',
  templateUrl: './invite-operator.component.html',
  styleUrls: ['./invite-operator.component.scss'],
})
export class InviteOperatorComponent implements OnInit {
  inviteForm: FormGroup;
  @Input() master: boolean;
  @Input() companies;
  @Input() companyId: number;

  sending: boolean = false;
  private language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';

  constructor(
    private formBuilder: FormBuilder,
    private notification: NotificationService,
    private inviteService: InviteOperatorService,
    public ref: DynamicDialogRef,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.formInit();
    if (this.master) {
      this.newFormControl();
    }
  }

  private formInit(): void {
    this.inviteForm = this.formBuilder.group({
      email: [
        null,
        [
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
          Validators.required,
        ],
        AsyncValidator.emailValidator(this.authService),
      ],
      companyId: [this.master ? null : this.companyId, [Validators.required]],
      independentOperator: [false],
    });
  }

  // Getters
  // Get Form
  get formData() {
    return this.inviteForm as FormGroup;
  }
  get companyStatus() {
    return this.formData.get('companyStatus').value;
  }
  get companyIdControl() {
    return this.formData.get('companyId');
  }
  get independentOperator() {
    return this.formData.get('independentOperator');
  }

  // Form Submit
  public submitForm() {
    const form = this.controlRemoving(this.inviteForm.value);
    if (this.inviteForm.valid) {
      this.sending = true;
      this.inviteService.inviteUser(form).subscribe((res) => {
        this.sending = false;
        if (!(res instanceof HttpErrorResponse)) {
          return this.response(res);
        }
      });
    }
  }

  private newFormControl(): void {
    const companyStatus = new FormControl(1, Validators.required);
    this.inviteForm.addControl('companyStatus', companyStatus);
  }

  private controlRemoving(control) {
    Object.keys(control).forEach((key) => {
      if (key === 'companyStatus') {
        delete control[key];
      }
    });
    return control;
  }
  // Enable or Disable Form Field
  public enableCompanySelect(): void {
    this.companyIdControl.enable();
    this.independentOperator.patchValue(false);
  }
  public disableCompanySelect(): void {
    this.companyIdControl.disable();
    this.independentOperator.patchValue(true);
  }
  // Response after success call
  private response(response): void {
    this.notification.send(
      this.language === 'en'
        ? 'Invitation sent via email!'
        : 'Invitasjonen ble sendt på e-post',
      'info'
    );
    this.ref.close();
  }

  public cancel(): void {
    this.ref.close();
  }
  status(event) {
    this.inviteForm.get('companyStatus').patchValue(event);
  }
  id(event: number) {
    this.companyIdControl.patchValue(+event);
  }
}

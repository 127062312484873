import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IUser } from '@shared/interfaces/IResponse';
import { IGuide } from '@shared/models/upload/upload.models';
import { ApiMainService } from '@shared/services/api.main.service';
import { HttpService } from '@shared/services/http.service';
import { NotificationService } from '@shared/services/notification.service';
import { QuestionService } from '@shared/services/question.service';
import { RoleService } from '@shared/services/role.service';
import { UserService } from '@shared/services/user.service';
import { guideStatus, IState, State } from '@shared/services/viewer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ec-view-single-guide',
  templateUrl: './view-single-guide.component.html',
  styleUrls: ['./view-single-guide.component.scss'],
})
export class ViewSingleGuideComponent implements OnInit, OnDestroy {
  public guide: IGuide;
  public loaded = false;
  pdfSrc: string;
  zoomStep: number = 0.25;
  reset: boolean = false;
  state: State;
  user: IUser;
  numPages: number = 0;
  user$: Subscription;

  constructor(
    private apiService: ApiMainService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private roleService: RoleService,
    private http: HttpService,
    private notify: NotificationService,
    private questionService: QuestionService
  ) {}

  ngOnInit(): void {
    this.user$ = this.userService.getUser$().subscribe((user) => {
      if (user && user.guides) {
        this.user = user;
        this.checkUser();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.state) {
      this.pauseCourse();
    }
    if (this.user$) {
      this.user$.unsubscribe();
    }
  }

  checkUser(): boolean {
    const id: string = this.route.snapshot.params.id;
    if (id === 'finish') {
      console.log('finish');
    }
    const isAvailable: boolean = this.userService.checkAvailabilityByID(+id);
    console.log(`Is guide with id - ${id} available: `, isAvailable);
    if (isAvailable) {
      this.getGuide(+id);
    } else {
      setTimeout(() => {
        this.router.navigate(['/', 'system', 'list']).finally(() => {
          this.notify.send('Access denied', 'warning');
        });
      }, 42);
    }
    return isAvailable;
  }

  getGuide(id: number) {
    this.apiService.getGuide(id).subscribe((result) => {
      if (!(result instanceof HttpErrorResponse)) {
        this.guide = result.data;
        this.questionService.questions = this.guide.questions;
        if (this.guide.resource.length) {
          const userId: number = this.user.id;
          const page = +localStorage.getItem('start_page');
          this.state = new State(
            this.guide.id,
            userId,
            page,
            new Date().getTime()
          );
          this.sendProgress(2).subscribe();
          this.pdfSrc = this.guide.resource[0].path;
        } else {
          this.router.navigate(['/', 'system', 'list']).finally(() => {
            this.notify.send('404. File not found :(', 'info');
          });
        }
      }
      this.loaded = true;
    });
  }

  pauseCourse(manual?: boolean) {
    if (this.state) {
      if (manual) {
        this.router.navigate(['/', 'system']).then(() => {
          this.sendProgress(3).subscribe((data) => {
            if (!(data instanceof HttpErrorResponse)) {
              this.userService.updatePassingProcess(data.data);
            }
          });
        });
      } else {
        this.sendProgress(3).subscribe((data) => {
          if (!(data instanceof HttpErrorResponse)) {
            this.userService.updatePassingProcess(data.data);
          }
        });
      }
    }
  }

  sendProgress(status?: guideStatus) {
    const body: IState = status
      ? this.state.getState(status)
      : this.state.getState();
    return this.http.put('/api/user/guide/process', body);
  }

  finishCourse() {
    this.pdfSrc = null;
    const state = this.state.getState();
    this.sendProgress(3).subscribe((data) => {
      this.state = null;
      if (!(data instanceof HttpErrorResponse)) {
        this.userService.updatePassingProcess(data.data);
      }
      this.router
        .navigate(['/', 'system', 'guide', this.guide.id, 'finish'], {
          queryParams: {
            guideId: state.guideId,
            page: state.page,
            time: state.time,
            userId: state.userId,
          },
        })
        .then(() => {});
    });
  }

  onLoad(event) {
    this.numPages = event.numPages;
  }

  onPageChanged(event) {
    this.sendProgress(2).subscribe();
    localStorage.setItem('start_page', `${event}`);
  }

  rotate(direction: number) {
    this.state.angle += direction;
  }

  zoomIn() {
    if (this.state.zoom < 5) {
      this.state.zoom += this.zoomStep;
    }
  }

  zoomOut() {
    if (this.state.zoom > 0.25) {
      this.state.zoom -= this.zoomStep;
    }
  }

  resetZoom() {
    this.state.zoom = 1;
    this.reset = false;
  }

  toggleReset(state: boolean) {
    if (this.state.zoom === 1) {
      return;
    }
    this.reset = state;
  }
}

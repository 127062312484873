import { Directive, HostListener } from '@angular/core';

@Directive({selector: '[ecNumbersOnly]'})
export class NumbersOnlyDirective {

  constructor() {
  }

  @HostListener('keypress', ['$event'])
  public onInputKeyPress(event): void {
    const theEvent = event || window.event;
    let key;
    if (theEvent.type === 'paste') {
      key = theEvent.clipboardData.getData('text/plain');
    } else {
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
    if (key === '.') {
      if (!event.target.value || event.target.value.split('.').length > 1) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) {
          theEvent.preventDefault();
        }
      }
    }
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  }
}

<div class="tabs-content">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <ec-tab (action)="onSelect($event)"
            [active]="selected === tab"
            [index]="i"
            [tab]="tab">
    </ec-tab>
  </ng-container>

</div>
<ng-container *ngIf="guide">

  <ec-details *ngIf="selected.title === 'details'"
              [selected]="!activity ? guide : guide.guide"></ec-details>
  <ec-activity *ngIf="selected.title === 'activity' && activity"
               [selected]="guide"></ec-activity>
</ng-container>


import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular/main';

@Component({
  selector: 'ec-currency-cell',
  templateUrl: './currency.component.html',
})
export class CurrencyComponent implements ICellRendererAngularComp {
  amount: number;
  currency: string;
  public params: any;

  agInit(params: any): void {
    this.params = params;
    if (this.params && this.params.value) {
      this.currency = this.params.value.currency;
      this.amount = +this.params.value.amount;
    }
  }

  refresh(): boolean {
    return false;
  }
}

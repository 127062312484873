<div *ngIf="selected" class="activity">
  <div class="row">
    <div class="col w-50">
      {{'tabs.title.date' | translate}}:
    </div>
    <div class="col w-50">{{selected.updated_at | date: 'dd-MM-yyyy'}}</div>
  </div>
  <div class="row">
    <div class="col w-50">
      {{'tabs.title.page' | translate}}:
    </div>
    <div class="col w-50">{{selected?.page}}/{{selected.guide.pages}}</div>
  </div>
  <div class="row">
    <div class="col w-50">
      {{'tabs.title.time' | translate}}:
    </div>
    <div class="col w-50">{{selected?.time | time}}</div>
  </div>
  <div class="row">
    <div class="col w-50">
      {{'settings.block.guides.sidebar.category' | translate}}:
    </div>
    <div class="col w-50">{{selected.guide.category?.type}}</div>
  </div>
</div>

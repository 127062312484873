<div class="flex-container">
  <div title="{{'tooltips.system.my-company.suspend' | translate}}">
    <label class="switch" *ngIf="self()">
      <input
        type="checkbox"
        [disabled]="!permissions()"
        [checked]="!value"
        (change)="valueChanged($event)"
      />
      <span class="slider round"></span>
    </label>
  </div>
  <span>
    {{ value ? ('actions.suspended' | translate) : ('actions.active' |
    translate) }}
  </span>
</div>

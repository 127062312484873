<div class="test-container">
  <div class="test-content">
    <form>
      <div *ngFor="let control of questionsForm.controls; index as i">
        <p class="question">
          {{control.get('questionId').value}}
        </p>
        <div class="answers">
          <input [formControl]="control[i].get('answerId')" type="radio">
        </div>
      </div>
    </form>
    <input [(ngModel)]="question" type="text">
    <button (click)="addQuestion(counter)">Add</button>
    <button (click)="show()">Show</button>
  </div>
</div>

<ec-data-grid #grid
              (onRowClick)="onRowSelected($event)"
              [columnDefs]="columnDefs"
              [defaultColDef]="defaultColDef"
              [gridOptions]="gridOptions"
              [paginationSize]="3"
              [rowData]="rowData"
              [height]="260"
              [title]="title">
</ec-data-grid>

import { Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeComponent } from '@shared/grid/date-time/date-time.component';
import { IconGridComponent } from '@shared/grid/icon/icon.component';
import { LengthComponent } from '@shared/grid/length/length.component';
import { TypeComponent } from '@shared/grid/type/type.component';
import { ApiMainService } from '@shared/services/api.main.service';
import { RoleService } from '@shared/services/role.service';
import { ColDef, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { DialogService } from 'primeng/dynamicdialog';
import * as fromModals from '../../dialog-components';
import { CookiesService } from '@shared/services/cookie.service';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { SearchingSubjectService } from '../../../../service/searching.subject.service';
import {
  DEFAULT_SEARCH_SET,
  SEARCH_PLACEHOLDER,
} from '@shared/constans/default';
import { pluck, debounceTime } from 'rxjs/operators';
import {ButtonDeleteComponent} from "@shared/grid/button-delete/button-delete.component";
import {NotificationService} from "@shared/services/notification.service";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'ec-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit, OnDestroy {
  @ViewChild('listRef') listRef;
  private subscription: Subscription;
  public loaded = false;
  public filter: Observable<any>;
  public selectedRow;
  title: string = 'companies';
  rowSelection = 'single';
  public rowData: any = [];
  gridOptions: GridOptions = {
    suppressCellSelection: true,
    suppressHorizontalScroll: true,
    suppressRowTransform: true,
  };
  defaultColDef: ColDef = {
    sortable: true,
    filter: false,
    resizable: false,
    unSortIcon: false,
    sortingOrder: ['asc', 'desc'],
    suppressMovable: true,
  };
  columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      cellRendererFramework: IconGridComponent,
      cellRendererParams: 'home',
    },
    {
      headerName: 'VAT number',
      field: 'vat',
    },
    {
      headerName: 'Company Type',
      field: 'companyTypeId',
      width: 120,
      suppressSizeToFit: true,
      cellRendererFramework: TypeComponent,
      cellRendererParams: 'company',
    },
    {
      headerName: 'Employees',
      field: 'employees',
      width: 90,
      suppressSizeToFit: true,
      cellRendererFramework: LengthComponent,
    },
    {
      headerName: 'Customer Group',
      field: 'customerGroup',
    },
    {
      headerName: 'Payment Status',
      field: 'paymentStatusId',
      width: 110,
      cellRendererFramework: TypeComponent,
      cellRendererParams: 'payment',
    },
    {
      headerName: 'Established',
      field: 'created_at',
      width: 110,
      suppressSizeToFit: true,
      cellRendererFramework: DateTimeComponent,
      cellRendererParams: {
        format: 'MMM dd, yyyy',
      },
    },
  ];
  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;

  private personalCompanyId = this.jsonParseFunction();

  @Input() scrWidth: number;
  searchSubscription: Subscription;
  placeholder: string = '';
  searchFilter: any = DEFAULT_SEARCH_SET;

  constructor(
    private searchingService: SearchingSubjectService,
    private apiMainService: ApiMainService,
    private roleService: RoleService,
    private translateService: TranslateService,
    private router: Router,
    public dialogService: DialogService,
    private cookieService: CookiesService,
    private role: RoleService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    if (this.role.masterRole()) {
      this.columnDefs.push({
        headerName: 'Action',
        field: 'id',
        cellRendererFramework: ButtonDeleteComponent,
        cellRendererParams: {
          clicked: (guideId) => this.delete(guideId)
        }
      });
    }
    this.subscription = this.searchingService.filterSbj$.subscribe((filter) => {
      this.filter = filter;
      this.companyList();
    });
    setTimeout(() => {
      this.subscribe();
    }, 0);
    this.initSearch();
  }

  public delete(companyId: number) {
    this.loaded = false;
    this.apiMainService.fullCompanyDelete(companyId)
      .subscribe(res => {
        if (!(res instanceof HttpErrorResponse)) {
          this.notification.send('Company deleted successfully!', 'warning');
          this.companyList();
        } else {
          this.loaded = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.unsubscribe();
  }

  sizeToFit() {
    this.gridApi.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.sizeToFit();
  }

  onRowSelected(event) {
    this.selectedRow = event.data;
  }

  public viewCompany(event) {
    if (this.selectedRow) {
      this.router.navigate(['/system/company', this.selectedRow.id]).then();
    }
  }

  openEditModal(event) {
    const data = event.data;
    const selectedCompanyId = data.id;
    if (
      (this.personalCompanyId === selectedCompanyId &&
        this.roleService.role === 'admin') ||
      this.roleService.role === 'master'
    ) {
      const ref = this.dialogService.open(fromModals.EditCompanyComponent, {
        data,
        styleClass: 'primeng-dialog-container',
      });
      ref.onClose.subscribe((response) => {
        if (response) {
          this.rowData[
            this.rowData.findIndex((r) => r.id === response.company.id)
          ] = response.company;
          this.listRef.api.setRowData(this.rowData);
        }
      });
    }
  }

  public companyList() {
    this.apiMainService.getCompanyList(this.filter).subscribe((res) => {
      this.rowData = res;
      this.loaded = true;
    });
  }
  private jsonParseFunction() {
    const user = JSON.parse(this.cookieService.get('User'));
    return user.company.id;
  }
  subscribe() {
    this.searchSubscription = fromEvent(
      document.getElementById('system-searching'),
      'keyup'
    )
      .pipe(pluck('target', 'value'), debounceTime(500))
      .subscribe((value: string) => {
        this.searchingService.setSearchFilter(
          Object.assign(DEFAULT_SEARCH_SET, { search: value })
        );
      });
  }
  unsubscribe() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }
  private initSearch() {
    this.placeholder = SEARCH_PLACEHOLDER[this.router.url];
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.searchFilter.search = '';
        this.placeholder = SEARCH_PLACEHOLDER[this.router.url];
      }
    });
  }
}

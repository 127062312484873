import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateCompanyService {
  private createdCompanyData$ = new BehaviorSubject({});
  createdCompanyDataSubject = this.createdCompanyData$.asObservable();
  private operatorData$ = new BehaviorSubject({});
  operatorDataSubject = this.operatorData$.asObservable();
  constructor() {}

  company(data) {
    this.createdCompanyData$.next(data);
  }
  operator(data) {
    this.operatorData$.next(data);
  }
}

<p-tabMenu
  [activeItem]="tabs[0]"
  [model]="tabs"
  class="primeng-tabs"
></p-tabMenu
><ec-invite-operator
  [style.display]="inviteOperator ? 'block' : 'none'"
  [companies]="companies"
  [master]="master"
  [companyId]="companyId"
  *ngIf="loaded"
></ec-invite-operator>
<ec-create-operator
  [style.display]="createOperator ? 'block' : 'none'"
  [companies]="companies"
  [master]="master"
  [companyId]="companyId"
  *ngIf="loaded"
></ec-create-operator>

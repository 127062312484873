<div [class.visible]="visible" class="guide-details-container">
  <div class="guide-details-actions single-guide-actions">
    <span (click)="hide()" class="linked">
      {{'button.return' | translate}}
    </span>
  </div>
  <div class="guide-details-content">
    <ng-content></ng-content>
  </div>
</div>

<pdf-viewer
  (after-load-complete)="onLoad($event)"
  (pageChange)="onPageChanged($event)"
  *ngIf="pdfSrc"
  [(page)]="state.page"
  [original-size]="true"
  [render-text]="false"
  [rotation]="state.angle"
  [show-all]="false"
  [src]="pdfSrc"
  [zoom]="state.zoom"
  style="display: block;"
>
</pdf-viewer>

<div *ngIf="pdfSrc" class="controls-wrapper">
  <div class="controls rotate">
    <button (click)="rotate(-90)" class="white left">
      <svg
        id="Capa_1"
        version="1.1"
        viewBox="0 0 426.667 426.667"
        x="0px"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        y="0px"
      >
        <g>
          <path
            d="M213.333,85.333V0L106.667,106.667l106.667,106.667V128c70.72,0,128,57.28,128,128s-57.28,128-128,128s-128-57.28-128-128
          H42.667c0,94.293,76.373,170.667,170.667,170.667S384,350.293,384,256S307.627,85.333,213.333,85.333z"
          />
        </g>
      </svg>
    </button>
    <button (click)="rotate(90)" class="white right">
      <svg
        id="Capa_1"
        version="1.1"
        viewBox="0 0 426.667 426.667"
        x="0px"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        y="0px"
      >
        <g>
          <path
            d="M213.333,85.333V0L106.667,106.667l106.667,106.667V128c70.72,0,128,57.28,128,128s-57.28,128-128,128s-128-57.28-128-128
          H42.667c0,94.293,76.373,170.667,170.667,170.667S384,350.293,384,256S307.627,85.333,213.333,85.333z"
          />
        </g>
      </svg>
    </button>
  </div>
  <ec-simple-pagination
    (first)="state.first()"
    (last)="state.last(numPages)"
    (next)="state.incrementPage()"
    (previous)="state.decrementPage()"
    [enableLast]="false"
    [page]="state.page"
    [pages]="numPages"
  >
    <button
      (click)="pauseCourse(true)"
      *ngIf="numPages !== state.page"
      class="white finish"
      title="{{'tooltips.system.guide-test.pause' | translate}}"
    >
      {{'right-sidebar.pause' | translate}}
    </button>
    <button
      (click)="finishCourse()"
      *ngIf="numPages === state.page"
      class="white finish"
    >
      {{'right-sidebar.finish' | translate}}
    </button>
  </ec-simple-pagination>
  <div class="controls zoom">
    <button
      (click)="zoomOut()"
      [disabled]="state.zoom <= 0.25"
      class="white zoom-out"
    ></button>
    <div
      (mouseenter)="toggleReset(true)"
      (mouseleave)="toggleReset(false)"
      class="reset"
    >
      <div *ngIf="!reset" class="zoom-value">
        {{state.zoom * 100}}%
      </div>
      <div *ngIf="reset">
        <button (click)="resetZoom()" class="white reset-zoom"></button>
      </div>
    </div>
    <button
      (click)="zoomIn()"
      [disabled]="state.zoom >= 5"
      class="white zoom-in"
    ></button>
  </div>
</div>

<form [formGroup]="form">
  <div class="ec-system-form-group ec-system-form-group-radio" *ngIf="master">
    <div class="group-radio-label">
      <label>{{'company.label.status' | translate}}</label>
    </div>
    <div class="group-radio-buttons">
      <div>
        <label>
          <input
            formControlName="companyStatus"
            [value]="1"
            type="radio"
            (click)="enable()"
          />{{'company.label.company' | translate}}</label
        >
      </div>
      <div>
        <label>
          <input
            formControlName="companyStatus"
            [value]="2"
            type="radio"
            (click)="disable()"
          />{{'company.label.independent' | translate}}</label
        >
      </div>
    </div>
  </div>
  <div class="ec-system-form-group" *ngIf="master">
    <ng-select
      class="ec-system-form-dropdown"
      placeholder="{{'invite.placeholder.select-company' | translate}}"
      [items]="companies"
      [searchable]="true"
      bindValue="id"
      bindLabel="name"
      formControlName="companyId"
    >
    </ng-select>
    <span
      class="validation_error__message"
      *ngIf="
                  form.get('companyId').invalid && form.get('companyId').touched
                "
    >
      <span *ngIf="form.get('companyId').errors['required']">
        {{'invite.error.company-id' | translate}}
      </span>
    </span>
  </div>
</form>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ec-role-transformer',
  templateUrl: './role-transformer.component.html',
  styleUrls: ['./role-transformer.component.scss'],
})
export class RoleTransformerComponent implements OnInit {
  @Input() value: string = '';

  constructor() {
  }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import {Confirmation} from '@shared/interfaces/Confirmation';

@Injectable()
export class ConfirmationService {
  private readonly DEFAULT_HEADER = 'Confirmation';
  private readonly DEFAULT_MESSAGE = 'Are you sure?';

  private _confirmAction: Subject<Confirmation> = new Subject<Confirmation>();

  public get status$(): Observable<Confirmation> {
    return this._confirmAction.asObservable();
  }

  public confirm(action: Confirmation): this {
    this._confirmAction.next({
      header: action.header || this.DEFAULT_HEADER,
      message: action.message || this.DEFAULT_MESSAGE,
      reject: action.reject,
      accept: action.accept,
      finalize: action.finalize
    });
    return this;
  }

}

<form *ngIf="loaded" [formGroup]="questionsForm" (ngSubmit)="submit()">
  <div
    formArrayName="questions"
    *ngFor="let question of q.controls; let i = index"
  >
    <div [formGroupName]="i">
      <div class="text">
        <h4 class="flex-between">
          {{question.get('text').value}}
          <a target="_blank"
             [routerLink]="['/system/preview', guideId, question.get('page').value]"
          >
            {{ 'questions-test.link-to-page' | translate }}
          </a>

        </h4>
      </div>
      <div
        formArrayName="answers"
        *ngFor="let answer of question.controls.answers.controls; let j = index"
        class="answer"
      >
        <div [formGroupName]="j">
          <p-checkbox
            class="primeng-checkbox"
            [binary]="true"
            formControlName="correctAnswer"
            (click)="setCorrectAnswer(i,j)"
          ></p-checkbox>
          <span>{{answer.get('text').value}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="guide-details-actions flex-between">
    <div>
      <p-checkbox
        class="primeng-checkbox"
        [binary]="true"
        formControlName="privacy"
        (click)="privacyTerms()"
      ></p-checkbox>
      <a
        *ngIf="language === 'en'; else no"
        href="https://osloliftutleie.no/type-specific-courses-terms-conditions"
        target="_blank"
      >
        {{'questions-test.terms' | translate}}</a
      >
      <ng-template #no>
        <a
          href="https://osloliftutleie.no/typegodkjenning-betingelser-og-vilkar"
          target="_blank"
        >
          {{'questions-test.terms' | translate}}</a
        ></ng-template
      >
    </div>
    <button type="submit" [disabled]="f.invalid || sending">
      {{'questions-test.review' | translate}}
    </button>
  </div>
</form>

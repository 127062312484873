<div class="guide-details">
  <div class="guide-details-title">
    <h4>{{'right-sidebar.title' | translate}}</h4>
  </div>
  <div class="guide-details-file-block">
    <span class="info-text">
      <ec-icon [icon]="'course'"></ec-icon> {{data.name}}</span
    >
    <span class="info-text description">{{data.description}}</span>
    <div class="guide-details-actions side-bar-action">
      <button
        *ngIf="startCourse"
        (click)="onStart()"
        type="button"
        [disabled]="buttonDisabled"
      >
        {{'right-sidebar.start' | translate}}
      </button>
      <button
        *ngIf="sendAnswer"
        (click)="onStart()"
        type="button"
        [disabled]="buttonDisabled"
      >
        {{'questions-test.review' | translate}}
      </button>
    </div>
  </div>
  <div class="guide-details-info">
    <div class="details">
      <div class="row">
        <div class="col w-50">
          {{'settings.block.guides.sidebar.category' | translate}}:
        </div>
        <div class="col w-50">{{data?.category?.type}}</div>
      </div>
      <div class="row">
        <div class="col w-50">
          {{'settings.block.guides.sidebar.price' | translate}}:
        </div>
        <div class="col w-50">
          <span> {{price}}</span>
          <span [style.margin-left.px]="'10'">NOK</span>
        </div>
      </div>
      <div class="row">
        <div class="col w-50">
          {{'right-sidebar.budget-status' | translate}}:
        </div>
        <div class="col w-50">
          {{budget}}{{'right-sidebar.courses-left' | translate}}
        </div>
      </div>
      <div class="row">
        <div class="col w-50">
          {{'settings.block.guides.sidebar.pages' | translate}}:
        </div>
        <div class="col w-50">{{data?.pages}}</div>
      </div>
      <div
        class="row"
        *ngIf="data?.equipments.length && data?.equipments[0].logo"
      >
        <div class="col w-50">
          {{'right-sidebar.equipment' | translate}}:
        </div>
        <img class="col w-50" [src]="data?.equipments[0].logo" />
      </div>
      <div class="row" *ngFor="let equipment of data?.equipments">
        <div class="col w-50">
          {{'right-sidebar.equipment-name' | translate}}:
        </div>
        <div class="col w-50">{{equipment.name}}</div>
      </div>
    </div>
  </div>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { IRole } from '@shared/interfaces/IResponse';
import { NotificationService } from '@shared/services/notification.service';
import { ROLE_IDS, RoleService } from '@shared/services/role.service';
import { ProfileService } from 'app/system/services/profile.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiMainService } from '@shared/services/api.main.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UploadService } from '@shared/services/upload.service';
import { CookiesService } from '@shared/services/cookie.service';
import { CompanySubjectService } from '../../../../service/company.subject.service';

@Component({
  selector: 'ec-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss'],
})
export class EditCompanyComponent implements OnInit {
  public companyForm: FormGroup;
  sending: boolean = false;
  editBudget: boolean = false;
  priceList;
  loaded: boolean = false;
  isFreeBudget: boolean = false;
  private companyLogoFile: File;
  public temporary: any;
  public mask = [
    '+',
    /\d/,
    /\d/,
    ' ',
    /[0-9]/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ];
  numberUnmask = /\D/g;
  private language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private notification: NotificationService,
    private roleService: RoleService,
    private apiService: ApiMainService,
    private uploadService: UploadService,
    private cookie: CookiesService,
    private companySubjectService: CompanySubjectService
  ) {}

  // Form
  get formData() {
    return this.companyForm as FormGroup;
  }

  // Contacts
  get formContacts() {
    return this.formData.get('contacts') as FormArray;
  }

  // Locations
  get formLocations() {
    return this.formData.get('locations') as FormArray;
  }

  // Web
  get formWeb() {
    return this.formData.get('web') as FormArray;
  }
  // COMPANY FREE BUDGET
  get fb(): FormControl {
    return this.formData.get('freeBudget') as FormControl;
  }
  // HIGH PRICE
  get hp(): FormGroup {
    return this.formData.get('highPriceBudget') as FormGroup;
  }
  // LOW PRICE
  get lp(): FormGroup {
    return this.formData.get('lowPriceBudget') as FormGroup;
  }

  ngOnInit(): void {
    this.getPriceList();
    this.userRoleCheck();
    console.log(this.companyForm);
  }

  public onFileChange(e) {
    this.companyLogoFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      this.temporary = evt.target.result;
    };
    reader.readAsDataURL(this.companyLogoFile);
  }

  public activateInput() {
    document.getElementById('file-input').click();
  }

  submitForm(): void {
    const form = this.controlRemoving(this.formData.getRawValue());
    form.highPriceBudget.discount = this.convertToDigits(
      'highPriceBudget',
      'discount'
    );
    form.highPriceBudget.quantity = this.convertToDigits(
      'highPriceBudget',
      'quantity'
    );

    if (!this.fb.value) {
      form.lowPriceBudget.discount = this.convertToDigits(
        'lowPriceBudget',
        'discount'
      );
      form.lowPriceBudget.quantity = this.convertToDigits(
        'lowPriceBudget',
        'quantity'
      );
    }
    if (this.companyForm.valid) {
      this.sending = true;
      this.profileService.updateCompany(form).subscribe((res) => {
        this.sending = false;
        if (!(res instanceof HttpErrorResponse)) {
          if (this.companyLogoFile) {
            this.uploadService
              .uploadProfileLogo(this.companyLogoFile, form.id, 'companyId')
              .subscribe((result) => {
                console.log('Uploading result', result);
                this.temporary = result.data.path;
                if (this.roleService.user.companyId === form.id) {
                  this.companySubjectService.setCompanyLogo(result.data.path);
                  this.cookie.set(
                    'Company',
                    JSON.stringify(
                      Object.assign(form, { logo: result.data.path })
                    )
                  );
                }
                this.response();
              });
          } else {
            if (this.roleService.user.companyId === form.id) {
              this.cookie.set('Company', JSON.stringify(form));
            }
            this.response();
          }
        }
      });
    }
  }

  // Form Builders

  cancel(): void {
    this.ref.close();
  }

  private convertToDigits(control, field) {
    return +this.formData.get(control).get(field).value;
  }

  // Validators
  public hasError(control, field): boolean {
    return control.get(field).hasError('required');
  }

  public isTouched(control, field): boolean {
    return control.get(field).touched;
  }

  private userRoleCheck() {
    const userRoles = this.roleService.roles;
    if ((userRoles || []).length) {
      if (userRoles.some((r: IRole) => r.id === ROLE_IDS.MASTER)) {
        return (this.editBudget = true);
      }
    }
  }

  private getPriceList() {
    this.apiService.getPriceList().subscribe((priceList) => {
      this.priceList = priceList;
      this.formInit();
    });
  }
  private formInit(): void {
    const company = this.config.data;
    this.companyForm = this.formBuilder.group({
      id: [company.id],
      name: [company.name, [Validators.required]],
      logo: [company.logo],
      vat: [company.vat],
      companyTypeId: [company.companyTypeId],
      paymentStatusId: [company.paymentStatusId],
      businessStatusId: [company.businessStatusId],
      businessTypeId: [company.businessTypeId],
      locations: this.formBuilder.array([]),
      contacts: this.formBuilder.array([]),
      freeBudget: [company.highPriceBudget.unlimited],
      highPriceBudget: this.formBuilder.group({
        id: [company.highPriceBudget.id],
        companyId: [company.id],
        priceId: [company.highPriceBudget.priceId],
        quantity: [
          company.highPriceBudget.unlimited
            ? 0
            : company.highPriceBudget.quantity,
        ],
        discount: [company.highPriceBudget.discount],
        unlimited: [company.highPriceBudget.unlimited],
      }),
      lowPriceBudget: this.formBuilder.group({
        id: [
          company.lowPriceBudget && company.lowPriceBudget.id
            ? company.lowPriceBudget.id
            : null,
        ],
        companyId: [company.id],
        priceId: [
          company.lowPriceBudget && company.lowPriceBudget.priceId
            ? company.lowPriceBudget.priceId
            : null,
        ],
        quantity: [
          company.lowPriceBudget && company.lowPriceBudget.quantity
            ? company.lowPriceBudget.quantity
            : 0,
        ],
        discount: [
          company.lowPriceBudget && company.lowPriceBudget.discount
            ? company.lowPriceBudget.discount
            : 0,
        ],
      }),
    });
    this.isFreeBudget = company.highPriceBudget.unlimited;
    if (this.isFreeBudget) {
      this.hp.get('quantity').disable();
    }
    console.log(this.companyForm.value);
    this.locationsFormBuilder(company.locations, company);
    this.contactsFormBuilder(company.contacts, company);
    this.loaded = true;
    this.lowPriceBudgetChange();
    this.highPriceBudgetChange();
  }

  // Contacts
  private contactsFormBuilder(contacts, data): FormGroup {
    const control = this.formContacts;
    if (contacts && contacts.length) {
      return contacts.forEach((contact) => {
        control.push(
          this.formBuilder.group({
            id: [contact.id],
            companyId: [data.id],
            phone: [
              contact.phone,
              [Validators.required, Validators.minLength(8)],
            ],
            email: [contact.email, [Validators.required]],
          })
        );
      });
    }
    control.push(this.emptyContactsFormBuilder(data));
  }

  private emptyContactsFormBuilder(data): FormGroup {
    return this.formBuilder.group({
      id: [null],
      companyId: [data.id],
      phone: [null, [Validators.required]],
      email: [null, [Validators.required]],
    });
  }

  // Locations
  private locationsFormBuilder(locations, data): FormGroup {
    const control = this.formLocations;
    if (locations && locations.length) {
      return locations.forEach((location) => {
        control.push(
          this.formBuilder.group({
            id: [location.id],
            companyId: [data.id],
            country: [location.country, [Validators.required]],
            city: [location.city, [Validators.required]],
            street: [location.street, [Validators.required]],
            zip: [location.zip, [Validators.required]],
          })
        );
      });
    }
    control.push(this.emptyLocationsFormBuilder(data));
    return;
  }

  private emptyLocationsFormBuilder(data): FormGroup {
    return this.formBuilder.group({
      id: [null],
      companyId: [data.id],
      country: [null, [Validators.required]],
      city: [null, [Validators.required]],
      street: [null, [Validators.required]],
      zip: [null, [Validators.required]],
    });
  }

  private response() {
    this.notification.send(
      this.language === 'en'
        ? 'Company updated successfully!'
        : 'Selskapet ble oppdatert',
      'info'
    );
    this.ref.close({
      status: 200,
      company: Object.assign(this.companyForm.value, { logo: this.temporary }),
    });
  }

  public toggleBudget(): void {
    this.isFreeBudget = this.fb.value;

    if (!this.fb.value) {
      this.setBudget();
    } else {
      this.freeBudget();
    }
    console.log(this.formData.getRawValue());
  }

  private freeBudget() {
    this.hp.get('quantity').clearValidators();
    this.hp.get('quantity').patchValue(0);
    this.hp.get('quantity').disable();

    this.hp.get('unlimited').patchValue(true);
  }

  private setBudget() {
    this.hp.get('quantity').enable();
    this.hp.get('quantity').patchValue(null);
    this.hp.get('unlimited').patchValue(false);
    this.lowPriceBudgetChange();
    console.log(this.formData.value);
  }

  private lowPriceBudgetChange() {
    this.lp
      .get('discount')
      .valueChanges.pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(() => {
        if (this.lp.get('discount').value > 0) {
          this.lp.get('quantity').setValidators([Validators.required]);
          this.lp.get('quantity').updateValueAndValidity();
        } else {
          this.lp.get('quantity').setValidators(null);
          this.lp.get('quantity').updateValueAndValidity();
        }
      });
  }
  private highPriceBudgetChange() {
    this.hp
      .get('discount')
      .valueChanges.pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(() => {
        if (!this.isFreeBudget) {
          if (this.hp.get('discount').value > 0) {
            this.hp.get('quantity').setValidators([Validators.required]);
            this.hp.get('quantity').updateValueAndValidity();
          } else {
            this.hp.get('quantity').setValidators(null);
            this.hp.get('quantity').updateValueAndValidity();
          }
        }
      });
  }
  private controlRemoving(control) {
    Object.keys(control).forEach((key) => {
      if (key === 'freeBudget') {
        delete control[key];
      }
    });
    return control;
  }
}

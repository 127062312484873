<div class="load-container load-appearance" *ngIf="!loaded; else qualifiedList">
  <div class="content">
    <ec-preloader></ec-preloader>
  </div>
</div>
<ng-template #qualifiedList>
  <ec-data-grid #grid
                (onRowClick)="onRowSelected($event)"
                (onDoubleRowClick)="onDoubleRowSelected($event)"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [gridOptions]="gridOptions"
                [paginationSize]="paginationSize"
                [rowData]="rowData"
                [height]="height"
                [title]="title">
  </ec-data-grid>
</ng-template>

import { Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { RoleService } from '@shared/services/role.service';
import { Subscription } from 'rxjs';
import { NavBarService } from '@shared/services/nav-bar.service';

interface ISidebarRoute {
  name: string;
  tooltip: string;
}

@Component({
  selector: 'ec-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  defaultRoutes: ISidebarRoute[] = [
    {
      name: 'list',
      tooltip: 'tooltips.system.sidebar.list',
    },
    {
      name: 'signed',
      tooltip: 'tooltips.system.sidebar.signed',
    },
    {
      name: 'user-list',
      tooltip: 'tooltips.system.sidebar.user-list',
    },
    {
      name: 'console',
      tooltip: '',
    },
  ];
  role$: Subscription;
  routes: ISidebarRoute[] = [];
  count: number = 0;
  @Input() scrWidth: number;
  @ViewChild('sidebar', { static: false }) sidebar;

  constructor(
    public service: RoleService,
    public navBarService: NavBarService
  ) {}

  ngOnInit(): void {
    this.service.currentRole$.subscribe((role) => {
      this.routes = [...this.defaultRoutes];
      if (this.service.isOperator() || this.service.isAdmin()) {
        this.routes.pop();
      }
    });
    this.onDocumentClick = this.onDocumentClick.bind(this);
    document.addEventListener('click', this.onDocumentClick);
  }

  ngOnDestroy(): void {
    if (this.role$) {
      this.role$.unsubscribe();
    }
    document.removeEventListener('click', this.onDocumentClick);
  }

  protected onDocumentClick(event: MouseEvent) {
    if (this.sidebar.nativeElement.contains(event.target)) {
      return;
    }
    this.count++;
    if (this.count > 1 && this.scrWidth <= 768) {
      return this.navBarService.hide();
    }
  }
}

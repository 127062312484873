import { Injectable } from '@angular/core';
import { IUser } from '@shared/interfaces/IResponse';
import { IGuide } from '@shared/models/upload/upload.models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUser: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);

  constructor() {
  }

  get user() {
    return {...this.currentUser.getValue()};
  }

  set user(user: IUser) {
    this.currentUser.next(user);
  }

  getUser$() {
    return this.currentUser;
  }

  checkAvailability(id: number): boolean {
    return this.user.guides.some((g) => {
      if (g.id === id) {
        return true;
      }
    });
  }

  checkAvailabilityByID(id: number): boolean {
    return this.user.guides.some((guide) => {
      return guide.id === id;
    });
  }

  userGuide(processId: number) {
    return this.user.guides.find(p => p.id === processId);
  }

  updatePassingProcess(process: any): void {
    this.user.guides.map(g => {
      if (+g.passingProcess.id === + process.id) {
        g.passingProcess.time = process.time;
        g.passingProcess.page = process.page;
        g.passingProcess.statusId = process.statusId;
        g.passingProcess.certificationStatusId = process.certificationStatusId;
      }
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabMenuModule } from 'primeng/tabmenu';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';

const primengModules = [
  CalendarModule,
  DynamicDialogModule,
  TabMenuModule,
  MenuModule,
  ConfirmDialogModule,
  ProgressSpinnerModule,
  CheckboxModule,
  RadioButtonModule
];
const primengServices = [ConfirmationService, DialogService];

@NgModule({
  imports: [CommonModule, ...primengModules],
  exports: [...primengModules],
  entryComponents: [DynamicDialogModule],
  declarations: [],
  providers: [...primengServices]
})
export class PrimeNGModule {}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { ApiAuthService } from '@shared/services/api.auth.service';
import { CookiesService } from '@shared/services/cookie.service';
import { RoleService } from '@shared/services/role.service';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import * as fromModals from '../dialog-components';
import {
  DEFAULT_SEARCH_SET,
  SEARCH_PLACEHOLDER,
} from '@shared/constans/default';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, pluck } from 'rxjs/operators';
import { SearchingSubjectService } from '../../../service/searching.subject.service';
import { CompanySubjectService } from '../../../service/company.subject.service';
import { NavBarService } from '@shared/services/nav-bar.service';
import { LanguageSubjectService } from '../../../service/language.subject.service';

@Component({
  selector: 'ec-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  items;
  company;
  personAbbreviation: string;
  filter: any = DEFAULT_SEARCH_SET;
  placeholder: string = '';
  logo: string;
  subscription: Subscription;
  private language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';

  constructor(
    private searchingService: SearchingSubjectService,
    private apiAuthService: ApiAuthService,
    private cookieService: CookiesService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public role: RoleService,
    private router: Router,
    private companySubjectService: CompanySubjectService,
    public navBarService: NavBarService,
    private languageSubjectService: LanguageSubjectService
  ) {}

  ngOnInit() {
    this.subscription = this.companySubjectService.companyLogoSbj$.subscribe(
      (logo) => {
        this.logo = logo;
      }
    );
    this.getProfile();
    this.items = this.dropdownInit();

    this.placeholder = SEARCH_PLACEHOLDER[this.router.url];
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.filter.search = '';
        this.placeholder = SEARCH_PLACEHOLDER[this.router.url];
      }
    });

    fromEvent(document.getElementById('search'), 'keyup')
      .pipe(pluck('target', 'value'), debounceTime(500))
      .subscribe((value: string) => {
        this.searchingService.setSearchFilter(
          Object.assign(DEFAULT_SEARCH_SET, { search: value })
        );
      });
    this.storageChange();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public logout(): void {
    this.apiAuthService.logout().subscribe(() => {});
  }

  private getProfile(): void {
    this.company = this.jsonParseFunction('Company');
    this.personAbbreviation = this.getAbbreviation(
      this.jsonParseFunction('User')
    );
  }

  private getAbbreviation(person): string {
    if (person.firstname && person.lastname) {
      return person.firstname.charAt(0) + person.lastname.charAt(0);
    }
  }

  private openConfirmModal() {
    this.language = localStorage.getItem('Language');
    this.confirmationService.confirm({
      message:
        this.language === 'en'
          ? 'Are you sure you want to log out?'
          : 'Er du sikker på at du vil logge ut?',
      accept: () => this.logout(),
    });
  }

  private dropdownInit() {
    return [
      {
        label: this.language === 'en' ? 'My profile' : 'Min profil',
        icon: 'pi pi-user',
        command: () => this.router.navigate(['system/profile']),
      },
      {
        label: this.language === 'en' ? 'My company' : 'Mitt selskap',
        icon: 'pi pi-sitemap',
        command: () =>
          this.router.navigate([
            '/system/company',
            this.jsonParseFunction('User').companyId,
          ]),
      },
      {
        label: this.language === 'en' ? 'Change password' : 'Bytt passord',
        icon: 'pi pi-exclamation-triangle',
        command: () => this.openChangePasswordModal(),
      },
      {
        label: this.language === 'en' ? 'Log out' : 'Logg ut',
        icon: 'pi pi-sign-out',
        command: () => this.openConfirmModal(),
      },
    ];
  }

  private jsonParseFunction(type: string) {
    return JSON.parse(this.cookieService.get(`${type}`));
  }
  openMenu(element, event) {
    this.language = localStorage.getItem('Language');
    this.items = this.dropdownInit();
    element.toggle(event);
  }

  private openChangePasswordModal() {
    this.dialogService.open(fromModals.ChangePasswordComponent, {
      styleClass: 'primeng-dialog-container',
    });
  }
  private storageChange() {
    this.languageSubjectService.watchStorage().subscribe((lang: string) => {
      this.language = lang;
      this.items = this.dropdownInit();
    });
  }
}

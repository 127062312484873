import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular/main';

@Component({
  selector: 'ec-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements ICellRendererAngularComp {
  value;
  private params: any;

  agInit(params: any): void {
    this.params = params;
    if (this.params && this.params.value) {
      this.value = (this.params.value || []).length;
    }
  }

  refresh(): boolean {
    return false;
  }
  editCompany() {
    this.params.context.componentParent.onCompanyEdit(this.params.data);
  }
}

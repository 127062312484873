<div class="profile-wrapper">
  <div class="profile-form">
    <form
      (ngSubmit)="submitForm()"
      [formGroup]="changePasswordForm"
      class="system-form system-form-change-password"
    >
      <div class="system-form-title">
        <div class="first-column">
          <span>{{'change-password.title' | translate}}</span>
        </div>
      </div>
      <div class="system-form-group">
        <div class="system-form-control">
          <input
            formControlName="oldPassword"
            placeholder="{{'change-password.placeholders.current-password' | translate}}"
            type="password"
          />
          <span
            *ngIf="
              changePasswordForm.get('oldPassword').invalid &&
              changePasswordForm.get('oldPassword').touched
            "
            class="validation_error__message"
          >
            <span
              *ngIf="changePasswordForm.get('oldPassword').errors['required']"
              >{{'change-password.errors.password-required' | translate}}
            </span>
          </span>
        </div>
        <div class="system-form-control">
          <input
            formControlName="password"
            placeholder="{{'change-password.placeholders.new-password' | translate}}"
            type="password"
          />
          <span
            *ngIf="
              changePasswordForm.get('password').invalid &&
              changePasswordForm.get('password').touched
            "
            class="validation_error__message"
          >
            <span *ngIf="changePasswordForm.get('password').errors['required']">
              {{'change-password.errors.new-password-required' | translate}}
            </span>
          </span>
        </div>
        <div class="system-form-control">
          <input
            formControlName="confirmPassword"
            placeholder="{{'change-password.placeholders.repeat-password' | translate}}"
            type="password"
          />
          <span class="validation_error__message">
            <span
              *ngIf="
          changePasswordForm.get('confirmPassword').errors &&
          changePasswordForm.get('confirmPassword').errors['ConfirmPassword']
        "
              >{{'change-password.errors.match' | translate}}</span
            >
          </span>
        </div>
      </div>
      <div class="system-form-actions system-form-change-password-actions">
        <button (click)="cancel()" type="button">
          {{'action-button.cancel' | translate}}
        </button>
        <button
          [disabled]="changePasswordForm.invalid || sending"
          type="submit"
        >
          <span *ngIf="!sending"> {{'action-button.change' | translate}}</span>
          <ec-preloader *ngIf="sending"></ec-preloader>
        </button>
      </div>
    </form>
  </div>
</div>

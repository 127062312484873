import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ec-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Equipment Certification';
  ngOnInit(): void {
    localStorage.removeItem('Language');
    localStorage.setItem('Language', 'no');
  }
}

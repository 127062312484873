<div #element
     class="wrapper">
  <div
    (click)="show($event)"
       [class.hovered]="hovered"
       class="pointer">
    <div class="dots">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITab } from '@components/tabs/tabs.component';

@Component({
  selector: 'ec-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit {
  @Input() active: boolean = false;
  @Input() index: number;
  @Input() tab: ITab;
  @Output() action: EventEmitter<ITab> = new EventEmitter<ITab>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onSelect() {
    this.action.emit(this.tab);
  }
}

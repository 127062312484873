import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import apiRoutes from '@shared/constans/apiRoutes';
import { IResponse } from '@shared/interfaces/IResponse';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookiesService } from './cookie.service';

import { HttpService } from './http.service';

@Injectable()
export class ApiMainService {
  constructor(private http: HttpService, private cookie: CookiesService) {}

  getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.cookie.get('Authorization'),
    });
  }

  generateLink(companyId: number) {
    return this.http.get(`/api/company/${companyId}/shared-link`).pipe(
      map((result) => result),
      catchError((err) => {
        return of(err);
      })
    );
  }

  public getUserList(options: any = {}): Observable<any> {
    return this.http.post('/api/user/list', options).pipe(
      map((result) => {
        if (!(result instanceof HttpErrorResponse)) {
          return result.data.data;
        }
      }),
      catchError((err) => {
        return of(err);
      })
    );
  }

  public manageUserRole(data: {
    userId: number;
    roleId: number;
    adding: boolean;
  }): Observable<any> {
    return this.http.patch('/api/user/role', data).pipe(
      map((result) => result),
      catchError((err) => {
        return of(err);
      })
    );
  }

  public suspendUser(id: number): Observable<any> {
    return this.http.delete(`/api/user/${id}`).pipe(
      map((result) => result),
      catchError((err) => {
        return of(err);
      })
    );
  }

  public returnUser(id: number): Observable<any> {
    return this.http.get(`/api/user/return/${id}`).pipe(
      map((result) => result),
      catchError((err) => {
        return of(err);
      })
    );
  }

  public getUser(id: number) {
    return this.http.get(`/api/user/${id}`).pipe(
      map((result) => result),
      catchError((err) => {
        return of(err);
      })
    );
  }

  public getCompanyList(options: any = {}): Observable<any> {
    return this.http.post('/api/company/list', options).pipe(
      map((result) => {
        if (!(result instanceof HttpErrorResponse)) {
          return result.data.data;
        }
      }),
      catchError((err) => {
        return of(err);
      })
    );
  }

  public getCompany(id: number) {
    return this.http.get(`/api/company/${id}`).pipe(
      map((result) => result),
      catchError((err) => {
        return of(err);
      })
    );
  }

  public getGuideList(options: any = {}): Observable<any> {
    return this.http.post('/api/guide/list', options).pipe(
      map((result) => {
        if (!(result instanceof HttpErrorResponse)) {
          return result.data.data;
        }
      }),
      catchError((err) => {
        return of(err);
      })
    );
  }

  public getUserGuideList(options: any = {}): Observable<any> {
    return this.http.post('/api/user/guide/list', options).pipe(
      map((result) => {
        if (!(result instanceof HttpErrorResponse)) {
          return result.data.data;
        }
      }),
      catchError((err) => {
        return of(err);
      })
    );
  }

  public getGuide(id: number) {
    return this.http.get(`/api/guide/${id}`).pipe(
      map((result) => result),
      catchError((err) => {
        return of(err);
      })
    );
  }

  public fullGuideDelete(id: number): Observable<any> {
    return this.http.delete(`/api/fullGuideDeletion/${id}`)
      .pipe(
        map((result) => result),
        catchError((err) => {
          return of(err);
        })
      );
  }

  public fullUserDelete(id: number): Observable<any> {
    return this.http.delete(`/api/fullUserDeletion/${id}`)
      .pipe(
        map((result) => result),
        catchError((err) => {
          return of(err);
        })
      );
  }

  public fullCompanyDelete(id: number): Observable<any> {
    return this.http.delete(`/api/fullCompanyDeletion/${id}`)
      .pipe(
        map((result) => result),
        catchError((err) => {
          return of(err);
        })
      );
  }

  public getDiscountList(options: any = {}): Observable<any> {
    return this.http.post('/api/discount/list', options).pipe(
      map((result) => result),
      catchError((err) => {
        return of(err);
      })
    );
  }

  public getCategoryList(): Observable<any> {
    return this.http
      .get(apiRoutes.guide.categoryList, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response: IResponse) => {
          return response.data;
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        })
      );
  }

  public getPriceList() {
    return this.http
      .get(apiRoutes.guide.priceList, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response: IResponse) => {
          console.log(response);
          return response.data;
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        })
      );
  }

  public getProcessById(id: number) {
    return this.http
      .get(`${apiRoutes.guide.guide}/${id}`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response: IResponse) => {
          return response.data;
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        })
      );
  }
  public userLanguage(lang) {
    return this.http.post(apiRoutes.user.switchLanguage, lang).pipe(
      map((response: IResponse) => {
        return response.data;
      }),
      catchError((err: Error) => {
        console.log(err);
        return of(err);
      })
    );
  }

  public setUserGuide(data: IGetGuide) {
    return this.http.post('/api/user/guide', data).pipe(
      map((result) => result),
      catchError((err) => {
        return of(err);
      })
    );
  }

  dismiss(userId: string, guideId: string, statusId: string) {
    const formData = new FormData();
    formData.append('auto', 'true');
    formData.append('guideId', guideId);
    formData.append('userId', userId);
    formData.append('statusId', statusId);
    return this.http.post('/api/user/guide/completion', formData);
  }

  accept(userId: string, guideId: string, statusId: string, data?: string) {
    const formData = new FormData();
    if (data) {
      const file = new File([this.DataURIToBlob(data)], 'signature.png');
      formData.append('signature', file);
    } else {
      formData.append('auto', 'true');
    }
    formData.append('guideId', guideId);
    formData.append('userId', userId);
    formData.append('statusId', statusId);
    return this.http.post('/api/user/guide/completion', formData);
  }

  operatorSigning(userId: string, guideId: string, data: string) {
    const formData = new FormData();
    const file = new File([this.DataURIToBlob(data)], 'signature.png');
    formData.append('signature', file);
    formData.append('guideId', guideId);
    formData.append('userId', userId);
    return this.http.post('/api/user/signing', formData);
  }

  DataURIToBlob(dataURI: string): Blob {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  exportInvoices(body: {
    startDate: string;
    endDate: string;
    search: string;
    companyId?: number;
  }) {
    return this.http.post('/api/invoice/export', body).pipe(
      map((response) => {
        const contentType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const array = new Uint8Array(response.data.data);
        const blob = new Blob([array], { type: contentType });
        return blob;
      }),
      catchError((err) => {
        return of(err);
      })
    );
  }
}

export interface IGetGuide {
  userId: number;
  guideId: number;
  companyId: number;
  experienceLevelId: number;
}

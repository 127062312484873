<div class="load-container load-appearance" *ngIf="!loaded; else guideList">
  <div class="content">
    <ec-preloader></ec-preloader>
  </div>
</div>
<ng-template #guideList>
  <ec-data-grid
    #grid
    (onRowClick)="onRowSelected($event)"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    [title]="title"
    [console]="console"
  >
    <ec-right-sidebar
      #sidebar
      (onAction)="openDetails()"
      (onClose)="grid.close()"
      [button]="button"
      [data]="selected"
      icon="course"
    >
      <ec-tabs [guide]="selected"></ec-tabs>
    </ec-right-sidebar>
  </ec-data-grid>
  <ec-single-guide-details
    #details
    (onAction)="navigateTo($event)"
    (onClose)="closeDetails()"
    [data]="selected"
  >
    <ec-guide-information
      #block
      (onAction)="navigateTo(this.form.value)"
      (onClose)="details.hide()"
      [buttonDisabled]="this.form.invalid"
      [button]="'start'"
      [title]="'information'"
    >
      <ol description>
        <li>{{'course-important-information.1' | translate}}</li>
        <li>{{'course-important-information.2' | translate}}</li>
        <li>{{'course-important-information.3' | translate}}</li>
        <li>{{'course-important-information.4' | translate}}</li>
      </ol>

      <div form>
        <p>{{'experience.text.experience' | translate}}</p>
        <form
          [formGroup]="form"
          class="radio-group single"
          title="{{'tooltips.system.guide-test.dashboard' | translate}}"
        >
          <div class="form-control">
            <input
              formControlName="level"
              id="j"
              name="level"
              type="radio"
              value="1"
            />
            <label for="j">
              {{'experience.begginer' | translate}} (0-5 {{'experience.times' |
              translate}})
            </label>
          </div>
          <div class="form-control">
            <input
              formControlName="level"
              id="m"
              name="level"
              type="radio"
              value="2"
            />
            <label for="m">
              {{'experience.trained' | translate}} (6-20 {{'experience.times' |
              translate}})
            </label>
          </div>

          <div class="form-control">
            <input
              formControlName="level"
              id="s"
              name="level"
              type="radio"
              value="3"
            />
            <label for="s">
              {{'experience.expert' | translate}} (20 + {{'experience.times' |
              translate}})
            </label>
          </div>
          <div class="guide-details-actions">
            <button
              type="button"
              (click)="navigateTo(this.form.value)"
              [disabled]="this.form.invalid"
              class="guide-list-button"
            >
              {{'right-sidebar.start' | translate}}
            </button>
          </div>
        </form>
      </div>
    </ec-guide-information>

    <ec-guide-information-sidebar
      #sidebar
      (onAction)="navigateTo(this.form.value)"
      (onClose)="details.hide()"
      *ngIf="selected"
      [buttonDisabled]="this.form.invalid"
      [startCourse]="true"
      [data]="selected"
      [prices]="prices"
    >
    </ec-guide-information-sidebar>
  </ec-single-guide-details>
</ng-template>

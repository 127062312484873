<div class="page-not-found">
  <div class="content">
    <span class="shadow">404</span>
    <h1 class="title">
      Oops! We lost this page
    </h1>
    <p class="description">
      This page is missing or you assembled the link incorrectly
    </p>
    <p class="code">
      Code error: 404
    </p>
    <button [routerLink]="['/', 'system']" class="home-btn blue rounded">Home page</button>
  </div>
</div>

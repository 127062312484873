import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import apiRoutes from '@shared/constans/apiRoutes';
import { IAuthResponse, IResponse } from '@shared/interfaces/IResponse';
import { CookiesService } from '@shared/services/cookie.service';
import { HttpService } from '@shared/services/http.service';
import { RoleService } from '@shared/services/role.service';
import { UserService } from '@shared/services/user.service';
import { ProfileService } from 'app/system/services/profile.service';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CompanySubjectService } from '../../service/company.subject.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn: boolean = false;
  private authToken: string;

  constructor(
    private http: HttpService,
    private router: Router,
    private cookie: CookiesService,
    private profileService: ProfileService,
    private rolesService: RoleService,
    private userService: UserService,
    private companySubjectService: CompanySubjectService
  ) {}

  get token() {
    return this.authToken;
  }

  login(data: ILogin): Observable<any> {
    return this.http.post(apiRoutes.auth.login, data).pipe(
      tap((response: IAuthResponse) => {
        if (response && response.data) {
          this.authToken = response.data.tokens.access;
          this.setCookies(response.data);
          this.rolesService.user = response.data.user;
          this.userService.user = response.data.user;
          this.companySubjectService.setCompanyLogo(
            response.data.user.company.logo
          );
          return response;
        }
      }),
      catchError((err: Error) => {
        return of(err);
      })
    );
  }

  registration(data: IRegistration) {
    return this.http.post(apiRoutes.auth.registration, data).pipe(
      map((resp: IResponse) => {
        return resp;
      }),
      catchError((err) => {
        console.log(err);
        return of(err);
      })
    );
  }

  operatorRegistration(data) {
    return this.http.post(apiRoutes.auth.operatorRegistration, data).pipe(
      map((resp: IResponse) => {
        return resp;
      }),
      catchError((err) => {
        return of(err);
      })
    );
  }

  restore(data) {
    return this.http.post(apiRoutes.user.restore, data).pipe(
      map((resp: IResponse) => {
        return { status: resp.status, message: resp.message };
      }),
      catchError((err: Error) => {
        console.log(err);
        return of(err);
      })
    );
  }

  refreshToken() {
    return this.http
      .get(apiRoutes.auth.refreshToken, {
        headers: { refresh: this.cookie.get('Refresh') },
      })
      .pipe(
        map((response: IResponse) => {
          if (!(response instanceof HttpErrorResponse)) {
            this.authToken = response.data.access;
            this.cookie.set('Authorization', response.data.access);
            this.cookie.set('Refresh', response.data.refresh);
            return response.data;
          } else {
            return response;
          }
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        })
      );
  }

  checkIfVatNumberAvailability(data) {
    return this.http.post(apiRoutes.auth.vatCheck, data).pipe(
      map((response: IResponse) => {
        console.log(response);
        return response.data;
      })
    );
  }

  checkIfEmailAvailability(data) {
    return this.http.post(apiRoutes.auth.emailCheck, data).pipe(
      map((response: IResponse) => {
        console.log(response);
        return response.data;
      })
    );
  }

  isUserAuthenticated() {
    return this.http.get(apiRoutes.auth.isUserAuthenticated).pipe(
      map((resp: IResponse) => {
        return resp.data;
      }),
      catchError((err: Error) => {
        console.log(err);
        return of(err);
      })
    );
  }

  isUserLoggedIn(): boolean {
    return this.isLoggedIn;
  }

  navigateTo(path: string[]) {
    this.router.navigate(path);
  }

  logout() {
    return this.http
      .get(apiRoutes.auth.logout, {
        headers: { access: this.cookie.get('Authorization') },
      })
      .pipe(
        map((resp: IResponse) => {
          this.cookie.deleteAll();
          localStorage.clear();
          return resp;
        }),
        catchError((err: Error) => {
          console.log('ERROR LOGOUT', err);
          return of(err);
        })
      );
  }

  changePassword(data) {
    return this.http.patch(apiRoutes.auth.changePassword, data).pipe(
      map((resp) => {
        return resp;
      }),
      catchError((err: Error) => {
        console.log(err);
        return of(err);
      })
    );
  }

  private setCookies(data) {
    this.cookie.set('Authorization', data.tokens.access);
    this.cookie.set('Refresh', data.tokens.refresh);
    this.cookie.set('User', JSON.stringify(data.user));
    this.cookie.set('Company', JSON.stringify(data.user.company));
  }
}

interface ILogin {
  Email: string;
  Password: string;
}

interface IRegistration {
  email: string;
  firstName: string;
  lastName: string;
  birthday: string;
  password: string;
  companyId: number;
  isOwner: boolean;
}

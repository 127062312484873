import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { forkJoin } from 'rxjs';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpErrorResponse } from '@angular/common/http';

import { NotificationService } from '@shared/services/notification.service';
import { ApiMainService } from '@shared/services/api.main.service';
import { InviteOperatorService } from '../../../../../../system/services/invite-operator.service';
import { AsyncValidator } from '@shared/validators/async-vat.validator';
import { AuthService } from '@auth/services/auth.service';
import { CreateCompanyService } from '../../create-company.service';
import { ProfileService } from '../../../../../../system/services/profile.service';
import { LanguageSubjectService } from 'app/service/language.subject.service';

@Component({
  selector: 'ec-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnInit {
  inviteForm: FormGroup;

  priceList;

  companyData;

  loaded: boolean = false;
  sending: boolean = false;
  private language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';
  constructor(
    private formBuilder: FormBuilder,
    public ref: DynamicDialogRef,
    private notification: NotificationService,
    private apiService: ApiMainService,
    private inviteService: InviteOperatorService,
    private authService: AuthService,
    private createCompanyService: CreateCompanyService,
    private profileService: ProfileService,
    private languageSubjectService: LanguageSubjectService
  ) {}

  ngOnInit(): void {
    this.inviteComponentData();
    this.storageChange();
  }
  private serviceCompanyData(): void {
    this.createCompanyService.createdCompanyDataSubject.subscribe(
      (company) => (this.companyData = company)
    );
  }
  private inviteComponentData() {
    const priceList = this.apiService.getPriceList();
    forkJoin([priceList]).subscribe((response) => {
      this.priceList = response[0];
      this.initForm();
    });
  }
  private initForm(): void {
    this.inviteForm = this.formBuilder.group({
      email: [
        null,
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
        AsyncValidator.emailValidator(this.authService),
      ],
      roleId: [2],
      highPriceBudget: this.formBuilder.group({
        quantity: [null],
      }),
      lowPriceBudget: this.formBuilder.group({
        quantity: [null],
      }),
      independentOperator: [false],
    });
    console.log(this.inviteForm.value);
    this.loaded = true;
  }
  submit() {
    this.serviceCompanyData();
    if (this.inviteForm.valid && Object.keys(this.companyData).length > 0) {
      this.profileService
        .createCompany(this.companyData)
        .subscribe((companyResponse) => {
          if (!(companyResponse instanceof HttpErrorResponse)) {
            const createdCompanyId = companyResponse.data.id;
            this.i.addControl('companyId', new FormControl(createdCompanyId));
            this.priceQuantityCheck(this.hp, 'highPriceBudget');
            this.priceQuantityCheck(this.lp, 'lowPriceBudget');
            const inviteForm = this.inviteForm.value;
            this.sending = true;
            this.inviteService
              .inviteUser(inviteForm)
              .subscribe((inviteResponse) => {
                this.sending = false;
                if (!(inviteResponse instanceof HttpErrorResponse)) {
                  console.log(inviteResponse.message);
                  this.notification.send(
                    this.language === 'en'
                      ? `User ${
                          this.i.get('email').value
                        } invited successfully!`
                      : `Bruker ${this.i.get('email').value}  ble invitert`,
                    'info'
                  );
                  this.cancel();
                }
              });
          }
        });
    } else if (Object.keys(this.companyData).length === 0) {
      this.notification.send(
        this.language === 'en'
          ? 'Set company data'
          : 'Legg inn data om selskapet før invitasjon',
        'error'
      );
    }
  }
  // GETTERS & SETTERS
  // INVITE FORM
  get i(): FormGroup {
    return this.inviteForm as FormGroup;
  }
  // HIGH PRICE
  get hp(): FormGroup {
    return this.i.get('highPriceBudget') as FormGroup;
  }
  // LOW PRICE
  get lp(): FormGroup {
    return this.i.get('lowPriceBudget') as FormGroup;
  }

  priceQuantityCheck(control, field: string) {
    if (!control.get('quantity').value) {
      this.i.removeControl(field);
      this.i.addControl(field, new FormControl(null));
    } else {
      control.patchValue({ quantity: +control.get('quantity').value });
    }
  }

  // DYNAMIC DIALOG METHODS
  cancel(): void {
    this.ref.close();
  }
  private storageChange() {
    this.languageSubjectService.watchStorage().subscribe((lang: string) => {
      this.language = lang;
    });
  }
}

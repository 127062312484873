<table class="card-table">
  <tr>
    <th>{{'person-card.country' | translate}}</th>
    <td>{{ location.country }}</td>
  </tr>
  <tr>
    <th>{{'person-card.city' | translate}}</th>
    <td>{{ location.city }}</td>
  </tr>
  <tr>
    <th>{{'person-card.street' | translate}}</th>
    <td>{{ location.street }}</td>
  </tr>
  <tr>
    <th>{{'person-card.zip' | translate}}</th>
    <td>{{ location.zip }}</td>
  </tr>
</table>

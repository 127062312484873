import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificationService {
  constructor(private toaster: ToastrService) {}

  public send(message: string, type: string, options: any = {}): void {
    this.toaster[type](message, '', options);
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ec-web-card',
  templateUrl: './web-card.component.html',
  styleUrls: ['./web-card.component.scss'],
})
export class WebCardComponent implements OnInit {
  @Input() web: any = {};

  constructor() {
  }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  constructor() {
  }

  formatting(date: Date = new Date(), format: string = 'MM-DD-YYYY', keepLocalTime: boolean = true): string {
    return moment(date).utc(keepLocalTime).format(format);
  }

  getFormattedTime(time: string | number) {
    if (typeof time === 'string') {
      if (time.split(':').length >= 3) {
        return this.timeFromString(time);
      } else {
        return this.timeFromNumber(+time);
      }
    }
    return this.timeFromNumber(+time);
  }

  timeFromString(time: string): string {
    // '00:00:00:00'
    // 'dd:hh:mm:ss'
    const timeArray: string[] = time.split(':');
    let result: number = 0;
    timeArray.forEach((t, i) => {
      const factor = i === 0 ? 86400 : i === 1 ? 3600 : i === 2 ? 60 : 1;
      result = result + +t * factor;
    });
    const output = moment.duration({minutes: result / 60});
    return `${this.days(output.days())} ${this.hours(output.hours())} ${this.minutes(output.minutes())} ${this.seconds(output.seconds())} `;
  }

  days(input: number): string {
    return input ? `${input} d` : '';
  }

  hours(input: number): string {
    return input ? `${input} h` : '';
  }

  minutes(input: number): string {
    return input ? `${input} m` : '';
  }

  seconds(input: number): string {
    return input ? `${input} s` : '';
  }

  formattedTimeInSeconds(time: string): number {
    if (time.split(':').length >= 3) {
      // '00:00:00:00'
      // 'dd:hh:mm:ss'
      const timeArray: string[] = time.split(':');
      let result: number = 0;
      timeArray.forEach((t, i) => {
        const factor = i === 0 ? 86400 : i === 1 ? 3600 : i === 2 ? 60 : 1;
        result = result + (+t * factor) / 60;
      });
      return result;
    }
  }

  timeFromNumber(time: number): string {
    // '00:00:00:00'
    // 'dd:hh:mm:ss'
    return this.sumDurationTimes(time * 1000);
  }

  sumDurationTimes(...durations) {
    const totalDurations = durations
      .slice(1)
      .reduce(
        (prev, cur) => moment.duration(cur).add(prev),
        moment.duration(durations[0])
      );
    const days = Math.floor(
      moment.duration(totalDurations.asMilliseconds(), 'milliseconds').asDays()
    );
    return `${days < 10 ? '0' + days : days}:${moment
      .utc(totalDurations.asMilliseconds())
      .format('HH:mm:ss')}`;
  }

  // public sumDurationTimes(
  //   ...durations
  // ) {
  //   const totalDurations = durations.slice(1)
  //     .reduce((prev, cur) => moment.duration(cur).add(prev), moment.duration(durations[0]));
  //   const days = Math.floor(moment.duration(totalDurations.asMilliseconds(), 'milliseconds').asDays());
  //   return `${days < 10 ? '0' + days : days}:${moment.utc(totalDurations.asMilliseconds()).format('HH:mm:ss')}`;
  // }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@auth/services/auth.service';

import { PrimeNGLocalDataService } from '@shared/configurations/primeng/local-data.service';
import { CookiesService } from '@shared/services/cookie.service';
import { NotificationService } from '@shared/services/notification.service';
import { ProfileService } from '../../../../system/services/profile.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AsyncValidator } from '@shared/validators/async-vat.validator';
import { RoleService, ROLE_IDS } from '@shared/services/role.service';
import { IRole } from '@shared/interfaces/IResponse';
import { LanguageSubjectService } from 'app/service/language.subject.service';

@Component({
  selector: 'ec-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  profileForm: FormGroup;
  profile;
  sending: boolean = false;
  companyStatusType: boolean = false;
  isOperator: boolean;
  public mask = [
    '+',
    /\d/,
    /\d/,
    ' ',
    /[0-9]/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ];
  numberUnmask = /\D/g;
  private language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';

  constructor(
    private formBuilder: FormBuilder,
    public primeNGLocalDataService: PrimeNGLocalDataService,
    private profileService: ProfileService,
    private authService: AuthService,
    private cookie: CookiesService,
    private notification: NotificationService,
    private roleService: RoleService,
    private languageSubjectService: LanguageSubjectService
  ) {}

  // Form
  get formData() {
    return this.profileForm;
  }

  // Contacts
  get formContacts() {
    return this.formData.get('contacts') as FormArray;
  }

  // Locations
  get formLocations() {
    return this.formData.get('locations') as FormArray;
  }

  ngOnInit(): void {
    this.formInit();
    this.primeNGLocalDataService.changeLocale();
    this.storageChange();
  }

  submitForm() {
    const profile = this.profileForm.value;
    if (this.profileForm.valid) {
      this.sending = true;
      this.profileService.updateUser(profile).subscribe((res) => {
        this.sending = false;
        if (!(res instanceof HttpErrorResponse)) {
          this.disableForm();
          return this.response(res);
        }
      });
    }
  }

  // Form Builders

  // Enable or Disable Reactive Form
  enableForm(): void {
    this.profileForm.enable();
  }

  disableForm(): void {
    this.profileForm.disable();
  }

  // Getters

  // Validators
  public hasError(control, field): boolean {
    return control.get(field).hasError('required');
  }

  public isTouched(control, field): boolean {
    return control.get(field).touched;
  }

  private formInit(): void {
    const profile = JSON.parse(this.cookie.get('User'));
    this.profile = profile;
    this.profileForm = this.formBuilder.group({
      id: [profile.id],
      email: [profile.email],
      logo: [profile.logo],
      firstName: [profile.firstName, [Validators.required]],
      lastName: [profile.lastName, [Validators.required]],
      birthday: [new Date(profile.birthday)],
      companyId: [profile.companyId],
      contacts: this.formBuilder.array([]),
      locations: this.formBuilder.array([]),
      // vat: [profile.vat, [], AsyncValidator.vatValidator(this.authService)],
    });
    this.contactsFormBuilder(profile.contacts, profile.id);
    this.locationsFormBuilder(profile.locations, profile.id);
    // this.companyType(profile.company.companyTypeId);
    // this.vatNumberChange();
    this.profileForm.disable();
  }

  // Contacts
  private contactsFormBuilder(contacts, id) {
    const control = this.profileForm.controls.contacts as FormArray;
    if (contacts.length) {
      return contacts.forEach((contact) => {
        control.push(
          this.formBuilder.group({
            id: [contact.id],
            userId: [contact.userId],
            phone: [
              contact.phone,
              [Validators.required, Validators.minLength(8)],
            ],
            email: [contact.email],
          })
        );
      });
    }
    return this.emptyContacts(id);
  }
  private emptyContacts(id) {
    const control = this.profileForm.controls.contacts as FormArray;
    control.push(
      this.formBuilder.group({
        userId: [id],
        phone: [null, [Validators.required, Validators.minLength(8)]],
        email: [null],
      })
    );
  }

  // Locations
  private locationsFormBuilder(locations, id: string) {
    const control = this.profileForm.controls.locations as FormArray;
    if (locations.length) {
      return locations.forEach((location) => {
        control.push(
          this.formBuilder.group({
            id: [location.id],
            userId: [id],
            country: [location.country, [Validators.required]],
            city: [location.city, [Validators.required]],
            street: [location.street, [Validators.required]],
            zip: [location.zip, [Validators.required]],
          })
        );
      });
    }
    return this.emptyLocations(id);
  }
  private emptyLocations(id) {
    const control = this.profileForm.controls.locations as FormArray;
    control.push(
      this.formBuilder.group({
        userId: [id],
        country: [null, [Validators.required]],
        city: [null, [Validators.required]],
        street: [null, [Validators.required]],
        zip: [null, [Validators.required]],
      })
    );
  }

  private response(response) {
    this.cookie.set('User', JSON.stringify(response.data));
    this.notification.send(
      this.language === 'en'
        ? 'User profile updated successfully!'
        : 'Brukerprofilen ble oppdatert',
      'info'
    );
    return this.disableForm();
  }
  companyType(type: number) {
    if (type === 2 && !this.isOperator) {
      this.formData.get('vat').setValidators(Validators.required);
      this.companyStatusType = true;
    }
  }
  private vatNumberChange() {
    this.formData
      .get('vat')
      .valueChanges.pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((value) => {
        if (this.profile.vat === value) {
          this.formData.get('vat').clearAsyncValidators();
          this.formData.get('vat').updateValueAndValidity();
        } else {
          // const asyncValidator = AsyncValidator.vatValidator(this.authService);
          // this.formData.get('vat').setAsyncValidators(asyncValidator);
          this.formData.get('vat').updateValueAndValidity();
        }
      });
  }
  private userRoleCheck() {
    const userRoles = this.roleService.roles;
    if ((userRoles || []).length) {
      if (
        userRoles.some((r: IRole) => r.id === ROLE_IDS.OPERATOR) &&
        userRoles.length < 2
      ) {
        this.isOperator = true;
      }
    }
  }
  private storageChange() {
    this.languageSubjectService.watchStorage().subscribe((lang: string) => {
      this.language = lang;
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IGuide, IGuideEquipment } from '@shared/models/upload/upload.models';

@Component({
  selector: 'ec-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  @Input() selected: IGuide;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.selected.equipments = this.selected.equipments.sort(
      (a, b) => a.id - b.id
    );
  }
  navigateTo(equipment?: IGuideEquipment) {
    if (equipment) {
      console.log('navigateTo(equipment)', equipment, equipment.externalLink);
    } else {
      if (
        this.selected.passingProcess &&
        this.selected.passingProcess.statusId !== 5
      ) {
        localStorage.setItem(
          'start_page',
          `${this.selected.passingProcess.page}`
        );
      } else {
        localStorage.setItem('start_page', `1`);
      }
      this.router.navigate(['/', 'system', 'guide', this.selected.id]);
    }
  }
}

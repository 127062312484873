import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ec-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() icon: string = 'course';
  @Input() size: number = 24;

  constructor() {
  }

  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { IQuestion } from '@shared/services/question.service';

@Component({
  selector: 'ec-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  @Input() question: IQuestion;

  constructor() {
  }

  ngOnInit(): void {
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormState } from '@shared/services/create-company.service';

@Component({
  selector: 'ec-form-wrapper',
  templateUrl: './form-wrapper.component.html',
  styleUrls: ['./form-wrapper.component.scss'],
})
export class FormWrapperComponent implements OnInit {
  @Input() title: string = '';
  @Input() state: FormState;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClick: EventEmitter<FormState> = new EventEmitter<FormState>();

  constructor() {
  }

  ngOnInit() {
  }
}

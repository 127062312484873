import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ec-simple-pagination',
  templateUrl: './simple-pagination.component.html',
  styleUrls: ['./simple-pagination.component.scss'],
})
export class SimplePaginationComponent implements OnInit {
  @Input() page: number;
  @Input() pages: number;
  @Input() enableFirst: boolean = true;
  @Input() enableLast: boolean = true;
  @Output() first: EventEmitter<number> = new EventEmitter<number>();
  @Output() previous: EventEmitter<number> = new EventEmitter<number>();
  @Output() next: EventEmitter<number> = new EventEmitter<number>();
  @Output() last: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onFirst() {
    if (this.enableFirst) {
      this.first.emit();
    }
  }

  onPrevious() {
    this.previous.emit();
  }

  onNext() {
    if (this.pages > this.page) {
      this.next.emit();
    }
  }

  onLast() {
    if (this.enableLast) {
      if (this.pages > this.page) {
        this.last.emit();
      }
    }
  }
}

<div class="grid-title-container">
  <h4 class="grid-title {{className}}">
    {{'grid.title.' + title | translate}}
  </h4>
  <a *ngIf="console" [routerLink]="'/system/guide-create'">
    {{'action-button.create' | translate}}</a
  >
  <a *ngFor="let modal of modals" class="link" (click)="onOpenModal()"
    >{{modal.name | translate}}</a
  >
  <div class="system-search">
    <input
      [placeholder]="placeholder || 'search.nothing' | translate"
      [(ngModel)]="filter.search"
      [readonly]="!placeholder"
      id="system-searching"
      type="text"
    />
  </div>
</div>
<ec-preloader *ngIf="!rowData; else userList"></ec-preloader>
<ng-template #userList>
  <ag-grid-angular
    #gridList
    (gridReady)="onGridReady($event)"
    (rowSelected)="onRowSelected($event)"
    (rowDoubleClicked)="onDoubleRowClicked($event)"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [gridOptions]="gridOptions"
    [paginationPageSize]="paginationSize"
    [pagination]="true"
    [rowData]="rowData"
    [rowSelection]="rowSelection"
    [rowClass]="rowClass"
    class="ag-theme-material"
    [ngStyle]="{width: '100%', height: height ? height + 'px' : 'calc(100vh - ' + 305 + 'px'}"
  >
  </ag-grid-angular>
</ng-template>

<ng-content></ng-content>

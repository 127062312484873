import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CookiesService {
  constructor(private cookieService: CookieService) {}

  public set(key: string, value: any, expired?: number): void {
    if (expired) {
      localStorage.setItem(key, value);
      this.cookieService.set(key, value, expired);
    } else {
      localStorage.setItem(key, value);
      this.cookieService.set(key, value);
    }
  }

  public get(key: string): any {
    if (this.checkExists(key)) {
      return localStorage.getItem(key);
      // return this.cookieService.get(key);
    } else {
      return false;
    }
  }

  public delete(key: string): boolean {
    if (this.checkExists(key)) {
      return Boolean(this.cookieService.delete(key));
    } else {
      return false;
    }
  }

  public deleteAll() {
    const language = localStorage.getItem('Language');
    localStorage.clear();
    localStorage.setItem('Language', language);
    return this.cookieService.deleteAll();
  }

  private checkExists(key: string): boolean {
    return !!localStorage.getItem(key);
    // return this.cookieService.check(key);
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'ec-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
})
export class PreloaderComponent {
  loading: boolean;

  constructor() {
  }
}

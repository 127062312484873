import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';

import { PrimeNGLocalDataService } from '@shared/configurations/primeng/local-data.service';
import { NotificationService } from '@shared/services/notification.service';
import { ProfileService } from 'app/system/services/profile.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiMainService } from '@shared/services/api.main.service';
import { RoleService } from '@shared/services/role.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AsyncValidator } from '@shared/validators/async-vat.validator';
import { AuthService } from '@auth/services/auth.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'ec-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  profileForm: FormGroup;
  profile;
  sending: boolean = false;
  priceList;
  loaded: boolean = false;
  companyStatusType: boolean = false;
  public mask = [
    '+',
    /\d/,
    /\d/,
    ' ',
    /[0-9]/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ];
  numberUnmask = /\D/g;

  highPriceQuantity: number;
  private language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    public primeNGLocalDataService: PrimeNGLocalDataService,
    private profileService: ProfileService,
    private notification: NotificationService,
    private apiService: ApiMainService,
    public roleService: RoleService,
    private authService: AuthService
  ) {}

  // Form
  get formData() {
    return this.profileForm;
  }

  // Contacts
  get formContacts() {
    return this.formData.get('contacts') as FormArray;
  }

  // Form Builders

  // Locations
  get formLocations() {
    return this.formData.get('locations') as FormArray;
  }

  ngOnInit(): void {
    this.primeNGLocalDataService.changeLocale();
    this.formInit();
  }

  submitForm() {
    this.budgetCheck();
    const profile = this.profileForm.value;
    if (this.profileForm.valid) {
      this.sending = true;
      this.profileService.updateUser(profile).subscribe((res) => {
        this.sending = false;
        if (!(res instanceof HttpErrorResponse)) {
          return this.response();
        }
      });
    }
  }

  cancel(): void {
    this.ref.close();
  }

  private budgetCheck() {
    this.operatorBudget('lowPriceBudget');
    this.regularPriceOperatorBudget('highPriceBudget');
  }

  private operatorBudget(price: string): void {
    if (!this.profileForm.get(price).get('userQuantity').value) {
      this.profileForm.removeControl(price);
      this.profileForm.addControl(price, new FormControl(null));
    } else {
      this.profileForm
        .get(price)
        .get('quantity')
        .patchValue(
          this.profileForm.get(price).get('userQuantity').value
            ? this.profileForm.get(price).get('userQuantity').value
            : 0
        );
      this.profileForm.get(price).get('userQuantity').disable();
      this.profileForm.get(price).get('quantity').updateValueAndValidity();
    }
  }

  private regularPriceOperatorBudget(price: string) {
    if (
      !this.profile.company.highPriceBudget.unlimited &&
      !this.profileForm.get(price).get('userQuantity').value
    ) {
      this.profileForm.removeControl(price);
      this.profileForm.addControl(price, new FormControl(null));
    } else {
      this.profileForm
        .get(price)
        .get('quantity')
        .patchValue(
          this.profileForm.get(price).get('userQuantity').value
            ? this.profileForm.get(price).get('userQuantity').value
            : 0
        );
      this.profileForm.get(price).get('userQuantity').disable();
      this.profileForm.get(price).get('quantity').updateValueAndValidity();
    }
  }
  // Getters

  // Validators
  public hasError(control, field): boolean {
    return control.get(field).hasError('required');
  }

  public isTouched(control, field): boolean {
    return control.get(field).touched;
  }

  private formInit(): void {
    const priceAPI = this.apiService.getPriceList();
    const userProfileApi = this.apiService.getUser(this.config.data.id);

    forkJoin([priceAPI, userProfileApi]).subscribe((response) => {
      this.priceList = response[0];
      const profile = response[1].data;
      this.profile = profile;
      console.log('EditProfileComponent -> formInit -> profile', profile);
      this.profileForm = this.formBuilder.group({
        id: [profile.id],
        email: [profile.email],
        logo: [profile.logo],
        firstName: [
          profile.firstName,
          [Validators.required, Validators.minLength(3)],
        ],
        lastName: [
          profile.lastName,
          [Validators.required, Validators.minLength(3)],
        ],
        birthday: [new Date(profile.birthday)],
        companyId: [profile.companyId],
        contacts: this.formBuilder.array([]),
        locations: this.formBuilder.array([]),
        highPriceBudget: this.formBuilder.group({
          id: [
            profile.highPriceBudget && profile.highPriceBudget.id
              ? profile.highPriceBudget.id
              : null,
          ],
          priceId: [
            profile.highPriceBudget && profile.highPriceBudget.priceId
              ? profile.highPriceBudget.priceId
              : null,
          ],
          quantity: [
            profile.highPriceBudget && profile.highPriceBudget.quantity
              ? profile.highPriceBudget.quantity
              : null,
          ],
          userQuantity: [null],
        }),
        lowPriceBudget: this.formBuilder.group({
          id: [
            profile.lowPriceBudget && profile.lowPriceBudget.id
              ? profile.lowPriceBudget.id
              : null,
          ],
          priceId: [
            profile.lowPriceBudget && profile.lowPriceBudget.priceId
              ? profile.lowPriceBudget.priceId
              : null,
          ],
          quantity: [
            profile.lowPriceBudget && profile.lowPriceBudget.quantity
              ? profile.lowPriceBudget.quantity
              : null,
          ],
          userQuantity: [null],
        }),
        // vat: [profile.vat, [], AsyncValidator.vatValidator(this.authService)],
      });
      this.contactsFormBuilder(profile.contacts, profile.id);
      this.locationsFormBuilder(profile.locations, profile.id);
      // this.companyType(profile.company.companyTypeId);
      // this.vatNumberChange();
      this.companyBudget(profile.company);
      this.loaded = true;
    });
  }

  // Contacts
  private contactsFormBuilder(contacts, id) {
    const control = this.profileForm.controls.contacts as FormArray;
    if (contacts.length) {
      return contacts.forEach((contact) => {
        control.push(
          this.formBuilder.group({
            id: [contact.id],
            userId: [contact.userId],
            phone: [
              contact.phone,
              [Validators.required, Validators.minLength(8)],
            ],
            email: [contact.email],
          })
        );
      });
    }
    return this.emptyContacts(id);
  }
  private emptyContacts(id) {
    const control = this.profileForm.controls.contacts as FormArray;
    control.push(
      this.formBuilder.group({
        userId: [id],
        phone: [null, [Validators.required, Validators.minLength(8)]],
        email: [null],
      })
    );
  }
  // Locations
  private locationsFormBuilder(locations, id: string) {
    const control = this.profileForm.controls.locations as FormArray;
    if (locations.length) {
      return locations.forEach((location) => {
        control.push(
          this.formBuilder.group({
            id: [location.id],
            userId: [id],
            country: [location.country, Validators.required],
            city: [location.city, Validators.required],
            street: [location.street, Validators.required],
            zip: [location.zip, Validators.required],
          })
        );
      });
    }
    return this.emptyLocations(id);
  }
  private emptyLocations(id) {
    const control = this.profileForm.controls.locations as FormArray;
    control.push(
      this.formBuilder.group({
        userId: [id],
        country: [null, Validators.required],
        city: [null, Validators.required],
        street: [null, Validators.required],
        zip: [null, Validators.required],
      })
    );
  }

  private response() {
    console.log(this.language);
    this.notification.send(
      this.language === 'en'
        ? 'User profile updated successfully!'
        : 'Brukerprofilen ble oppdatert',
      'info'
    );
    this.ref.close({ status: 200 });
  }
  companyType(type: number) {
    if (type === 2) {
      this.formData.get('vat').setValidators(Validators.required);
      this.companyStatusType = true;
    }
  }
  private vatNumberChange() {
    this.formData
      .get('vat')
      .valueChanges.pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((value) => {
        if (this.profile.vat === value) {
          this.formData.get('vat').clearAsyncValidators();
          this.formData.get('vat').updateValueAndValidity();
        } else {
          // const asyncValidator = AsyncValidator.vatValidator(this.authService);
          // this.formData.get('vat').setAsyncValidators(asyncValidator);
          this.formData.get('vat').updateValueAndValidity();
        }
      });
  }

  private companyBudget(company): void {
    this.highPriceQuantity = company.highPriceBudget.quantity;
    if (this.profile.company.highPriceBudget.unlimited) {
      this.formData.get('highPriceBudget').get('userQuantity').disable();
      this.formData.get('lowPriceBudget').get('userQuantity').disable();
    }
  }
}

<div class="ec-system-container">
  <div class="ec-system-form">
    <form [formGroup]="inviteForm" *ngIf="loaded">
      <div class="ec-system-form-group">
        <input
          formControlName="email"
          type="text"
          placeholder="{{'company.placeholder.contact-person-email' | translate}}"
        />
        <span
          class="form-error"
          *ngIf="i.get('email').invalid && i.get('email').touched "
        >
          <span *ngIf="i.get('email').errors['required']"
            >{{'company.error.contact-person-email-required' | translate}}</span
          >
          <span *ngIf="i.get('email').errors['pattern']"
            >{{'company.error.contact-person-email-unique' | translate}}</span
          >
          <span *ngIf="i.get('email').errors['emailIsNotAvailable']">
            {{'invite.error.email-exist' | translate}}</span
          >
        </span>
      </div>

      <div class="ec-system-form-group multiple-buttons">
        <button type="button" (click)="cancel()">
          {{'action-button.cancel' | translate}}
        </button>
        <button
          type="button"
          [disabled]="inviteForm.invalid || sending"
          (click)="submit()"
        >
          <span *ngIf="!sending">{{'action-button.save' | translate}}</span>
          <ec-preloader *ngIf="sending"></ec-preloader>
        </button>
      </div>
    </form>
  </div>
</div>

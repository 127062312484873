import {BehaviorSubject, Observable} from 'rxjs';
import {DEFAULT_SEARCH_SET} from '@shared/constans/default';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchingSubjectService {
  private filter: Observable<any>;
  public filterSbj$: BehaviorSubject<any> = new BehaviorSubject(DEFAULT_SEARCH_SET);
  constructor() {}

  public setSearchFilter(
    filter
  ) {
    this.filter = filter;
    console.log('TEST', filter, this.filter);
    this.filterSbj$.next(this.filter);
  }
}

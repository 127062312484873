import { MenuItem } from 'primeng/api/menuitem';

import { Component, OnInit } from '@angular/core';
import { LanguageSubjectService } from 'app/service/language.subject.service';

@Component({
  selector: 'ec-create-company-modal',
  templateUrl: './create-company-modal.component.html',
  styleUrls: ['./create-company-modal.component.scss'],
})
export class CreateCompanyModalComponent implements OnInit {
  tabs = [];
  activeItem: MenuItem;
  createCompanyComponent: boolean = true;
  inviteOperatorComponent: boolean = false;
  private language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';

  constructor(private languageSubjectService: LanguageSubjectService) {}

  ngOnInit(): void {
    this.initTabs();
    this.storageChange();
  }

  initTabs() {
    this.tabs = [
      {
        label: this.language === 'en' ? 'Add new company' : 'Legg til selskap',
        command: () => this.loadComponent(),
      },
      {
        label:
          this.language === 'en'
            ? 'Send link to contact person'
            : 'Send link til kontaktperson',
        command: () => this.loadComponent(),
      },
    ];
  }
  private storageChange() {
    this.languageSubjectService.watchStorage().subscribe((lang: string) => {
      this.language = lang;
    });
  }

  private loadComponent() {
    this.createCompanyComponent = !this.createCompanyComponent;
    this.inviteOperatorComponent = !this.inviteOperatorComponent;
  }
}

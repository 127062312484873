import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { RightSidebarComponent } from '@sys-comp/right-sidebar/right-sidebar.component';
import { ColDef, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { Subscription, fromEvent } from 'rxjs';
import { pluck, debounceTime } from 'rxjs/operators';
import {
  DEFAULT_SEARCH_SET,
  SEARCH_PLACEHOLDER,
} from '@shared/constans/default';
import { SearchingSubjectService } from 'app/service/searching.subject.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'ec-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
})
export class DataGridComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() height: number;

  @Input() rowSelection = 'single';
  @Input() className = '';
  @Input() rowClass = '';
  @Input() rowData: any = [];
  @Input() gridOptions: GridOptions = {
    suppressCellSelection: true,
    suppressHorizontalScroll: true,
    suppressRowTransform: true,
  };
  @Input() modals = [];
  @Input() paginationSize: number = 11;
  @ViewChild('sidebar') sidebar: RightSidebarComponent;
  @ViewChild('gridList') gridList;
  @Input() defaultColDef: ColDef = {
    sortable: true,
    filter: false,
    resizable: false,
    unSortIcon: false,
    sortingOrder: ['asc', 'desc'],
    suppressMovable: true,
  };
  @Input() columnDefs: ColDef[];
  @Input() console: boolean;
  @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDoubleRowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onModalOpen: EventEmitter<any> = new EventEmitter<any>();
  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  @Input() scrWidth: number;
  subscription: Subscription;
  placeholder: string = '';
  filter: any = DEFAULT_SEARCH_SET;

  constructor(
    private searchingService: SearchingSubjectService,
    private router: Router
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.subscribe();
    }, 0);
    this.initSearch();
  }
  ngOnDestroy(): void {
    this.unsubscribe();
  }

  sizeToFit() {
    this.gridApi.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.sizeToFit();
  }

  close() {
    this.gridApi.deselectAll();
  }

  onRowSelected(event) {
    if (event.node.selected) {
      this.onRowClick.emit(event.data);
    }
  }

  onDoubleRowClicked(event) {
    if (event.node.selected) {
      this.onDoubleRowClick.emit(event.data);
    }
  }

  onOpenModal() {
    this.onModalOpen.emit();
  }
  subscribe() {
    this.subscription = fromEvent(
      document.getElementById('system-searching'),
      'keyup'
    )
      .pipe(pluck('target', 'value'), debounceTime(500))
      .subscribe((value: string) => {
        this.searchingService.setSearchFilter(
          Object.assign(DEFAULT_SEARCH_SET, { search: value })
        );
      });
  }
  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  private initSearch() {
    this.placeholder = SEARCH_PLACEHOLDER[this.router.url];
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.filter.search = '';
        this.placeholder = SEARCH_PLACEHOLDER[this.router.url];
      }
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TimeService } from '@shared/services/time.service';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  constructor(private time: TimeService) {
  }

  transform(value: string | number): unknown {
    return this.time.getFormattedTime(value);
  }

}

<div class="user-list-titles">
  <h4 class="grid-title">{{'grid.title.' + title | translate}}</h4>
  <div class="system-search">
    <input
      [placeholder]="placeholder || 'search.nothing' | translate"
      [(ngModel)]="searchFilter.search"
      [readonly]="!placeholder"
      id="system-searching"
      type="text"
    />
  </div>
</div>
<div class="load-container load-appearance" *ngIf="!loaded; else userList">
  <div class="content">
    <ec-preloader></ec-preloader>
  </div>
</div>
<ng-template #userList>
  <ag-grid-angular
    #listRef
    (dblclick)="viewCompany($event)"
    (gridReady)="onGridReady($event)"
    (rowClicked)="openEditModal($event)"
    (rowSelected)="onRowSelected($event)"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [gridOptions]="gridOptions"
    [paginationPageSize]="10"
    [pagination]="true"
    [rowData]="rowData"
    [rowSelection]="rowSelection"
    class="ag-theme-material"
    style="width: 100%; height: calc(100vh - 300px);"
  >
  </ag-grid-angular>
</ng-template>

import { Injectable } from '@angular/core';
import { IRole, IUser } from '@shared/interfaces/IResponse';
import { BehaviorSubject } from 'rxjs';

export enum ROLE {
  MASTER = 'master',
  ADMIN = 'admin',
  OPERATOR = 'operator'
}

export enum ROLE_IDS {
  MASTER = 1,
  ADMIN_COMPANY,
  ADMIN_OPERATOR,
  OPERATOR
}

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  public currentRole$: BehaviorSubject<ROLE> = new BehaviorSubject<ROLE>(ROLE.OPERATOR);
  private currentUser: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(
    JSON.parse(localStorage.getItem('User')) as IUser,
  );
  private currentRole: ROLE = ROLE.ADMIN;

  constructor() {
  }

  get user(): IUser {
    return this.currentUser.getValue();
  }

  set user(user: IUser) {
    this.currentUser.next(user);
  }

  get role(): ROLE {
    return this.currentRole;
  }

  set role(role: ROLE) {
    this.currentRole = role;
    this.setRole$(role);
  }

  get roles(): IRole[] {
    return this.user.roles || [];
  }

  set roles(roles) {
    this.user.roles = roles;
  }

  getUser$() {
    return this.currentUser;
  }

  public isRoleExist(role: number) {
    return (this.user.roles.map(r => +r.id).includes(+role));
  }

  isMaster(): boolean {
    return this.role === ROLE.MASTER;
  }

  isAdmin(): boolean {
    return this.role === ROLE.ADMIN;
  }

  isOperator(): boolean {
    return this.user.roles.some(r => r.id === ROLE_IDS.OPERATOR);
  }

  masterRole(): boolean {
    return this.user.roles.some(r => r.id === ROLE_IDS.MASTER);
  }

  adminRole(): boolean {
    return this.user.roles.some(r => r.id === ROLE_IDS.ADMIN_COMPANY);
  }

  signatureAttached(): boolean {
    return Boolean(this.user.signature);
  }

  setRole$(role: ROLE) {
    this.currentRole$.next(role);
  }
}

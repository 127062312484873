import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular/main';

@Component({
  selector: 'ec-date-time-cell',
  templateUrl: './date-time.component.html',
})
export class DateTimeComponent implements ICellRendererAngularComp {
  date: Date | string;
  format: string = '';
  private params: any;

  agInit(params: any): void {
    this.params = params;
    if (this.params && this.params.value) {
      this.date = this.params.value;
      if (this.params.colDef.cellRendererParams) {
        this.format = this.params.colDef.cellRendererParams.format;
      }
    }
  }

  refresh(): boolean {
    return false;
  }
}

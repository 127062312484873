import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[ecSigning]',
})
export class SigningDirective {
  constructor() {}
  @Output() ecSigning: EventEmitter<any> = new EventEmitter();

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onSigning() {
    this.ecSigning.emit(true);
  }
}

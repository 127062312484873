import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { AuthService } from '@auth/services/auth.service';
import { AsyncValidator } from '@shared/validators/async-vat.validator';
import { NotificationService } from '@shared/services/notification.service';
import { ApiMainService } from '@shared/services/api.main.service';
import { CreateCompanyService } from '../../create-company.service';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ec-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss'],
})
export class CreateCompanyComponent implements OnInit {
  public companyForm: FormGroup;
  isFreeBudget: boolean = false;
  priceList;
  loaded: boolean = false;
  formDisabled: boolean = false;
  public mask = [
    '+',
    /\d/,
    /\d/,
    ' ',
    /[0-9]/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ];
  numberUnmask = /\D/g;
  private language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public ref: DynamicDialogRef,
    private notification: NotificationService,
    private apiService: ApiMainService,
    private createCompanyService: CreateCompanyService
  ) {}

  ngOnInit(): void {
    this.getPriceList();
  }

  private initForm(): void {
    this.companyForm = this.formBuilder.group({
      name: [null, [Validators.required, Validators.maxLength(100)]],
      vat: [
        null,
        [Validators.required, Validators.maxLength(100)],
        // AsyncValidator.vatValidator(this.authService),
      ],
      internalId: [null, [Validators.required]],
      companyTypeId: [1, [Validators.required]],
      contacts: this.formBuilder.array([]),
      locations: this.formBuilder.array([]),
      person: this.formBuilder.group({
        name: [null, [Validators.required]],
        phone: [null, [Validators.required, Validators.minLength(8)]],
        email: [
          null,
          [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
          ],
          AsyncValidator.emailValidator(this.authService),
        ],
      }),
      freeBudget: [false],
      highPriceBudget: this.formBuilder.group({
        quantity: [null],
        discount: [null],
      }),
      lowPriceBudget: this.formBuilder.group({
        quantity: [null],
        discount: [null],
      }),
    });
    this.locationsFormBuilder();
    this.contactsFormBuilder();
    this.loaded = true;

    this.lowPriceBudgetChange();
    this.highPriceBudgetChange();
    console.log(this.companyForm.value);
  }
  submit(): void {
    const form = this.controlRemoving(this.c.getRawValue());
    form.highPriceBudget.discount = this.convertToDigits(
      'highPriceBudget',
      'discount'
    );
    form.highPriceBudget.quantity = this.convertToDigits(
      'highPriceBudget',
      'quantity'
    );
    if (!this.fb.value) {
      form.lowPriceBudget.discount = this.convertToDigits(
        'lowPriceBudget',
        'discount'
      );
      form.lowPriceBudget.quantity = this.convertToDigits(
        'lowPriceBudget',
        'quantity'
      );
    } else if (
      this.hp.get('quantity').value === 0 &&
      !this.lp.get('discount').value &&
      !this.lp.get('quantity').value
    ) {
      form.lowPriceBudget = null;
    }
    if (this.c.valid) {
      this.createCompanyService.company(form);
      this.notification.send(
        this.language === 'en'
          ? 'Company has been successfully saved'
          : 'Selskapet har blitt lagret',
        'success'
      );
      this.c.disable();
      this.formDisabled = true;
    }
  }

  // FORM BUILDERS
  // COMPANY LOCATIONS
  private locationsFormBuilder() {
    return this.l.push(
      this.formBuilder.group({
        country: [null, [Validators.required, Validators.maxLength(100)]],
        city: [null, [Validators.required, Validators.maxLength(100)]],
        street: [null, [Validators.required]],
        zip: [null, [Validators.required, Validators.maxLength(30)]],
      })
    );
  }
  // COMPANY CONTACTS
  private contactsFormBuilder() {
    return this.cc.push(
      this.formBuilder.group({
        phone: [null, [Validators.required, Validators.minLength(8)]],
        email: [
          null,
          [
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
          ],
          AsyncValidator.emailValidator(this.authService),
        ],
      })
    );
  }
  // GETTERS & SETTERS
  // COMPANY FORM
  get c(): FormGroup {
    return this.companyForm as FormGroup;
  }
  // COMPANY LOCATIONS
  get l(): FormArray {
    return this.c.get('locations') as FormArray;
  }
  // COMPANY CONTACTS
  get cc(): FormArray {
    return this.c.get('contacts') as FormArray;
  }
  // COMPANY CONTACTS PERSON
  get p(): FormGroup {
    return this.c.get('person') as FormGroup;
  }
  // COMPANY FREE BUDGET
  get fb(): FormControl {
    return this.c.get('freeBudget') as FormControl;
  }
  // HIGH PRICE
  get hp(): FormGroup {
    return this.c.get('highPriceBudget') as FormGroup;
  }
  // LOW PRICE
  get lp(): FormGroup {
    return this.c.get('lowPriceBudget') as FormGroup;
  }
  // VALIDATORS
  public hasError(control, field, error: string): boolean {
    return control.get(field).hasError(error);
  }

  public isTouched(control, field): boolean {
    return control.get(field).touched;
  }

  public toggleBudget(): void {
    this.isFreeBudget = this.fb.value;

    if (!this.fb.value) {
      this.setBudget();
    } else {
      this.freeBudget();
    }
    console.log(this.c.getRawValue());
  }

  private freeBudget() {
    this.hp.get('quantity').clearValidators();
    this.hp.get('quantity').patchValue(0);
    this.hp.get('quantity').disable();
  }

  private setBudget() {
    this.hp.get('quantity').enable();
    this.hp.get('quantity').patchValue(null);
    this.lowPriceBudgetChange();
  }

  private lowPriceBudgetChange() {
    this.lp
      .get('discount')
      .valueChanges.pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(() => {
        if (this.lp.get('discount').value > 0) {
          this.lp.get('quantity').setValidators([Validators.required]);
          this.lp.get('quantity').updateValueAndValidity();
        } else {
          this.lp.get('quantity').setValidators(null);
          this.lp.get('quantity').updateValueAndValidity();
        }
      });
  }
  private highPriceBudgetChange() {
    this.hp
      .get('discount')
      .valueChanges.pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(() => {
        if (!this.isFreeBudget) {
          console.log(this.hp.get('discount').value);
          if (this.hp.get('discount').value > 0) {
            this.hp.get('quantity').setValidators([Validators.required]);
            this.hp.get('quantity').updateValueAndValidity();
          } else {
            this.hp.get('quantity').setValidators(null);
            this.hp.get('quantity').updateValueAndValidity();
          }
        }
      });
  }
  private controlRemoving(control) {
    Object.keys(control).forEach((key) => {
      if (key === 'freeBudget') {
        delete control[key];
      }
    });
    return control;
  }

  private convertToDigits(control, field) {
    return +this.c.get(control).get(field).value;
  }

  private getPriceList() {
    this.apiService.getPriceList().subscribe((priceList) => {
      this.priceList = priceList;
      this.initForm();
    });
  }
  // DYNAMIC DIALOG METHODS
  cancel(): void {
    this.ref.close();
  }
  enableForm() {
    this.c.enable();
    this.formDisabled = false;
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../auth/services/auth.service';

export class AsyncValidator {
  static vatValidator(service: AuthService): any {
    return (control: AbstractControl): ValidationErrors => {
      return timer(1000).pipe(
        switchMap(() => {
          if (!!control.value) {
            return service
              .checkIfVatNumberAvailability({ vat: control.value })
              .pipe(
                map((response: { available: boolean }) => {
                  return response.available
                    ? null
                    : { vatNumberIsNotAvailable: true };
                })
              );
          }
          return [];
        })
      );
    };
  }

  static emailValidator(service: AuthService): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      return timer(1000).pipe(
        switchMap(() => {
          return service
            .checkIfEmailAvailability({ email: control.value })
            .pipe(
              map((response: { available: boolean }) => {
                return response.available
                  ? null
                  : { emailIsNotAvailable: true };
              })
            );
        })
      );
    };
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiMainService } from '@shared/services/api.main.service';

@Component({
  selector: 'ec-view-single-user',
  templateUrl: './view-single-user.component.html',
  styleUrls: ['./view-single-user.component.scss'],
})
export class ViewSingleUserComponent implements OnInit {
  public user: any = {};
  public loaded = false;

  constructor(
    private apiService: ApiMainService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.apiService.getUser(+this.route.snapshot.params.id)
      .subscribe(result => {
        if (!(result instanceof HttpErrorResponse)) {
          this.user = result.data;
        }
        this.loaded = true;
      });
  }

}

import { Component } from '@angular/core';
import { COMPANY_PAYMENT_STATUSES, COMPANY_TYPES } from '@shared/constans/default';

import { ICellRendererAngularComp } from 'ag-grid-angular/main';

@Component({
  selector: 'ec-type-cell',
  templateUrl: './type.component.html',
})
export class TypeComponent implements ICellRendererAngularComp {
  id: number;
  type: string;
  value;
  private params: any;

  agInit(params: any): void {
    this.params = params;
    if (this.params && this.params.value) {
      this.id = this.params.value;
      this.type = this.params.colDef.cellRendererParams;
      if (this.type) {
        this.value = this.getTypeByID(this.type, this.id);
      }
    }
  }

  refresh(): boolean {
    return false;
  }

  getTypeByID(type: string, id: number): string {
    let output: string = '';
    if (type === 'company') {
      output = COMPANY_TYPES[id - 1];
    } else if (type === 'payment') {
      output = COMPANY_PAYMENT_STATUSES[id - 1];
    }
    return output;
  }
}

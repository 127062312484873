import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector:    'ec-modal',
  templateUrl: './modal.component.html',
  styleUrls:   [ './modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() modal = true;
  @Input() header: string;
  @Input() styles = { width: '40vw' };
  @Input() includeFooter = true;
  @Input() showCancel = true;
  @Input() showHeader = true;
  @Input() closable = true;
  @Input() cancelButtonText: any;
  @Input() submitButtonText: any;
  @Input() buttonLayoutClass = 'flex-between';

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onCancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  public visible = false;

  constructor() { }

  public submit() {
    this.onSubmit.emit();
  }

  public open() {
    this.visible = true;
  }

  public close() {
    this.visible = false;
  }

  public cancel() {
    this.close();
    this.onCancel.emit();
  }

  ngOnInit(): void {
  }

}

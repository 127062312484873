import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import apiRoutes from '@shared/constans/apiRoutes';
import { CookiesService } from '@shared/services/cookie.service';
import { HttpService } from '@shared/services/http.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiAuthService {
  constructor(
    private http: HttpService,
    private router: Router,
    private cookie: CookiesService
  ) {}

  public logout(): Observable<any> {
    return this.http
      .get(apiRoutes.auth.logout, {
        headers: { access: this.cookie.get('Authorization') },
      })
      .pipe(
        (response) => {
          console.log('LOGOUT', response);
          this.router.navigate(['/']).then(() => {
            this.clearCookies();
            // location.reload();
          });
          return response;
        },
        catchError((err: Error) => {
          console.log(err);
          this.router.navigate(['/']).then(() => {
            this.clearCookies();
            // location.reload();
          });
          return of(err);
        })
      );
  }

  public isAuth(): Promise<any> {
    return this.http
      .get(apiRoutes.auth.check)
      .toPromise()
      .then((response) => {
        console.log('CHECK AUTH RESPONSE', response);
        return (response || {}).status;
      })
      .catch((err) => {
        return err;
      });
  }

  private clearCookies() {
    this.cookie.deleteAll();
  }
}

import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng';
import { NotificationService } from '@shared/services/notification.service';

@Component({
  selector: 'ec-shared-link',
  templateUrl: './shared-link.component.html',
  styleUrls: ['./shared-link.component.scss'],
})
export class SharedLinkComponent implements OnInit {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {}

  ok(inputElement): void {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.notification.send('Shared link was copied successfully!', 'info');
    this.ref.close();
  }
}

import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { IQuestion, QuestionService } from '@shared/services/question.service';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '@shared/services/notification.service';
import { debounceTime } from 'rxjs/operators';
import { HttpService } from '@shared/services/http.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ec-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit, OnChanges {
  @Input() questions: IQuestion[] = [];
  @Input() saveAnswer: boolean = false;
  @Output() valid: EventEmitter<any> = new EventEmitter();
  @Output() onAction: EventEmitter<any> = new EventEmitter();
  questionsForm: FormGroup;
  loaded: boolean = false;
  sending: boolean = false;
  privacyTermsVar: boolean = false;
  guideId: number;
  public language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';
  state;

  constructor(
    private formBuilder: FormBuilder,
    private questionsService: QuestionService,
    private notification: NotificationService,
    private http: HttpService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.guideId = this.route.snapshot.queryParams.guideId;
    this.initForm();
    this.formChanges();
    this.state = this.route.snapshot.queryParams;
  }
  ngOnChanges(): void {
    if (this.saveAnswer) {
      return this.submit();
    }
  }

  private initForm() {
    this.questionsForm = this.formBuilder.group({
      guideId: [this.route.snapshot.queryParams.guideId],
      questions: this.formBuilder.array([]),
      privacy: [null, [Validators.required]],
    });
    this.questionsFromBuilder(this.questions);
    this.loaded = true;
  }

  get f() {
    return this.questionsForm as FormGroup;
  }

  get q() {
    return this.f.get('questions') as FormArray;
  }

  get a() {
    return this.q.get('answers') as FormArray;
  }

  private questionsFromBuilder(questions) {
    if (questions && questions.length) {
      return questions.forEach((question) => {
        this.q.push(
          this.formBuilder.group({
            text: [question.text],
            page: [question.page || 1],
            answers: this.formBuilder.array([
              this.answersFormBuilder(question.answers[0], question),
              this.answersFormBuilder(question.answers[1], question),
              this.answersFormBuilder(question.answers[2], question),
            ]),
          })
        );
      });
    }
  }
  private answersFormBuilder(answer, question): FormGroup {
    return this.formBuilder.group({
      answerId: [answer.id],
      questionId: [question.id],
      text: [answer.text],
      correctAnswer: [null, [Validators.required]],
    });
  }

  setCorrectAnswer(i, j) {
    const answers: FormArray = this.q.at(i).get('answers') as FormArray;
    answers.controls.forEach((answer) => {
      answer.get('correctAnswer').patchValue(false);
    });
    answers.at(j).get('correctAnswer').patchValue(true);
  }
  submit() {
    const state = {
      guideId: +this.state.guideId,
      page: +this.state.page,
      time: +this.state.time,
      userId: +this.state.userId,
      statusId: 6,
    };
    const form = { guideId: this.f.get('guideId').value, answers: [] };
    this.q.controls.forEach((question) => {
      question.get('answers').value.map((answer) => {
        if (!!answer.correctAnswer) {
          return form.answers.push(answer);
        }
      });
    });
    this.sending = true;
    if (this.questions.length) {
      this.sendAnswers(form, state);
    } else {
      this.http.put('/api/user/guide/process', state).subscribe((res) => {
        if (!(res instanceof HttpErrorResponse)) {
          this.notification.send(res.message, 'info');
          this.onAction.emit();
        }
        this.sending = false;
      });
    }
  }

  private sendAnswers(
    form,
    state
  ) {
    this.questionsService.saveAnswer(form).subscribe((res) => {
      this.http.put('/api/user/guide/process', state).subscribe(() => {});
      this.sending = false;

      if (!(res instanceof HttpErrorResponse)) {
        this.notification.send(res.message, 'info');
        this.onAction.emit();
      }
    });
  }

  privacyTerms() {
    this.privacyTermsVar = this.f.get('privacy').value;
    if (!this.privacyTermsVar) {
      this.f.get('privacy').patchValue(null);
    }
  }
  private formChanges() {
    this.f.valueChanges.pipe(debounceTime(500)).subscribe((res) => {
      this.valid.emit(this.f.valid);
    });
  }
}

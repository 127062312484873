import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DataGridComponent } from '@components/data-grid/data-grid.component';
import {
  ChangePasswordComponent,
  EditCompanyComponent,
  EditProfileComponent,
  InviteOperatorModalComponent,
} from '@components/dialog-components';
import { FormWrapperComponent } from '@components/form/form-wrapper/form-wrapper.component';
import { HeaderComponent } from '@components/header/header.component';
import { IconComponent } from '@components/icon/icon.component';
import { PreloaderComponent } from '@components/preloader/preloader.component';
import { AnswerComponent } from '@components/questions/answer/answer.component';
import { QuestionComponent } from '@components/questions/question/question.component';
import { QuestionsComponent } from '@components/questions/questions.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';
import { SimplePaginationComponent } from '@components/simple-pagination/simple-pagination.component';
import { ActivityComponent } from '@components/tabs/activity/activity.component';
import { DetailsComponent } from '@components/tabs/details/details.component';
import { TabComponent } from '@components/tabs/tab/tab.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { TileComponent } from '@components/tile/tile.component';
import { ViewerComponent } from '@components/viewer/viewer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateModule } from '@ngx-translate/core';
import { PrimeNGLocalDataService } from '@shared/configurations/primeng/local-data.service';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { DecimalDirective } from '@shared/directives/decimal.directive';
import { DigitsOnlyDirective } from '@shared/directives/digits-only.directive';
import { NumbersOnlyDirective } from '@shared/directives/numbers-only.directive';
import { CategoryComponent } from '@shared/grid/category/category.component';
import { CurrencyComponent } from '@shared/grid/currency/currency.component';
import { DateTimeComponent } from '@shared/grid/date-time/date-time.component';
import { IndicatorComponent } from '@shared/grid/discount/indicator.component';
import { FullNameComponent } from '@shared/grid/full-name/full-name.component';
import { StatusComponent } from '@shared/grid/status/status.component';
import { CertificationStatusComponent } from '@shared/grid/certification-status/certification-status.component';
import { IconGridComponent } from '@shared/grid/icon/icon.component';
import { LengthComponent } from '@shared/grid/length/length.component';
import { ControlsComponent } from '@shared/grid/notes/controls.component';
import { TimeComponent } from '@shared/grid/time/time.component';
import { TitleCaseComponent } from '@shared/grid/TitleCase/title-case.component';
import { TypeComponent } from '@shared/grid/type/type.component';
import { AuthInterceptor } from '@shared/interceptors/auth-interceptor';
import { PrimeNGModule } from '@shared/modules/primeng/primeng.module';
import { PageNotFoundComponent } from '@shared/pages/page-not-found/page-not-found.component';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { TimePipe } from '@shared/pipes/time.pipe';
import { ApiAuthService } from '@shared/services/api.auth.service';
import { ApiMainService } from '@shared/services/api.main.service';
import { CookiesService } from '@shared/services/cookie.service';
import { NotificationService } from '@shared/services/notification.service';
import { CompanyListComponent } from '@sys-comp/company-list/company-list.component';
import { CompanyProfileComponent } from '@sys-comp/company-profile/company-profile.component';
import { ContactCardComponent } from '@sys-comp/contact-card/contact-card.component';
import { TestComponent } from '@sys-comp/give-guide/test.component';
import { GuideListComponent } from '@sys-comp/guide-list/guide-list.component';
import { InviteOperatorComponent } from '@sys-comp/invite-operator/invite-operator.component';
import { LanguageChangerComponent } from '@sys-comp/language-changer/language-changer.component';
import { LastAddedListComponent } from '@sys-comp/last-added-list/last-added-list.component';
import { LocationCardComponent } from '@sys-comp/location-card/location-card.component';
import { PersonCardComponent } from '@sys-comp/person-card/person-card.component';
import { UserProfileComponent } from '@sys-comp/profile/profile.component';
import { QualifiedListComponent } from '@sys-comp/qualified-list/qualified-list.component';
import { RightSidebarComponent } from '@sys-comp/right-sidebar/right-sidebar.component';
import { UserListComponent } from '@sys-comp/user-list/user-list.component';
import { ViewSingleCompanyComponent } from '@sys-comp/view-single-company/view-single-company.component';
// tslint:disable-next-line: max-line-length
import { GuideInformationSidebarComponent } from '@sys-comp/view-single-guide/single-guide-details/pages/guide-information-sidebar/guide-information-sidebar.component';
// tslint:disable-next-line: max-line-length
import { GuideInformationComponent } from '@sys-comp/view-single-guide/single-guide-details/pages/guide-information/guide-information.component';
import { SingleGuideDetailsComponent } from '@sys-comp/view-single-guide/single-guide-details/single-guide-details.component';
import { ViewSingleGuideComponent } from '@sys-comp/view-single-guide/view-single-guide.component';
import { ViewSingleUserComponent } from '@sys-comp/view-single-user/view-single-user.component';
import { WebCardComponent } from '@sys-comp/web-card/web-card.component';
import { AgGridModule } from 'ag-grid-angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaskModule } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { RoleTransformerComponent } from '@components/role-transformer/role-transformer.component';
import { CreateCompanyModalComponent } from '@components/dialog-components';
// tslint:disable-next-line: max-line-length
import { CreateCompanyComponent } from '@components/dialog-components/create-company-modal/components/create-company/create-company.component';
import { InviteComponent } from '@components/dialog-components/create-company-modal/components/invite/invite.component';
import { CreateCompanyService } from '@components/dialog-components/create-company-modal/create-company.service';
import { EditComponent } from './grid/edit/edit.component';
import { SystemPersonComponent } from '@sys-comp/system-person/system-person.component';
import { RolesComponent } from './grid/roles/roles.component';
import { CreateOperatorComponent } from '@sys-comp/create-operator/create-operator.component';
// tslint:disable-next-line:max-line-length
import { CompanyStatusComponent } from '@components/dialog-components/invite-operator-modal/components/company-status/company-status.component';
import { SharedLinkComponent } from '@components/dialog-components';
import { NavBarService } from './services/nav-bar.service';
import { DeletingActionComponent } from './grid/deleting-action/deleting-action.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TextMaskModule } from 'angular2-text-mask';
import { UnmaskDirective } from './directives/unmask.directive';
import { SigningDirective } from './directives/signing.directive';
import { ViewSingleGuidePageComponent } from './components/system-components/view-single-guide-page/view-single-guide-page.component';
import { ButtonDeleteComponent } from './grid/button-delete/button-delete.component';

const sharedComponents = [
  PageNotFoundComponent,
  FormWrapperComponent,
  SidebarComponent,
  HeaderComponent,
  UserProfileComponent,
  PreloaderComponent,
  CompanyProfileComponent,
  UserListComponent,
  ChangePasswordComponent,

  ViewSingleUserComponent,
  ViewSingleCompanyComponent,
  ViewSingleGuideComponent,
  TestComponent,

  RightSidebarComponent,
  ViewerComponent,

  IconComponent,
  InviteOperatorComponent,
  InviteOperatorModalComponent,

  ContactCardComponent,
  LocationCardComponent,
  WebCardComponent,
  PersonCardComponent,
  TabsComponent,
  TabComponent,
  DetailsComponent,
  ActivityComponent,

  EditProfileComponent,
  EditCompanyComponent,
  SharedLinkComponent,

  LanguageChangerComponent,
  SimplePaginationComponent,

  SingleGuideDetailsComponent,
  GuideInformationComponent,
  GuideInformationSidebarComponent,
  TileComponent,

  RoleTransformerComponent,

  CreateCompanyModalComponent,
  CreateCompanyComponent,
  InviteComponent,

  QuestionsComponent,
  QuestionComponent,
  AnswerComponent,
  SystemPersonComponent,
  RolesComponent,
  CreateOperatorComponent,
  CompanyStatusComponent,
  ViewSingleGuidePageComponent
];

const sharedGrids = [
  GuideListComponent,
  CompanyListComponent,
  DataGridComponent,
  QualifiedListComponent,
  LastAddedListComponent,
];
const sharedPipes = [
  // PIPES
  TimePipe,
  SafeHtmlPipe,
];
const sharedGridComponents = [
  // Custom grid pages
  TimeComponent,
  CurrencyComponent,
  IndicatorComponent,
  ControlsComponent,
  IconGridComponent,
  DateTimeComponent,
  TypeComponent,
  CategoryComponent,
  LengthComponent,
  TitleCaseComponent,
  FullNameComponent,
  EditComponent,
  DeletingActionComponent,
  StatusComponent,
  CertificationStatusComponent,
];

const sharedDirectives = [
  ClickOutsideDirective,
  DigitsOnlyDirective,
  NumbersOnlyDirective,
  DecimalDirective,
  UnmaskDirective,
  SigningDirective,
];

const sharedModules = [
  CommonModule,
  RouterModule,
  // FORMS
  FormsModule,
  ReactiveFormsModule,
  // HTTP
  HttpClientModule,
  // LIBRARIES
  PrimeNGModule,
  TranslateModule,
  LoadingBarHttpClientModule,
  LoadingBarModule,
  NgxFileDropModule,
  PdfViewerModule,

  MomentModule,

  NgSelectModule,
  NgxPaginationModule,
  TextMaskModule,
];

const sharedServices = [
  // LOCAL DATA
  PrimeNGLocalDataService,
  // API CALLS
  CookiesService,
  ApiAuthService,
  // HELPERS
  NotificationService,
  ApiMainService,
  CreateCompanyService,
  NavBarService,
];
const sharedEntryComponents = [
  ChangePasswordComponent,
  InviteOperatorModalComponent,
  EditProfileComponent,
  EditCompanyComponent,
  CreateCompanyModalComponent,
];

@NgModule({
  declarations: [
    ...sharedComponents,
    ...sharedGrids,
    ...sharedDirectives,
    ...sharedGridComponents,
    ...sharedPipes,
    ButtonDeleteComponent
  ],
  imports: [
    ...sharedModules,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      preventDuplicates: true,
    }),
    AgGridModule.withComponents([...sharedGridComponents]),
    ToastContainerModule,
  ],
  exports: [
    ...sharedModules,
    ...sharedComponents,
    ...sharedDirectives,
    ...sharedPipes,
    ...sharedGrids,

    ...sharedGridComponents,
    NgxMaskModule,
    AgGridModule,
  ],
  providers: [
    ...sharedServices,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  entryComponents: [...sharedEntryComponents],
})
export class SharedModule {}

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '@shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';

import { AppRouting } from './app.routing';
import { CompanySubjectService } from './service/company.subject.service';
import { SearchingSubjectService } from './service/searching.subject.service';
import { LanguageSubjectService } from './service/language.subject.service';
import {ConfirmationDialogComponent} from './features/confirmation-dialog/confirmation-dialog.component';
import {ModalModule} from './features/modal/modal.module';
import {ConfirmationService} from './service/confirmation.service';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationDialogComponent
  ],
    imports: [
        BrowserModule,
        AppRouting,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            defaultLanguage: 'no',
        }),
        BrowserAnimationsModule,
        ModalModule,
    ],
  providers: [
    CookieService,
    SearchingSubjectService,
    CompanySubjectService,
    LanguageSubjectService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    ConfirmationService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

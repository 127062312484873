import { Directive, Input, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[ecUnmask]',
})
export class UnmaskDirective implements OnInit, OnDestroy {
  @Input() ecUnmask: string;
  private subscriber;
  constructor(private elementRef: ElementRef, private model: NgControl) {}
  ngOnInit(): void {
    this.subscriber = this.model.control.valueChanges.subscribe(() => {
      const newValue = this.elementRef.nativeElement.value.replace(
        new RegExp(this.ecUnmask),
        ''
      );

      this.model.control.setValue(newValue, {
        emitEvent: false,
        emitModelToViewChange: false,
        emitViewToModelChange: false,
      });
    });
  }
  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }
}

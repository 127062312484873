import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import apiRoutes from '@shared/constans/apiRoutes';
import { IResponse } from '@shared/interfaces/IResponse';
import { IGuide } from '@shared/models/upload/upload.models';
import { CookiesService } from '@shared/services/cookie.service';
import { HttpService } from '@shared/services/http.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export enum AWS_UPLOAD_INSTANCES {
  GUIDE_RESOURCES = 1,
  GUIDE_MEDIA = 2,
  COMPANY_PROFILE = 3,
  USER_PROFILE = 4,
  EQUIPMENT_PROFILE = 5,
  CERTIFICATE = 6
}

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpService, private cookie: CookiesService) {
  }

  public createGuide(body: IGuide) {
    return this.http
      .post(apiRoutes.guide.create, body, {headers: this.getHeaders()})
      .pipe(
        map((response: IResponse) => {
          return response.data;
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        }),
      );
  }

  public updateGuide(body: IGuide) {
    return this.http
      .put(apiRoutes.guide.update, body, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response: IResponse) => {
          return response.data;
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        }),
      );
  }

  public removeGuide(body) {
    return this.http
      .delete(
        apiRoutes.guide.remove +
        `${body}` +
        `/${AWS_UPLOAD_INSTANCES.GUIDE_RESOURCES}`,
        {
          headers: this.getHeaders(),
        },
      )
      .pipe(
        map((response: IResponse) => {
          return response.status;
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        }),
      );
  }

  public removeEquipmentPhoto(body) {
    return this.http
      .delete(
        apiRoutes.guide.remove +
        `${body}` +
        `/${AWS_UPLOAD_INSTANCES.EQUIPMENT_PROFILE}`,
        {
          headers: this.getHeaders(),
        },
      )
      .pipe(
        map((response: IResponse) => {
          return response.status;
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        }),
      );
  }

  public singleGuide(header: number) {
    return this.http
      .get(apiRoutes.guide.single + `/${header}`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response: IResponse) => {
          return response.data;
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        }),
      );
  }

  public uploadGuidePDF(file, guideId: number) {
    const formData = new FormData();
    formData.append('guide', file, file.name);
    formData.append('guideId', String(guideId));
    return this.http.post(apiRoutes.guide.upload, formData);
  }

  public uploadEquipmentPhoto(file, equipmentId: number) {
    const formData = new FormData();
    formData.append('profile', file, file.name);
    formData.append('equipmentId', String(equipmentId));
    return this.http.post(apiRoutes.equipment.upload, formData);
  }

  public uploadProfileLogo(file: File, id: string, relation: string) {
    const formData = new FormData();
    formData.append('profile', file, file.name);
    formData.append(relation, String(id));
    return this.http.post(apiRoutes.equipment.upload, formData);
  }

  public removeEquipment(id) {
    return this.http
      .delete(apiRoutes.equipment.remove + `${id}`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response: IResponse) => {
          console.log(response);
          return response.data;
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        }),
      );
  }

  public removeQuestion(id) {
    return this.http
      .delete(apiRoutes.question.remove + `${id}`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response: IResponse) => {
          console.log(response);
          return response.data;
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        }),
      );
  }

  getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.cookie.get('Authorization'),
    });
  }
}

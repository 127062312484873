import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DataGridComponent } from '@components/data-grid/data-grid.component';
import { DEFAULT_SEARCH_SET } from '@shared/constans/default';
import { IndicatorComponent } from '@shared/grid/discount/indicator.component';
import { FullNameComponent } from '@shared/grid/full-name/full-name.component';
import { IconGridComponent } from '@shared/grid/icon/icon.component';
import { TimeComponent } from '@shared/grid/time/time.component';
import { IGuide } from '@shared/models/upload/upload.models';
import { ApiMainService } from '@shared/services/api.main.service';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { SearchingSubjectService } from '../../../../service/searching.subject.service';
import { StatusComponent } from '@shared/grid/status/status.component';
import { CertificationStatusComponent } from '@shared/grid/certification-status/certification-status.component';

@Component({
  selector: 'ec-qualified-list',
  templateUrl: './qualified-list.component.html',
  styleUrls: ['./qualified-list.component.scss'],
})
export class QualifiedListComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public loaded = false;
  public filter = DEFAULT_SEARCH_SET;
  @Input() title: string = 'last-qualified';
  public rowData: any = [];
  opened: boolean = false;
  @Input() paginationSize: number = 11;
  @Input() height: number;
  @Input() invoice: boolean = false;
  @Output() selectedRow: EventEmitter<IGuide> = new EventEmitter<IGuide>();
  @ViewChild('grid') grid: DataGridComponent;
  gridOptions: GridOptions = {
    suppressCellSelection: true,
    suppressHorizontalScroll: true,
    suppressRowTransform: true,
  };

  selected: IGuide;
  defaultColDef: ColDef = {
    sortable: true,
    filter: false,
    resizable: false,
    unSortIcon: false,
    sortingOrder: ['asc', 'desc'],
    suppressMovable: true,
  };
  columnDefs: ColDef[] = [
    {
      headerName: 'Course Name',
      field: 'guide.name',
      cellRendererFramework: IconGridComponent,
      cellRendererParams: 'list',
      suppressSizeToFit: true,
    },
    {
      headerName: 'Operator',
      field: 'operator',
      cellRendererFramework: FullNameComponent,
      suppressSizeToFit: true,
      valueGetter: (params) => {
        return `${params.data.operator.firstName} ${params.data.operator.lastName}`;
      },
    },
    {
      headerName: 'Company',
      field: 'operator.company.name',
    },
    {
      headerName: 'Time used',
      field: 'time',
      cellRendererFramework: TimeComponent,
      width: 150,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Status',
      field: 'status.type',
      cellRendererFramework: StatusComponent,
      width: 150,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Certification Status',
      field: 'certificationStatus.type',
      cellRendererFramework: CertificationStatusComponent,
    },
  ];

  constructor(
    private searchingService: SearchingSubjectService,
    private apiMainService: ApiMainService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscription = this.searchingService.filterSbj$.subscribe((filter) => {
      console.log('CompanyListComponent', filter);
      this.filter = filter;
      this.getGuideList().subscribe((response) => {
        console.log(response);
        this.rowData = response;
        this.loaded = true;
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getGuideList() {
    this.filter.certificated = this.invoice;
    return this.apiMainService.getUserGuideList(this.filter);
  }

  onRowSelected(guide: IGuide) {
    this.selectedRow.emit(guide);
    this.opened = true;
    this.selected = guide;
  }

  onDoubleRowSelected(guide: IGuide) {
    this.router.navigate(['/', 'system', 'process', guide.id]);
  }

  close() {
    if (this.opened) {
      this.opened = false;
      this.grid.close();
    }
  }
}

<div class="profile-wrapper">
  <form (ngSubmit)="submitForm()" [formGroup]="profileForm">
    <div class="system-form-title">
      <div class="first-column">
        <span>{{'person-profile.title' | translate}}</span>
      </div>
    </div>
    <div class="system-form-actions form-switcher">
      <button (click)="enableForm()" *ngIf="profileForm.disabled">
        {{'action-button.edit' | translate}}
      </button>
    </div>
    <!-- <div class="system-form-photo">
      <div></div>
    </div> -->
    <div class="system-form-group">
      <div class="system-form-control">
        <input
          class="profile-email"
          formControlName="email"
          readonly
          type="text"
        />
      </div>
      <div class="system-form-control">
        <input
          formControlName="firstName"
          placeholder="{{'person-profile.placeholders.first-name' | translate}}"
          type="text"
        />
        <span
          *ngIf="profileForm.get('firstName').invalid && profileForm.get('firstName').touched"
          class="validation_error__message"
        >
          <span *ngIf="profileForm.get('firstName').errors['required']">
            {{'person-profile.errors.first-name' | translate}}
          </span>
        </span>
      </div>
      <div class="system-form-control">
        <input
          formControlName="lastName"
          placeholder="{{'person-profile.placeholders.second-name' | translate}}"
          type="text"
        />
        <span
          *ngIf="profileForm.get('lastName').invalid && profileForm.get('lastName').touched"
          class="validation_error__message"
        >
          <span *ngIf="profileForm.get('lastName').errors['required']">
            {{'person-profile.errors.second-name' | translate}}
          </span>
        </span>
      </div>
      <div
        *ngFor="let item of formContacts.controls; let i = index"
        formArrayName="contacts"
      >
        <div [formGroupName]="i">
          <div class="system-form-control">
            <input
              formControlName="phone"
              placeholder="{{'person-profile.placeholders.personal-phone' | translate}}"
              type="text"
              [textMask]="{mask: mask}"
              [ecUnmask]="numberUnmask"
            />
            <span
              *ngIf="isTouched(item,'phone')"
              class="validation_error__message"
            >
              <span *ngIf="hasError(item,'phone')">
                {{'person-profile.errors.phone' | translate}}
              </span>
            </span>
          </div>
          <div class="system-form-control">
            <input
              formControlName="email"
              placeholder="{{'person-profile.placeholders.personal-email' | translate}}"
              type="text"
            />
            <span
              *ngIf="isTouched(item,'email')"
              class="validation_error__message"
            >
              <span *ngIf="hasError(item,'email')">
                {{'person-profile.errors.email' | translate}}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div
        *ngFor="let item of formLocations.controls; let i = index"
        formArrayName="locations"
      >
        <div [formGroupName]="i">
          <div class="system-form-control">
            <input
              formControlName="country"
              placeholder="{{'person-profile.placeholders.country' | translate}}"
              type="text"
            />
            <span
              *ngIf="isTouched(item,'country')"
              class="validation_error__message"
            >
              <span *ngIf="hasError(item,'country')">
                {{'person-profile.errors.country' | translate}}
              </span>
            </span>
          </div>
          <div class="system-form-control">
            <input
              formControlName="street"
              placeholder="{{'person-profile.placeholders.street' | translate}}"
              type="text"
            />
            <span
              *ngIf="isTouched(item,'street')"
              class="validation_error__message"
            >
              <span *ngIf="hasError(item,'street')">
                {{'person-profile.errors.street' | translate}}
              </span>
            </span>
          </div>
          <div class="system-form-control">
            <input
              formControlName="city"
              placeholder="{{'person-profile.placeholders.city' | translate}}"
              type="text"
            />
            <span
              *ngIf="isTouched(item,'city')"
              class="validation_error__message"
            >
              <span *ngIf="hasError(item,'city')">
                {{'person-profile.errors.city' | translate}}
              </span>
            </span>
          </div>
          <div class="system-form-control">
            <input
              formControlName="zip"
              placeholder="{{'person-profile.placeholders.zip' | translate}}"
              type="text"
            />
            <span
              *ngIf="isTouched(item,'zip')"
              class="validation_error__message"
            >
              <span *ngIf="hasError(item,'zip')">
                {{'person-profile.errors.zip' | translate}}
              </span>
            </span>
          </div>
        </div>
      </div>

      <!-- <div class="system-form-control" *ngIf="!isOperator">
        <input
          type="text"
          formControlName="vat"
          placeholder="{{'person-profile.placeholders.vat' | translate}} {{companyStatusType ? '*' : ''}}"
        />
        <span
          class="form-error"
          *ngIf="
            profileForm.get('vat').invalid && profileForm.get('vat').touched
          "
        >
          <span *ngIf="profileForm.get('vat').errors['vatNumberIsNotAvailable']"
            >{{'company-profile.errors.vat-exist' | translate }}</span
          >
          <span *ngIf="profileForm.get('vat').errors['required']"
            >{{'person-profile.errors.vat-required' | translate}}</span
          >
        </span>
      </div> -->
      <div class="system-form-control">
        <label>{{'profile.label.birthday' | translate}}</label>
        <p-calendar
          [locale]="primeNGLocalDataService.en"
          [monthNavigator]="true"
          [readonlyInput]="true"
          [yearNavigator]="true"
          class="system-primeng-calendar"
          disabled="profileForm.disabled"
          formControlName="birthday"
          inputStyleClass="system-primeng-input"
          placeholder="Date of birth"
          styleClass="system-primeng"
          yearRange="1950:2003"
        >
        </p-calendar>
      </div>
    </div>
    <div *ngIf="profileForm.enabled" class="system-form-actions">
      <button (click)="disableForm()" type="button">
        {{'action-button.cancel' | translate}}
      </button>
      <button [disabled]="profileForm.invalid || sending" type="submit">
        <span *ngIf="!sending">{{'action-button.save' | translate}}</span>
        <ec-preloader *ngIf="sending"></ec-preloader>
      </button>
    </div>
  </form>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IGuide } from '@shared/models/upload/upload.models';
import { RoleService } from '@shared/services/role.service';

@Component({
  selector: 'ec-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
})
export class RightSidebarComponent {
  @Input() data: IGuide;
  @Input() close: boolean = true;
  @Input() button: string = '';
  @Input() adminButton: boolean = true;
  @Input() icon: string;
  @Output() onClose: EventEmitter<null> = new EventEmitter(null);
  @Output() onAction: EventEmitter<null> = new EventEmitter(null);

  visible: boolean = false;

  tooltip: string;

  constructor(private router: Router, public role: RoleService) {}

  toggle() {
    this.visible = !this.visible;
  }

  show() {
    if (!this.visible) {
      this.visible = true;
    }
    this.tooltipMessage();
  }

  hide() {
    this.visible = false;
    this.onClose.emit(null);
  }

  action() {
    this.onAction.emit();
  }

  editCourse() {
    this.router.navigate([`/system/update-course/${this.data.id}`]);
  }

  tooltipMessage() {
    switch (this.button) {
      case 'details':
        this.tooltip = 'details';
        break;
      case 'take':
        this.tooltip = 'take';
        break;
      case 'certificate':
        this.tooltip = 'certificate';
        break;
    }
  }
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DEFAULT_SEARCH_SET } from '@shared/constans/default';
import { CategoryComponent } from '@shared/grid/category/category.component';
import { DateTimeComponent } from '@shared/grid/date-time/date-time.component';
import { FullNameComponent } from '@shared/grid/full-name/full-name.component';
import { IconGridComponent } from '@shared/grid/icon/icon.component';
import { TimeComponent } from '@shared/grid/time/time.component';
import { IGuide } from '@shared/models/upload/upload.models';
import { ApiMainService, IGetGuide } from '@shared/services/api.main.service';
import { RoleService } from '@shared/services/role.service';
import { UserService } from '@shared/services/user.service';
import { RightSidebarComponent } from '@sys-comp/right-sidebar/right-sidebar.component';
import { SingleGuideDetailsComponent } from '@sys-comp/view-single-guide/single-guide-details/single-guide-details.component';
import { ColDef, GridOptions } from 'ag-grid-community';
import { ProfileService } from '../../../../system/services/profile.service';
import { SearchingSubjectService } from '../../../../service/searching.subject.service';
import { Subscription } from 'rxjs';
import {ButtonDeleteComponent} from "@shared/grid/button-delete/button-delete.component";
import {ConfirmationService} from "../../../../service/confirmation.service";

@Component({
  selector: 'ec-guide-list',
  templateUrl: './guide-list.component.html',
  styleUrls: ['./guide-list.component.scss'],
})
export class GuideListComponent implements OnInit, OnDestroy {
  @Input() console: boolean;
  @ViewChild('details') details: SingleGuideDetailsComponent;
  private subscription: Subscription;
  public loaded = false;
  public filter = DEFAULT_SEARCH_SET;
  title: string = 'all-courses';
  public rowData: any = [];
  button: string;
  userGuide: any;
  gridOptions: GridOptions = {
    suppressCellSelection: true,
    suppressHorizontalScroll: true,
    suppressRowTransform: true,
  };
  form: FormGroup;
  @ViewChild('sidebar') sidebar: RightSidebarComponent;
  selected: IGuide;
  defaultColDef: ColDef = {
    sortable: true,
    filter: false,
    resizable: false,
    unSortIcon: false,
    sortingOrder: ['asc', 'desc'],
    suppressMovable: true,
  };
  columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      cellRendererFramework: IconGridComponent,
      cellRendererParams: 'list',
    },
    {
      headerName: 'Category',
      field: 'category.type',
      cellRendererFramework: CategoryComponent,
    },
    {
      headerName: 'Added by',
      field: 'creator',
      cellRendererFramework: FullNameComponent,
      cellRendererParams: 'user',
      valueGetter: (params) => {
        return `${params.data.creator.firstName} ${params.data.creator.lastName}`;
      },
    },
    {
      headerName: 'Pages',
      field: 'pages',
    },
    {
      headerName: 'Time',
      field: 'time',
      cellRendererFramework: TimeComponent,
    },
    {
      headerName: 'Last modified',
      field: 'updated_at',
      cellRendererFramework: DateTimeComponent,
      cellRendererParams: {
        format: 'MMM dd, yyyy',
      },
    }
  ];
  prices;
  constructor(
    private searchingService: SearchingSubjectService,
    private apiMainService: ApiMainService,
    private role: RoleService,
    private userService: UserService,
    private profile: ProfileService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.role.masterRole()) {
      this.columnDefs.push(
        {
          headerName: 'PDF',
          field: 'resourceAttached',
          cellRendererParams: { success: 'file-attached', fail: 'file-missed' },
          cellRendererFramework: IconGridComponent,
        },
        {
          headerName: 'Action',
          field: 'id',
          cellRendererFramework: ButtonDeleteComponent,
          cellRendererParams: {
            clicked: (guideId) => this.delete(guideId)
          }
        }
      );
    }
    this.subscription = this.searchingService.filterSbj$.subscribe((filter) => {
      this.filter = filter;
      this.getGuideList().subscribe((res) => {
        this.rowData = res;
        this.loaded = true;
      });
    });

    this.form = this.formBuilder.group({
      level: ['', [Validators.required]],
    });
    this.apiMainService
      .getPriceList()
      .subscribe((prices) => (this.prices = prices));
  }

  public delete(guideId: number) {
    this.loaded = false;
    this.apiMainService.fullGuideDelete(guideId)
      .subscribe(() => {
        this.getGuideList().subscribe((res) => {
          this.rowData = res;
          this.loaded = true;
        });
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getGuideList() {
    return this.apiMainService.getGuideList(this.filter);
  }

  onRowSelected(guide: IGuide) {
    this.apiMainService.getUser(this.role.user.id).subscribe((response) => {
      this.userService.user = response.data;
      this.selected = guide;
      this.userGuide = this.userService.userGuide(this.selected.id);
      if (!this.userGuide) {
        this.button = 'take';
      } else if (this.userGuide.passingProcess.statusId === 3) {
        this.button = 'continue';
      } else {
        this.button = 'details';
      }
      this.sidebar.show();
    });
  }

  openDetails() {
    this.sidebar.hide();
    if (!this.userGuide) {
      setTimeout(() => {
        this.details.show();
      }, 200);
    } else if (this.button === 'continue') {
      this.goToGuide();
    } else {
      this.viewDetails();
    }
  }

  closeDetails() {
    this.details.hide();
  }

  navigateTo(xp: { level: string }) {
    this.details.hide();
    const isAvailable: boolean = this.userService.checkAvailability(
      this.selected.id
    );
    if (isAvailable) {
      this.goToGuide();
      this.form.reset();
    } else {
      this.takeCourse(xp).subscribe(() => {
        this.apiMainService.getUser(this.role.user.id).subscribe((response) => {
          this.userService.user = response.data;
          this.goToGuide();
          this.form.reset();
        });
      });
    }
  }

  goToGuide() {
    const model = this.userGuide || this.selected;
    if (
      (model || {}).passingProcess &&
      (model.passingProcess || {}).statusId !== 5
    ) {
      localStorage.setItem(
        'start_page',
        `${this.userGuide.passingProcess.page}`
      );
    } else {
      localStorage.setItem('start_page', `1`);
    }
    this.router.navigate(['/', 'system', 'guide', this.selected.id]);
  }

  takeCourse(experience: { level: string }) {
    const body: IGetGuide = {
      userId: this.role.user.id,
      guideId: this.selected.id,
      companyId: this.role.user.companyId,
      experienceLevelId: +experience.level,
    };
    return this.apiMainService.setUserGuide(body);
  }

  viewDetails() {
    this.router.navigate([
      '/',
      'system',
      'process',
      this.userGuide.passingProcess.id,
    ]);
  }
}

<p-tabMenu
  [activeItem]="tabs[0]"
  [model]="tabs"
  class="primeng-tabs"
></p-tabMenu>
<ec-create-company
  [style.display]="createCompanyComponent ? 'block' : 'none'"
></ec-create-company>
<ec-invite
  [style.display]="inviteOperatorComponent ? 'block' : 'none'"
></ec-invite>

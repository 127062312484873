<div class="ec-system-container">
  <!-- <div class="ec-system-title">
    <span> {{'invite.label.title' | translate}}</span>
  </div> -->
  <div class="ec-system-form">
    <form [formGroup]="inviteForm" (ngSubmit)="submitForm()">
      <ec-company-status
        [companies]="companies"
        [master]="master"
        [companyId]="companyId"
        (enableSelect)="enableCompanySelect()"
        (disableSelect)="disableCompanySelect()"
        (status)="status($event)"
        (id)="id($event)"
      ></ec-company-status>
      <div class="ec-system-form-group">
        <input
          type="text"
          placeholder="{{'invite.placeholder.operator-email' | translate}}"
          formControlName="email"
        />
        <span
          class="validation_error__message"
          *ngIf="
              inviteForm.get('email').invalid && inviteForm.get('email').touched
            "
        >
          <span *ngIf="inviteForm.get('email').errors['pattern']">
            {{'company.error.contact-person-email-unique' | translate}}
          </span>
          <span *ngIf="inviteForm.get('email').errors['required']">
            {{'invite.error.email-required' | translate}}
          </span>
          <span *ngIf="inviteForm.get('email').errors['emailIsNotAvailable']">
            {{'invite.error.email-exist' | translate}}</span
          >
        </span>
      </div>
      <div class="ec-system-form-group multiple-buttons">
        <button type="button" (click)="cancel()">
          {{'action-button.cancel' | translate}}
        </button>
        <button type="submit" [disabled]="inviteForm.invalid || sending">
          <span *ngIf="!sending">{{'action-button.save' | translate}}</span>
          <ec-preloader *ngIf="sending"></ec-preloader>
        </button>
      </div>
    </form>
  </div>
</div>

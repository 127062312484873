import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavBarService {
  visible: boolean;
  visibleSbj$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor() {
    this.visible = false;
  }

  hide() {
    this.visible = false;
    this.visibleSbj$.next(this.visible);
  }

  show() {
    this.visible = true;
    this.visibleSbj$.next(this.visible);
  }

  toggle() {
    this.visible = !this.visible;
    this.visibleSbj$.next(this.visible);
  }
}

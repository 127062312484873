<div
  class="ag-theme-material pagination ag-paging-page-summary-panel"
  title="{{'tooltips.system.guide-test.pagination' | translate}}"
>
  <div
    (click)="onFirst()"
    [class.ag-disabled]="!enableFirst || page === 1"
    class="ag-icon ag-paging-button ag-icon-first"
  ></div>
  <div
    (click)="onPrevious()"
    [class.ag-disabled]="page === 1"
    class="ag-icon ag-paging-button ag-icon-previous"
  ></div>
  {{page}}/{{pages}}
  <div
    (click)="onNext()"
    [class.ag-disabled]="pages === page"
    class="ag-icon ag-paging-button ag-icon-next"
  ></div>
  <div
    (click)="onLast()"
    [class.ag-disabled]="!enableLast || pages === page"
    class="ag-icon ag-paging-button ag-icon-last"
  ></div>
</div>
<div class="fixed-content">
  <ng-content></ng-content>
</div>

import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ROLES_ARRAY} from '@shared/constans/default';

@Component({
  selector: 'ec-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements ICellRendererAngularComp {

  constructor() { }

  status: number;
  private params: any = [];

  agInit(params: any): void {
    params.data.roles.map(r => {
      if (r.id !== 3) {
        this.params.push(ROLES_ARRAY[r.id - 1]);
      }
    });
    this.status = this.params.join(', ');
  }

  refresh(params: any): boolean {
    return false;
  }

}

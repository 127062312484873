<div class="profile-wrapper">
  <form (ngSubmit)="submitForm()" *ngIf="loaded" [formGroup]="companyForm">
    <div class="system-form-title">
      <div class="first-column">
        <span>{{'company-profile.title.title' | translate}}</span>
      </div>
    </div>
    <div class="system-form-actions form-switcher" *ngIf="isNaNOperator">
      <button (click)="enableForm()" *ngIf="companyForm.disabled">
        {{'action-button.edit' | translate}}
      </button>
    </div>
    <div class="system-form-group">
      <!-- <div class="system-form-control"> -->
      <input
        type="file"
        class="file-input-hidden"
        id="file-input"
        [disabled]="companyForm.disabled"
        (change)="onFileChange($event)"
        accept="image/*"
      />
      <div
        class="logo-block"
        [ngStyle]="{'opacity': companyForm.disabled ? 0.6 : 1}"
      >
        <img
          class="logo-mini uploaded-logo"
          *ngIf="temporary || companyForm.get('logo').value"
          [src]="temporary || companyForm.get('logo').value"
          alt=""
        />
        <img
          *ngIf="!companyForm.disabled"
          title="Upload Logo"
          class="logo-mini upload-logo"
          (click)="activateInput()"
          src="assets/icons/actions/upload-512.png"
          alt=""
        />
      </div>
      <!-- </div> -->
      <div class="system-form-control">
        <input
          formControlName="name"
          placeholder="{{'company-profile.placeholder.company-name' | translate }}"
          type="text"
        />
        <span
          *ngIf="
                  companyForm.get('name').invalid &&
                  companyForm.get('name').touched
                "
          class="validation_error__message"
        >
          <span *ngIf="companyForm.get('name').errors['required']"
            >{{'company-profile.errors.name' | translate }}
          </span>
        </span>
      </div>
      <div class="system-form-control">
        <input
          formControlName="vat"
          placeholder="{{'company-profile.placeholder.company-vat' | translate }}"
          type="text"
        />
      </div>
      <div
        *ngFor="let item of formContacts.controls; let i = index"
        formArrayName="contacts"
      >
        <div [formGroupName]="i">
          <div class="system-form-control">
            <input
              formControlName="phone"
              placeholder="{{'company-profile.placeholder.company-phone' | translate }}"
              type="text"
              [textMask]="{mask: mask}"
              [ecUnmask]="numberUnmask"
            />
            <span
              *ngIf="isTouched(item,'phone')"
              class="validation_error__message"
            >
              <span *ngIf="hasError(item,'phone')">
                {{'company-profile.errors.phone' | translate }}
              </span>
            </span>
          </div>
          <div class="system-form-control">
            <input
              formControlName="email"
              placeholder="{{'company-profile.placeholder.company-email' | translate }}"
              type="text"
            />
            <span
              *ngIf="isTouched(item,'email')"
              class="validation_error__message"
            >
              <span *ngIf="hasError(item,'email')">
                {{'company-profile.errors.email' | translate }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div
        *ngFor="let item of formLocations.controls; let i = index"
        formArrayName="locations"
      >
        <div [formGroupName]="i">
          <div class="system-form-control">
            <input
              formControlName="country"
              placeholder="{{'company-profile.placeholder.company-country' | translate }}"
              type="text"
            />
            <span
              *ngIf="isTouched(item,'country')"
              class="validation_error__message"
            >
              <span *ngIf="hasError(item,'country')">
                {{'company-profile.errors.country' | translate }}
              </span>
            </span>
          </div>
          <div class="system-form-control">
            <input
              formControlName="street"
              placeholder="{{'company-profile.placeholder.company-street' | translate }}"
              type="text"
            />
            <span
              *ngIf="isTouched(item,'street')"
              class="validation_error__message"
            >
              <span *ngIf="hasError(item,'street')">
                {{'company-profile.errors.street' | translate }}
              </span>
            </span>
          </div>
          <div class="system-form-control">
            <input
              formControlName="city"
              placeholder="{{'company-profile.placeholder.company-city' | translate }}"
              type="text"
            />
            <span
              *ngIf="isTouched(item,'city')"
              class="validation_error__message"
            >
              <span *ngIf="hasError(item,'city')">
                {{'company-profile.errors.city' | translate }}
              </span>
            </span>
          </div>
          <div class="system-form-control">
            <input
              formControlName="zip"
              placeholder="{{'company-profile.placeholder.company-zip' | translate }}"
              type="text"
            />
            <span
              *ngIf="isTouched(item,'zip')"
              class="validation_error__message"
            >
              <span *ngIf="hasError(item,'zip')">
                {{'company-profile.errors.zip' | translate }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <label class="label">{{'budget.individual' | translate}}</label>
      <div
        formGroupName="highPriceBudget"
        class="ec-system-form-group-array discount"
        [style.margin-top.px]="'30'"
      >
        <div class="ec-system-form-group inline-label price">
          <span>{{'company.label.high-price' | translate}}</span>
          <input
            type="text"
            readonly
            disabled
            placeholder="{{priceList[0].amount}}"
          />
          <span>NOK</span>
        </div>
        <div class="ec-system-form-group inline-label">
          <input
            formControlName="discount"
            type="number"
            min="0"
            max="100"
            placeholder="{{'company.placeholder.discount' | translate}}"
          />
          <span>%</span>
        </div>
      </div>
      <div
        formGroupName="lowPriceBudget"
        class="ec-system-form-group-array discount"
      >
        <div class="ec-system-form-group inline-label price">
          <span>{{'company.label.low-price' | translate}}</span>
          <input
            type="text"
            readonly
            disabled
            placeholder="{{priceList[1].amount}}"
          />
          <span>NOK</span>
        </div>
        <div class="ec-system-form-group inline-label">
          <input
            formControlName="discount"
            type="number"
            min="0"
            max="100"
            placeholder="{{'company.placeholder.discount' | translate}}"
          />
          <span>%</span>
        </div>
      </div>
      <!-- LEFT FOR THE FUTURE -->
      <div class="ec-system-form-group ec-system-form-group-checkbox">
        <label for="freeBudget"
          >{{'company.label.freeBudget' | translate}}</label
        >
        <p-checkbox
          class="primeng-checkbox"
          [binary]="true"
          formControlName="freeBudget"
          id="freeBudget"
          (onChange)="toggleBudget()"
        ></p-checkbox>
      </div>
      <div formGroupName="highPriceBudget" class="ec-system-form-group-array">
        <div class="ec-system-form-group inline-label">
          <input formControlName="quantity" type="number" min="1" />
          <span
            >{{'company.label.quantity-first' |
            translate}}{{priceList[0].amount}}{{'company.label.quantity-second'
            | translate}}</span
          >
        </div>
        <span class="form-error" *ngIf="hp.get('quantity').invalid">
          <span *ngIf="hp.get('quantity').errors['required']"
            >{{'company.error.quantity-required' | translate}}</span
          >
        </span>
      </div>

      <div formGroupName="lowPriceBudget" class="ec-system-form-group-array">
        <div class="ec-system-form-group inline-label">
          <input formControlName="quantity" type="number" min="1" />
          <span
            >{{'company.label.quantity-first' |
            translate}}{{priceList[1].amount}}{{'company.label.quantity-second'
            | translate}}</span
          >
        </div>
        <span
          class="form-error"
          *ngIf="!isFreeBudget && lp.get('quantity').invalid"
        >
          <span *ngIf="lp.get('quantity').errors['required']"
            >{{'company.error.quantity-required' | translate}}</span
          >
        </span>
      </div>
    </div>

    <div *ngIf="companyForm.enabled" class="system-form-actions">
      <button (click)="disableForm()" type="button">
        {{'action-button.cancel' | translate}}
      </button>
      <button [disabled]="companyForm.invalid || sending" type="submit">
        <span *ngIf="!sending">{{'action-button.save' | translate}}</span>
        <ec-preloader *ngIf="sending"></ec-preloader>
      </button>
    </div>
  </form>
</div>

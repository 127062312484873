<p-dialog
  #Modal
  [header]="header"
  [style]="styles"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="visible"
  [showHeader]="showHeader"
  [closable]="closable"
  [modal]="modal">
  <p-footer *ngIf="includeFooter">
    <div [class]="'button-container ' + buttonLayoutClass">
      <button *ngIf="showCancel"
                  (click)="cancel()">
        {{ 'button.cancel' | translate }}
      </button>
      <button (click)="submit()">
        {{ 'button.confirm' | translate }}
      </button>
    </div>
  </p-footer>
  <ng-content>
  </ng-content>

</p-dialog>

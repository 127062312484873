import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@auth/services/auth.service';
import { ConfirmPasswordValidator } from '@shared/helpers/confirm-password.validator';
import { NotificationService } from '@shared/services/notification.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'ec-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  sending: boolean = false;
  private language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';

  constructor(
    private formBuilder: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private authService: AuthService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.formInit();
  }

  public cancel(): void {
    this.ref.close();
  }

  public submitForm(): void {
    if (this.changePasswordForm.valid) {
      this.sending = true;
      const {
        oldPassword,
        password: newPassword,
      } = this.changePasswordForm.value;
      this.changePassword({ oldPassword, newPassword });
    }
  }

  private formInit() {
    this.changePasswordForm = this.formBuilder.group(
      {
        oldPassword: [null, [Validators.required]],
        password: [null, [Validators.required]],
        confirmPassword: [null, [Validators.required]],
      },
      { validator: ConfirmPasswordValidator.MatchPassword }
    );
  }

  private changePassword(object) {
    this.sending = true;
    this.authService.changePassword(object).subscribe((res) => {
      console.log(res);
      if (!(res instanceof HttpErrorResponse)) {
        this.sending = false;
      }
      this.response(res.status);
      this.sending = false;
    });
  }

  private response(status: number | boolean): void {
    if (status === 404) {
      this.notification.send(
        this.language === 'en'
          ? 'Incorrect old password or user not found...'
          : 'Feil i gammelt passord eller bruker ikke funnet',
        'error'
      );
    } else if (status === true) {
      this.notification.send(
        this.language === 'en'
          ? 'Password changed successfully!'
          : 'Passordet er oppdatert',
        'info'
      );
      this.ref.close();
    }
  }
}

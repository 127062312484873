<div class="ec-system-container">
  <form [formGroup]="f" (ngSubmit)="submit()">
    <div class="ec-system-form">
      <ec-company-status
        [companies]="companies"
        [master]="master"
        [companyId]="companyId"
        (enableSelect)="enableSelect()"
        (disableSelect)="disableSelect()"
        (status)="status($event)"
        (id)="id($event)"
      ></ec-company-status>
      <div class="ec-system-form-group">
        <input
          type="text"
          formControlName="email"
          placeholder="{{'profile.placeholder.email' | translate}}"
        />
        <span
          class="validation_error__message"
          *ngIf="
              f.get('email').invalid && f.get('email').touched
            "
        >
          <span *ngIf="f.get('email').errors['emailIsNotAvailable']">
            {{'invite.error.email-exist' | translate}}</span
          >
          <span *ngIf="f.get('email').errors['pattern']">
            {{'company.error.contact-person-email-unique' | translate}}
          </span>
          <span *ngIf="f.get('email').errors['required']">
            {{'invite.error.email-required' | translate}}
          </span></span
        >
      </div>
      <div class="ec-system-form-group">
        <input
          type="text"
          formControlName="firstName"
          placeholder="{{'profile.placeholder.first-name' | translate}}"
        />
        <span
          class="form-error"
          *ngIf="f.get('firstName').invalid && f.get('firstName').touched"
        >
          <span *ngIf="f.get('firstName').errors['required']">
            {{'profile.error.first-name-required' | translate}}
          </span>
          <span *ngIf="f.get('firstName').errors['minlength']">
            {{'profile.error.length-first' | translate}}{{
            f.get("firstName")["errors"]["minlength"]["requiredLength"]
            }}{{'profile.error.length-second' | translate}}{{
            f.get("firstName")["errors"]["minlength"]["actualLength"] }}.
          </span>
        </span>
      </div>
      <div class="ec-system-form-group">
        <input
          type="text"
          formControlName="lastName"
          placeholder="{{'profile.placeholder.last-name' | translate}}"
        />
        <span
          *ngIf="f.get('lastName').invalid && f.get('lastName').touched"
          class="form-error"
        >
          <span *ngIf="f.get('lastName').errors['required']">
            {{'profile.error.last-name-required' | translate}}
          </span>
          <span *ngIf="f.get('lastName').errors['minlength']">
            {{'profile.error.length-first' | translate}}{{
            f.get("lastName")["errors"]["minlength"]["requiredLength"]
            }}{{'profile.error.length-second' | translate}}{{
            f.get("lastName")["errors"]["minlength"]["actualLength"] }}.
          </span>
        </span>
      </div>
      <div
        *ngFor="let item of c.controls; let i = index"
        formArrayName="contacts"
        class="ec-system-form-group-array"
      >
        <div [formGroupName]="i">
          <div class="ec-system-form-group">
            <!-- phone-not-required -->
            <input
              formControlName="phone"
              [placeholder]="companyStatus === 1 ?
              ('profile.placeholder.phone-not-required' | translate) :
               ('profile.placeholder.phone' | translate)"
              type="text"
              [textMask]="{mask: mask}"
              [ecUnmask]="numberUnmask"
            />
            <span *ngIf="isTouched(item,'phone')" class="form-error">
              <span *ngIf="hasError(item,'phone')">
                {{'profile.error.phone-required' | translate}}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="ec-system-form-group">
        <label>{{'profile.label.birthday' | translate}}</label>
        <p-calendar
          [locale]="primeNGLocalDataService.en"
          [monthNavigator]="true"
          [readonlyInput]="true"
          [yearNavigator]="true"
          class="system-primeng-calendar edit-profile-modal"
          formControlName="birthday"
          inputStyleClass="system-primeng-input"
          placeholder="{{'profile.placeholder.date' | translate}}"
          styleClass="system-primeng"
          yearRange="1950:2003"
        >
        </p-calendar>
        <span
          class="form-error"
          *ngIf="f.get('birthday').invalid && f.get('birthday').touched"
        >
          <span *ngIf="f.get('birthday').errors['required']">
            {{'profile.error.first-name-required' | translate}}
          </span>
        </span>
      </div>
    </div>
    <div *ngIf="f.enabled" class="ec-system-form-group multiple-buttons modal">
      <button (click)="cancel()" type="button">
        {{'action-button.cancel' | translate}}
      </button>
      <button type="submit" [disabled]="f.invalid || sending">
        <span *ngIf="!sending">{{'action-button.save' | translate}}</span>
        <ec-preloader *ngIf="sending"></ec-preloader>
      </button>
    </div>
  </form>
</div>

import { Injectable } from '@angular/core';
import { IRole, IUser, IResponse } from '@shared/interfaces/IResponse';
import { BehaviorSubject, of } from 'rxjs';
import { HttpService } from './http.service';
import { CookiesService } from './cookie.service';
import apiRoutes from '@shared/constans/apiRoutes';
import { HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
export interface IAnswer {
  id: number;
  text: string;
  correctAnswer: boolean;
  guideTestQuestionId: number;
  orderNum: number;
}
export interface IQuestion {
  text: string;
  page?: number;
  guideId: number;
  answers: IAnswer[];
}
@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  private _questions: IQuestion[] = [];
  constructor(private http: HttpService, private cookie: CookiesService) {}

  get questions(): IQuestion[] {
    return this._questions;
  }

  set questions(questions: IQuestion[]) {
    this._questions = questions;
  }
  saveAnswer(body) {
    return this.http
      .post(apiRoutes.question.saveAnswers, body, {
        headers: this.getHeaders()
      })
      .pipe(
        map((response: IResponse) => {
          console.log(response);
          return response;
        }),
        catchError((err: Error) => {
          console.log(err);
          return of(err);
        })
      );
  }
  getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.cookie.get('Authorization')
    });
  }
}

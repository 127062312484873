import { Component, OnInit } from '@angular/core';
import {RoleService} from '@shared/services/role.service';

@Component({
  selector: 'ec-system-person',
  templateUrl: './system-person.component.html',
  styleUrls: ['./system-person.component.scss']
})
export class SystemPersonComponent implements OnInit {

  constructor(
    private role: RoleService
  ) { }

  public user = this.role.user;

  ngOnInit(): void {
    console.log(this.role.user);
  }

}

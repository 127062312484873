<div class="ec-system-container">
  <form [formGroup]="companyForm" (ngSubmit)="submit()" *ngIf="loaded">
    <div class="ec-system-form">
      <div class="ec-system-form-group">
        <input
          formControlName="name"
          type="text"
          placeholder="{{'company.placeholder.name' | translate}}"
        />
        <span
          class="form-error"
          *ngIf="
          c.get('name').invalid &&
          c.get('name').touched
        "
        >
          <span *ngIf="c.get('name').errors['required']">
            {{'company.error.name-required' | translate}}</span
          >
        </span>
      </div>
      <div class="ec-system-form-group">
        <input
          formControlName="vat"
          type="text"
          placeholder="{{'company.placeholder.vat' | translate}}"
        />
        <span
          class="form-error"
          *ngIf="c.get('vat').invalid && c.get('vat').touched"
        >
          <span *ngIf="c.get('vat').errors['required']"
            >{{'company.error.vat-required' | translate}}</span
          >
          <span
            *ngIf="
              c.get('vat').errors[
                'vatNumberIsNotAvailable'
              ]
            "
          >
            {{'company.error.vat-unique' | translate}}</span
          >
        </span>
      </div>
      <div class="ec-system-form-group">
        <input
          formControlName="internalId"
          type="text"
          placeholder="{{'company.placeholder.internalId' | translate}}"
          ecDigitsOnly
        />
        <span
          class="form-error"
          *ngIf="
          c.get('internalId').invalid &&
          c.get('internalId').touched
        "
        >
          <span *ngIf="c.get('internalId').errors['required']">
            {{'company.error.internalId-required' | translate}}</span
          >
        </span>
      </div>
      <!-- LEFT FOR THE FUTURE -->
      <!-- <div class="ec-system-form-group ec-system-form-group-radio">
          <div class="group-radio-label">
            <label>{{'company.label.status' | translate}}</label>
          </div>
          <div class="group-radio-buttons">
            <div>
              <input
                formControlName="companyTypeId"
                value="1"
                id="company"
                type="radio"
              />
              <label for="company">{{'company.label.company' | translate}}</label>
            </div>
            <div>
              <input
                formControlName="companyTypeId"
                value="2"
                id="independent"
                type="radio"
              />
              <label for="independent"
                >{{'company.label.independent' | translate}}</label
              >
            </div>
          </div>
        </div> -->
      <div
        formArrayName="contacts"
        *ngFor="let item of cc.controls; let i = index"
        class="ec-system-form-group-array"
      >
        <div [formGroupName]="i">
          <div class="ec-system-form-group">
            <input
              formControlName="phone"
              type="text"
              placeholder="{{'company.placeholder.phone' | translate}}"
              [textMask]="{mask: mask}"
              [ecUnmask]="numberUnmask"
            />
            <span class="form-error" *ngIf="isTouched(item,'phone')">
              <span *ngIf="hasError(item,'phone', 'required')">
                {{'company.error.phone-required' | translate}}</span
              >
            </span>
          </div>
          <div class="ec-system-form-group">
            <input
              formControlName="email"
              type="text"
              placeholder="{{'company.placeholder.email' | translate}}"
            />
            <span class="form-error" *ngIf="isTouched(item,'email')">
              <span *ngIf="hasError(item, 'email', 'required')">
                {{'company.error.email-required' | translate}}</span
              >
              <span *ngIf="hasError(item, 'email', 'pattern')">
                {{'company.error.email-unique' | translate}}</span
              >
              <span *ngIf="hasError(item, 'email', 'emailIsNotAvailable')">
                {{'invite.error.email-exist' | translate}}</span
              >
            </span>
          </div>
        </div>
      </div>
      <div
        formArrayName="locations"
        *ngFor="let item of l.controls; let i = index"
        class="ec-system-form-group-array"
      >
        <div [formGroupName]="i">
          <div class="ec-system-form-group">
            <input
              formControlName="country"
              type="text"
              placeholder="{{'company.placeholder.country' | translate}}"
            />
            <span class="form-error" *ngIf="isTouched(item,'country')">
              <span *ngIf="hasError(item, 'country', 'required')">
                {{'company.error.country-required' | translate}}</span
              >
            </span>
          </div>
          <div class="ec-system-form-group">
            <input
              formControlName="city"
              type="text"
              placeholder="{{'company.placeholder.city' | translate}}"
            />
            <span class="form-error" *ngIf="isTouched(item,'city')">
              <span *ngIf="hasError(item, 'city', 'required')">
                {{'company.error.city-required' | translate}}</span
              >
            </span>
          </div>
          <div class="ec-system-form-group">
            <input
              formControlName="street"
              type="text"
              placeholder="{{'company.placeholder.street' | translate}}"
            />
            <span class="form-error" *ngIf="isTouched(item,'street')">
              <span *ngIf="hasError(item, 'street', 'required')">
                {{'company.error.street-required' | translate}}</span
              >
            </span>
          </div>
          <div class="ec-system-form-group">
            <input
              formControlName="zip"
              type="text"
              placeholder="{{'company.placeholder.zip' | translate}}"
            />
            <span class="form-error" *ngIf="isTouched(item,'zip')">
              <span *ngIf="hasError(item, 'zip', 'required')">
                {{'company.error.zip-required' | translate}}</span
              >
            </span>
          </div>
        </div>
      </div>
      <div formGroupName="person" class="ec-system-form-group-array">
        <div class="ec-system-form-group">
          <input
            formControlName="name"
            type="text"
            placeholder="{{'company.placeholder.contact-person-name' | translate}}"
          />
          <span
            class="form-error"
            *ngIf="p.get('name').invalid && p.get('name').touched "
          >
            <span *ngIf="p.get('name').errors['required']">
              {{'company.error.contact-person-name-required' | translate}}</span
            >
          </span>
        </div>
        <div class="ec-system-form-group">
          <input
            formControlName="phone"
            type="text"
            placeholder="{{'company.placeholder.contact-person-phone' | translate}}"
            [textMask]="{mask: mask}"
            [ecUnmask]="numberUnmask"
          />
          <span
            class="form-error"
            *ngIf="p.get('phone').invalid && p.get('phone').touched "
          >
            <span *ngIf="p.get('phone').errors['required']">
              {{'company.error.contact-person-phone-required' |
              translate}}</span
            >
          </span>
        </div>
        <div class="ec-system-form-group">
          <input
            formControlName="email"
            type="text"
            placeholder="{{'company.placeholder.contact-person-email' | translate}}"
          />
          <span
            class="form-error"
            *ngIf="p.get('email').invalid && p.get('email').touched "
          >
            <span *ngIf="p.get('email').errors['required']"
              >{{'company.error.contact-person-email-required' |
              translate}}</span
            >
            <span *ngIf="p.get('email').errors['pattern']"
              >{{'company.error.contact-person-email-unique' | translate}}</span
            >
            <span *ngIf="p.get('email').errors['emailIsNotAvailable']"
              >{{'invite.error.email-exist' | translate}}</span
            >
          </span>
        </div>
      </div>
      <label class="budget-label">{{'budget.individual' | translate}}</label>
      <div
        formGroupName="highPriceBudget"
        class="ec-system-form-group-array discount"
      >
        <div class="ec-system-form-group inline-label price">
          <span>{{'company.label.high-price' | translate}}</span>
          <input
            type="text"
            readonly
            disabled
            placeholder="{{priceList[0].amount}}"
          />
          <span>NOK</span>
        </div>
        <div class="ec-system-form-group inline-label">
          <input
            formControlName="discount"
            type="number"
            min="0"
            max="100"
            placeholder="{{'company.placeholder.discount' | translate}}"
          />
          <span>%</span>
        </div>
      </div>
      <div
        formGroupName="lowPriceBudget"
        class="ec-system-form-group-array discount"
      >
        <div class="ec-system-form-group inline-label price">
          <span>{{'company.label.low-price' | translate}}</span>
          <input
            type="text"
            readonly
            disabled
            placeholder="{{priceList[1].amount}}"
          />
          <span>NOK</span>
        </div>
        <div class="ec-system-form-group inline-label">
          <input
            formControlName="discount"
            type="number"
            min="0"
            max="100"
            placeholder="{{'company.placeholder.discount' | translate}}"
          />
          <span>%</span>
        </div>
      </div>
      <div class="ec-system-form-group ec-system-form-group-checkbox">
        <label for="freeBudget"
          >{{'company.label.freeBudget' | translate}}</label
        >
        <p-checkbox
          class="primeng-checkbox"
          [binary]="true"
          formControlName="freeBudget"
          id="freeBudget"
          (onChange)="toggleBudget()"
        ></p-checkbox>
      </div>
      <div formGroupName="highPriceBudget" class="ec-system-form-group-array">
        <div class="ec-system-form-group inline-label">
          <input formControlName="quantity" type="number" min="1" />
          <span
            >{{'company.label.quantity-first' |
            translate}}{{priceList[0].amount}}{{'company.label.quantity-second'
            | translate}}</span
          >
        </div>
        <span class="form-error" *ngIf="hp.get('quantity').invalid">
          <span *ngIf="hp.get('quantity').errors['required']"
            >{{'company.error.quantity-required' | translate}}</span
          >
        </span>
      </div>

      <div formGroupName="lowPriceBudget" class="ec-system-form-group-array">
        <div class="ec-system-form-group inline-label">
          <input formControlName="quantity" type="number" min="1" />
          <span
            >{{'company.label.quantity-first' |
            translate}}{{priceList[1].amount}}{{'company.label.quantity-second'
            | translate}}</span
          >
        </div>
        <span class="form-error" *ngIf="lp.get('quantity').invalid">
          <span *ngIf="lp.get('quantity').errors['required']"
            >{{'company.error.quantity-required' | translate}}</span
          >
        </span>
      </div>
    </div>
    <div class="ec-system-form-group multiple-buttons modal">
      <button type="button" (click)="cancel()">
        {{'action-button.cancel' | translate}}
      </button>
      <button
        type="submit"
        *ngIf="!formDisabled; else edit"
        [disabled]="companyForm.invalid"
      >
        {{'action-button.save' | translate}}
      </button>
      <ng-template #edit>
        <button type="button" (click)="enableForm()">
          {{'action-button.edit' | translate}}
        </button>
      </ng-template>
    </div>
  </form>
</div>

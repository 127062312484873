import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IUser } from '@shared/interfaces/IResponse';
import { IGuide } from '@shared/models/upload/upload.models';
import { HttpService } from '@shared/services/http.service';
import { RightSidebarComponent } from '@sys-comp/right-sidebar/right-sidebar.component';

interface IAnswer {
  answer: number;
  vars: string[];
}

interface IAnswerRequest {
  questionId: number;
  answerId: number;
}

interface IAnswersRequest {
  guideId: number;
  answers: IAnswerRequest[];
}

@Component({
  selector: 'ec-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
  @ViewChild('sidebar') sidebar: RightSidebarComponent;
  user: IUser;
  @Input() data: IGuide;
  @Input() close: boolean = true;
  @Input() button: string;
  @Input() icon: string;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onAction: EventEmitter<any> = new EventEmitter();
  question: string;
  questions: IAnswer[];
  questionsForm: FormArray;
  counter: number = 0;
  visible: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private http: HttpService,
              private route: ActivatedRoute) {
  }

  get answers() {
    return this.questionsForm.get('answers') as FormArray;
  }

  ngOnInit(): void {
    this.questionsForm = this.formBuilder.array([]);

    this.questions = [
      {
        answer: 0,
        vars: [
          '1',
          '2',
          '3',
          '4',
        ],
      },
    ];
  }

  addQuestion(questionId: number) {
    this.questionsForm.push(this.formBuilder.group({
      questions: [questionId],
      answers: this.generateAnswers(),
    }));
    this.counter++;
  }

  generateAnswers(): FormArray {
    const formArray: FormArray = this.formBuilder.array([]);
    // this.questions.forEach((answer) => {
    //   formArray.push({
    //     ...answer
    //   });
    // });
    return formArray;
  }

  show() {
    console.log(this.questionsForm.value);
  }

  hide() {
    if (this.visible) {
      this.visible = false;
      this.onClose.emit(null);
      this.questionsForm.reset();
    }
  }

  action(event: any) {
    this.onAction.emit(event);
  }

  saveResult() {
    const body: IAnswersRequest = {
      guideId: this.route.snapshot.params.id,
      answers: [
        {
          questionId: 0,
          answerId: 1,
        },
      ],
    };
    this.http.post('/api/user/guide/answer', body)
      .subscribe((response) => {
        console.log(response);
      });
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { AuthService } from '@auth/services/auth.service';
import { CookiesService } from '@shared/services/cookie.service';
import { NotificationService } from '@shared/services/notification.service';
import { RoleService, ROLE_IDS } from '@shared/services/role.service';

import { ProfileService } from 'app/system/services/profile.service';
import { UploadService } from '@shared/services/upload.service';
import { CompanySubjectService } from '../../../../service/company.subject.service';
import { ApiMainService } from '@shared/services/api.main.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IRole } from '@shared/interfaces/IResponse';
import { AsyncValidator } from '@shared/validators/async-vat.validator';
import { LanguageSubjectService } from 'app/service/language.subject.service';

@Component({
  selector: 'ec-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
})
export class CompanyProfileComponent implements OnInit {
  public companyForm: FormGroup;
  public isNaNOperator = this.roleService.role !== 'operator';
  public loaded = false;
  public company: any = {};
  private companyLogoFile: File;
  public temporary: any;
  sending: boolean = false;
  priceList;
  isFreeBudget: boolean = false;
  master: boolean = false;

  public mask = [
    '+',
    /\d/,
    /\d/,
    ' ',
    /[0-9]/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ];
  numberUnmask = /\D/g;
  private language: string = localStorage.getItem('Language')
    ? localStorage.getItem('Language')
    : 'no';

  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private uploadService: UploadService,
    private cookie: CookiesService,
    private notification: NotificationService,
    private roleService: RoleService,
    private companySubjectService: CompanySubjectService,
    private apiService: ApiMainService,
    private authService: AuthService,
    private languageSubjectService: LanguageSubjectService
  ) {}

  // Form
  get formData() {
    return this.companyForm as FormGroup;
  }

  // Contacts
  get formContacts() {
    return this.formData.get('contacts') as FormArray;
  }

  // Locations
  get formLocations() {
    return this.formData.get('locations') as FormArray;
  }

  // Web
  get formWeb() {
    return this.formData.get('web') as FormArray;
  }
  // COMPANY FREE BUDGET
  get fb(): FormControl {
    return this.formData.get('freeBudget') as FormControl;
  }
  // HIGH PRICE
  get hp(): FormGroup {
    return this.formData.get('highPriceBudget') as FormGroup;
  }
  // LOW PRICE
  get lp(): FormGroup {
    return this.formData.get('lowPriceBudget') as FormGroup;
  }

  ngOnInit(): void {
    this.getPriceList();
    this.userRoleCheck();
    this.storageChange();
  }
  private userRoleCheck() {
    const userRoles = this.roleService.roles;
    if ((userRoles || []).length) {
      if (userRoles.some((r: IRole) => r.id === ROLE_IDS.MASTER)) {
        return (this.master = true);
      }
    }
  }
  private getPriceList() {
    this.apiService.getPriceList().subscribe((priceList) => {
      this.priceList = priceList;
      const selfCompany = JSON.parse(this.cookie.get('Company'));
      this.companyInfo(selfCompany.id);
    });
  }

  budgetEditing() {
    if (!this.master && this.isFreeBudget) {
      this.lp.disable();
      this.hp.disable();
    } else if (!this.master && !this.isFreeBudget) {
      this.lp.disable();
      this.hp.get('discount').disable();
    } else if (this.master && this.isFreeBudget) {
      this.hp.get('quantity').disable();
    }
  }

  submitForm() {
    const profile = this.controlRemoving(this.companyForm.getRawValue());
    if (this.companyForm.valid) {
      this.sending = true;
      this.profileService.updateCompany(profile).subscribe((res) => {
        this.sending = false;
        if (!(res instanceof HttpErrorResponse)) {
          if (this.companyLogoFile) {
            this.uploadService
              .uploadProfileLogo(this.companyLogoFile, profile.id, 'companyId')
              .subscribe((result) => {
                this.temporary = result.data.path;
                if (this.roleService.user.companyId === profile.id) {
                  this.companySubjectService.setCompanyLogo(result.data.path);
                  this.cookie.set(
                    'Company',
                    JSON.stringify(
                      Object.assign(profile, { logo: result.data.path })
                    )
                  );
                }
              });
          } else {
            if (this.roleService.user.companyId === profile.id) {
              this.cookie.set('Company', JSON.stringify(profile));
            }
          }
          this.disableForm();
          this.response(res);
          this.getPriceList();
        }
      });
    }
  }

  private controlRemoving(control) {
    Object.keys(control).forEach((key) => {
      if (key === 'freeBudget') {
        delete control[key];
      }
    });
    return control;
  }
  // Form Builders

  // Enable or Disable Reactive Form
  enableForm(): void {
    this.companyForm.enable();
    this.budgetEditing();
  }

  disableForm(): void {
    this.temporary = null;
    this.companyForm.disable();
  }

  // Validators
  public hasError(control, field): boolean {
    return control.get(field).hasError('required');
  }

  public isTouched(control, field): boolean {
    return control.get(field).touched;
  }

  // Getters

  private companyInfo(id: number) {
    this.profileService.getCompany(id).subscribe((res) => {
      if (!(res instanceof HttpErrorResponse)) {
        this.company = res.data;
        this.formInit();
        this.loaded = true;
      }
    });
  }

  public onFileChange(e) {
    console.log(e);
    this.companyLogoFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      this.temporary = evt.target.result;
    };
    reader.readAsDataURL(this.companyLogoFile);
  }

  public activateInput() {
    document.getElementById('file-input').click();
  }

  private formInit(): void {
    console.log(this.company);
    this.companyForm = this.formBuilder.group({
      id: this.company.id,
      name: [this.company.name, [Validators.required]],
      logo: [this.company.logo],
      vat: [this.company.vat],
      companyTypeId: [this.company.companyTypeId],
      paymentStatusId: [this.company.paymentStatusId],
      businessStatusId: [this.company.businessStatusId],
      businessTypeId: [this.company.businessTypeId],
      locations: this.formBuilder.array([]),
      contacts: this.formBuilder.array([]),
      freeBudget: [this.company.highPriceBudget.unlimited],
      highPriceBudget: this.formBuilder.group({
        id: [this.company.highPriceBudget.id],
        companyId: [this.company.id],
        priceId: [this.company.highPriceBudget.priceId],
        quantity: [
          this.company.highPriceBudget.unlimited
            ? 0
            : this.company.highPriceBudget.quantity,
        ],
        discount: [this.company.highPriceBudget.discount],
        unlimited: [this.company.highPriceBudget.unlimited],
      }),
      lowPriceBudget: this.formBuilder.group({
        id: [
          this.company.lowPriceBudget && this.company.lowPriceBudget.id
            ? this.company.lowPriceBudget.id
            : null,
        ],
        companyId: [this.company.id],
        priceId: [
          this.company.lowPriceBudget && this.company.lowPriceBudget.priceId
            ? this.company.lowPriceBudget.priceId
            : null,
        ],
        quantity: [
          this.company.lowPriceBudget && this.company.lowPriceBudget.quantity
            ? this.company.lowPriceBudget.quantity
            : 0,
        ],
        discount: [
          this.company.lowPriceBudget && this.company.lowPriceBudget.discount
            ? this.company.lowPriceBudget.discount
            : 0,
        ],
      }),
    });
    this.isFreeBudget = this.company.highPriceBudget.unlimited;
    if (this.isFreeBudget) {
      this.hp.get('quantity').disable();
    }
    this.locationsFormBuilder(this.company.locations, this.company);
    this.contactsFormBuilder(this.company.contacts, this.company);
    this.lowPriceBudgetChange();
    this.highPriceBudgetChange();
    this.vatNumberChange();
    this.companyForm.disable();
  }

  // Contacts
  private contactsFormBuilder(contacts, data): FormGroup {
    const control = this.formContacts;
    if (contacts && contacts.length) {
      return contacts.forEach((contact) => {
        control.push(
          this.formBuilder.group({
            id: [contact.id],
            companyId: [data.id],
            phone: [
              contact.phone,
              [Validators.required, Validators.minLength(8)],
            ],
            email: [contact.email, [Validators.required]],
          })
        );
      });
    }
    control.push(this.emptyContactsFormBuilder(data));
  }

  private emptyContactsFormBuilder(data): FormGroup {
    return this.formBuilder.group({
      id: [null],
      companyId: [data.id],
      phone: [null, [Validators.required]],
      email: [null, [Validators.required]],
    });
  }

  // Locations
  private locationsFormBuilder(locations, data): FormGroup {
    const control = this.formLocations;
    if (locations && locations.length) {
      return locations.forEach((location) => {
        control.push(
          this.formBuilder.group({
            id: [location.id],
            companyId: [data.id],
            country: [location.country, [Validators.required]],
            city: [location.city, [Validators.required]],
            street: [location.street, [Validators.required]],
            zip: [location.zip, [Validators.required]],
          })
        );
      });
    }
    control.push(this.emptyLocationsFormBuilder(data));
    return;
  }

  private emptyLocationsFormBuilder(data): FormGroup {
    return this.formBuilder.group({
      id: [null],
      companyId: [data.id],
      country: [null, [Validators.required]],
      city: [null, [Validators.required]],
      street: [null, [Validators.required]],
      zip: [null, [Validators.required]],
    });
  }

  private response(response) {
    this.notification.send(
      this.language === 'en'
        ? 'Company updated successfully!'
        : 'Selskapet ble oppdatert',
      'info'
    );
    return this.disableForm();
  }
  public toggleBudget(): void {
    this.isFreeBudget = this.fb.value;

    if (!this.fb.value) {
      this.setBudget();
    } else {
      this.freeBudget();
    }
    console.log(this.formData.getRawValue());
  }

  private freeBudget() {
    if (this.master) {
      this.hp.get('quantity').clearValidators();
      this.hp.get('quantity').patchValue(0);
      this.hp.get('quantity').disable();
      this.hp.get('unlimited').patchValue(true);
    } else {
      this.hp.get('unlimited').patchValue(true);
      this.hp.disable();
      this.lp.disable();
    }
  }

  private setBudget() {
    if (this.master) {
      this.hp.get('quantity').enable();
      this.hp.get('quantity').patchValue(null);
      this.hp.get('unlimited').patchValue(false);
      this.lowPriceBudgetChange();
    } else {
      this.hp.get('unlimited').patchValue(false);
      this.hp.enable();
      this.hp.get('discount').disable();
    }
  }

  private lowPriceBudgetChange() {
    this.lp
      .get('discount')
      .valueChanges.pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(() => {
        if (this.lp.get('discount').value > 0) {
          this.lp.get('quantity').setValidators([Validators.required]);
          this.lp.get('quantity').updateValueAndValidity();
        } else {
          this.lp.get('quantity').setValidators(null);
          this.lp.get('quantity').updateValueAndValidity();
        }
      });
  }
  private highPriceBudgetChange() {
    this.hp
      .get('discount')
      .valueChanges.pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(() => {
        if (!this.isFreeBudget) {
          if (this.hp.get('discount').value > 0) {
            this.hp.get('quantity').setValidators([Validators.required]);
            this.hp.get('quantity').updateValueAndValidity();
          } else {
            this.hp.get('quantity').setValidators(null);
            this.hp.get('quantity').updateValueAndValidity();
          }
        }
      });
  }

  private vatNumberChange() {
    this.formData
      .get('vat')
      .valueChanges.pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((value) => {
        if (this.company.vat === value) {
          this.formData.get('vat').clearAsyncValidators();
          this.formData.get('vat').updateValueAndValidity();
        } else {
          // const asyncValidator = AsyncValidator.vatValidator(this.authService);
          // this.formData.get('vat').setAsyncValidators(asyncValidator);
          this.formData.get('vat').updateValueAndValidity();
        }
      });
  }
  private storageChange() {
    this.languageSubjectService.watchStorage().subscribe((lang: string) => {
      this.language = lang;
    });
  }
}

<div class="ec-system-container">
  <div class="ec-system-title">
    <span>{{'profile.title' | translate}}</span>
  </div>

  <form (ngSubmit)="submitForm()" [formGroup]="profileForm" *ngIf="loaded">
    <div class="ec-system-form">
      <div class="ec-system-form-group">
        <input
          formControlName="email"
          readonly
          type="text"
          class="profile-email"
          placeholder="{{'profile.placeholder.email' | translate}}"
        />
      </div>
      <div class="ec-system-form-group">
        <input
          formControlName="firstName"
          placeholder="{{'profile.placeholder.first-name' | translate}}"
          type="text"
        />
        <span
          class="form-error"
          *ngIf="profileForm.get('firstName').invalid && profileForm.get('firstName').touched"
        >
          <span *ngIf="profileForm.get('firstName').errors['required']">
            {{'profile.error.first-name-required' | translate}}
          </span>
          <span *ngIf="profileForm.get('firstName').errors['minlength']">
            {{'profile.error.length-first' | translate}}{{
            profileForm.get("firstName")["errors"]["minlength"]["requiredLength"]
            }}{{'profile.error.length-second' | translate}}{{
            profileForm.get("firstName")["errors"]["minlength"]["actualLength"]
            }}.
          </span>
        </span>
      </div>
      <div class="ec-system-form-group">
        <input
          formControlName="lastName"
          placeholder="{{'profile.placeholder.last-name' | translate}}"
          type="text"
        />
        <span
          *ngIf="profileForm.get('lastName').invalid && profileForm.get('lastName').touched"
          class="form-error"
        >
          <span *ngIf="profileForm.get('lastName').errors['required']">
            {{'profile.error.last-name-required' | translate}}
          </span>
          <span *ngIf="profileForm.get('lastName').errors['minlength']">
            {{'profile.error.length-first' | translate}}{{
            profileForm.get("lastName")["errors"]["minlength"]["requiredLength"]
            }}{{'profile.error.length-second' | translate}}{{
            profileForm.get("lastName")["errors"]["minlength"]["actualLength"]
            }}.
          </span>
        </span>
      </div>
      <!-- <div class="ec-system-form-group">
        <input
          type="text"
          formControlName="vat"
          placeholder="{{'person-profile.placeholders.vat' | translate}} {{companyStatusType ? '*' : ''}}"
        />
        <span
          class="form-error"
          *ngIf="
            profileForm.get('vat').invalid && profileForm.get('vat').touched
          "
        >
          <span *ngIf="profileForm.get('vat').errors['vatNumberIsNotAvailable']"
            >{{'company-profile.errors.vat-exist' | translate }}</span
          >
          <span *ngIf="profileForm.get('vat').errors['required']"
            >{{'person-profile.errors.vat-required' | translate}}</span
          >
        </span>
      </div> -->
      <div
        *ngFor="let item of formContacts.controls; let i = index"
        formArrayName="contacts"
        class="ec-system-form-group-array"
      >
        <div [formGroupName]="i">
          <div class="ec-system-form-group">
            <input
              formControlName="phone"
              placeholder="{{'profile.placeholder.personal-phone' | translate}}"
              type="text"
              [textMask]="{mask: mask}"
              [ecUnmask]="numberUnmask"
            />
            <span *ngIf="isTouched(item,'phone')" class="form-error">
              <span *ngIf="hasError(item,'phone')">
                {{'profile.error.phone-required' | translate}}
              </span>
            </span>
          </div>
          <div class="ec-system-form-group">
            <input
              formControlName="email"
              placeholder="{{'profile.placeholder.personal-email' | translate}}"
              type="text"
            />
          </div>
        </div>
      </div>
      <div
        *ngFor="let item of formLocations.controls; let i = index"
        formArrayName="locations"
        class="ec-system-form-group-array"
      >
        <div [formGroupName]="i">
          <div class="ec-system-form-group">
            <input
              formControlName="country"
              placeholder="{{'profile.placeholder.country' | translate}}"
              type="text"
            />
            <span *ngIf="isTouched(item,'country')" class="form-error">
              <span *ngIf="hasError(item,'country')">
                {{'profile.error.country-required' | translate}}
              </span>
            </span>
          </div>
          <div class="ec-system-form-group">
            <input
              formControlName="street"
              placeholder="{{'profile.placeholder.street' | translate}}"
              type="text"
            />
            <span *ngIf="isTouched(item,'street')" class="form-error">
              <span *ngIf="hasError(item,'street')">
                {{'profile.error.street-required' | translate}}
              </span>
            </span>
          </div>
          <div class="ec-system-form-group">
            <input
              formControlName="city"
              placeholder="{{'profile.placeholder.city' | translate}}"
              type="text"
            />
            <span *ngIf="isTouched(item,'city')" class="form-error">
              <span *ngIf="hasError(item,'city')">
                {{'profile.error.city-required' | translate}}
              </span>
            </span>
          </div>
          <div class="ec-system-form-group">
            <input
              formControlName="zip"
              placeholder="{{'profile.placeholder.zip' | translate}}"
              type="text"
            />
            <span *ngIf="isTouched(item,'zip')" class="form-error">
              <span *ngIf="hasError(item,'zip')">
                {{'profile.error.zip-required' | translate}}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="ec-system-form-group">
        <label>{{'profile.label.birthday' | translate}}</label>
        <p-calendar
          [locale]="primeNGLocalDataService.en"
          [monthNavigator]="true"
          [readonlyInput]="true"
          [yearNavigator]="true"
          class="system-primeng-calendar edit-profile-modal"
          formControlName="birthday"
          inputStyleClass="system-primeng-input"
          placeholder="{{'profile.placeholder.date' | translate}}"
          styleClass="system-primeng"
          yearRange="1950:2003"
        >
        </p-calendar>
      </div>

      <div
        class="ec-system-form-group-price"
        *ngIf="roleService.role === 'master' || roleService.role === 'admin'"
      >
        <label [style.margin-bottom.px]="'20'"
          >{{'budget.individual' | translate}}</label
        >
        <label
          [style.margin-bottom.px]="'20'"
          *ngIf="profile.company.highPriceBudget.unlimited"
          >{{'edit-profile.budget.free-budget.first-part' |
          translate}}"{{profile.company.name}}"{{'edit-profile.budget.free-budget.second-part'
          | translate}}</label
        >
        <div formGroupName="highPriceBudget" class="ec-system-form-group-array">
          <div
            class="ec-system-form-group inline-label"
            [style.margin-bottom.px]="'20'"
          >
            <input
              type="text"
              formControlName="quantity"
              class="readonly-input"
              readonly
            />
            <label
              >{{'edit-profile.budget.user-regular-price' | translate}}</label
            >
          </div>
          <div class="ec-system-form-group inline-label">
            <input
              type="number"
              id="hightPriceQuantity"
              formControlName="userQuantity"
              ecDigitsOnly
              min="0"
              [max]="highPriceQuantity"
              title="{{'tooltips.system.edit-profile.budget' | translate}}"
            />
            <label for="hightPriceQuantity"
              >{{'edit-profile.budget.set-budget-regular' | translate}}</label
            >
          </div>
        </div>
        <div
          formGroupName="lowPriceBudget"
          class="ec-system-form-group-array"
          *ngIf="roleService.role === 'master'"
        >
          <div
            class="ec-system-form-group inline-label"
            [style.margin-bottom.px]="'20'"
          >
            <input
              type="text"
              formControlName="quantity"
              class="readonly-input"
              readonly
            />
            <label>{{'edit-profile.budget.user-low-price' | translate}}</label>
          </div>
          <div class="ec-system-form-group inline-label">
            <input
              type="number"
              id="lowPriceQuantity"
              formControlName="userQuantity"
              ecDigitsOnly
              min="0"
              title="{{'tooltips.system.edit-profile.budget' | translate}}"
            />
            <label for="lowPriceQuantity"
              >{{'edit-profile.budget.set-budget-low' | translate}}</label
            >
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="profileForm.enabled"
      class="ec-system-form-group multiple-buttons modal"
    >
      <button (click)="cancel()" type="button">
        {{'action-button.cancel' | translate}}
      </button>
      <button type="submit" [disabled]="profileForm.invalid || sending">
        <span *ngIf="!sending">{{'action-button.save' | translate}}</span>
        <ec-preloader *ngIf="sending"></ec-preloader>
      </button>
    </div>
  </form>
</div>

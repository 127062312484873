<div [class.visible]="visible" class="sidebar">
  <div class="sidebar-header">
    <div class="title">
      <div class="name">
        <ec-icon [icon]="icon" [size]="30">
          <h4>{{data?.name ? data.name : data?.guide?.name}}</h4>
        </ec-icon>
      </div>
      <div (click)="hide()" *ngIf="close" class="icon close">
        <ec-icon icon="close"></ec-icon>
      </div>
    </div>
    <div class="action">
      <button
        (click)="action()"
        title="{{'tooltips.system.right-side-bar.' + button | translate}}"
        *ngIf="data?.guide ? data.guide.resourceAttached : data?.resourceAttached"
      >
        {{'right-sidebar.' + button | translate}}
      </button>
      <button (click)="editCourse()" *ngIf="role.isMaster() && adminButton">
        {{'upload.new.guide.edit-title' | translate}}
      </button>
    </div>
  </div>
  <div class="sidebar-content">
    <ng-content></ng-content>
  </div>
</div>

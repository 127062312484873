import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular/main';

@Component({
  selector: 'ec-status-cell',
  templateUrl: './status.component.html',
})
export class StatusComponent implements ICellRendererAngularComp {
  value;
  private params: any;
  tooltipText: string;

  agInit(params: any): void {
    this.params = params;
    if (this.params && this.params.value) {
      this.value = this.params.value;
      this.tooltip(this.value);
    }
  }

  refresh(): boolean {
    return false;
  }
  tooltip(status) {
    switch (status) {
      case 'PAUSED':
        this.tooltipText = 'tooltips.system.qualified-courses-table.paused';
        break;
      case 'COMPLETED':
        this.tooltipText = 'tooltips.system.qualified-courses-table.completed';
        break;
      case 'ON APPROVAL':
        this.tooltipText =
          'tooltips.system.qualified-courses-table.on-approval';
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ec-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
})
export class ViewerComponent implements OnInit {
  @Input() path;

  constructor() {
  }

  ngOnInit(): void {
  }

}

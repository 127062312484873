import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGuide } from '@shared/models/upload/upload.models';
import { CookiesService } from '@shared/services/cookie.service';

@Component({
  selector: 'ec-guide-information-sidebar',
  templateUrl: './guide-information-sidebar.component.html',
  styleUrls: ['./guide-information-sidebar.component.scss'],
})
export class GuideInformationSidebarComponent implements OnInit {
  @Input() data: IGuide;
  @Input() prices;
  @Input() buttonDisabled: boolean = true;
  @Input() sendAnswer: boolean = false;
  @Input() startCourse: boolean = false;
  @Output() onClose: EventEmitter<null> = new EventEmitter(null);
  @Output() onAction: EventEmitter<any> = new EventEmitter();
  public user = JSON.parse(this.cookieService.get('User'));
  public budget: string | number;
  public price;
  constructor(private cookieService: CookiesService) {}

  ngOnInit(): void {
    this.coursesLeft();
    this.coursesPrice();
  }

  onStart() {
    this.onAction.emit();
  }

  private coursesLeft() {
    if (this.user.lowPriceBudget && this.user.lowPriceBudget.quantity) {
      this.budget = this.user.lowPriceBudget.quantity;
      this.price = this.user.lowPriceBudget.price.amount;
    } else if (
      this.user.highPriceBudget &&
      this.user.highPriceBudget.quantity
    ) {
      this.budget = this.user.highPriceBudget.quantity;
      this.price = this.user.highPriceBudget.price.amount;
    } else if (
      this.user.company.lowPriceBudget &&
      this.user.company.lowPriceBudget.quantity
    ) {
      this.budget = this.user.company.lowPriceBudget.quantity;
      this.price = this.user.company.lowPriceBudget.price.amount;
    } else if (
      this.user.company.highPriceBudget.quantity &&
      !this.user.company.highPriceBudget.unlimited
    ) {
      this.budget = this.user.company.highPriceBudget.quantity;
      this.price = this.user.company.highPriceBudget.price.amount;
    } else if (this.user.company.highPriceBudget.unlimited) {
      this.budget = 'unlimited';
      this.price = 'unlimited';
    }
  }

  private coursesPrice() {
    if (this.data.lowPrice) {
      this.price = this.data.lowPrice;
    } else if (this.data.regularPrice) {
      this.price = this.data.regularPrice;
    } else if (
      this.user.lowPriceBudget &&
      this.user.lowPriceBudget.price.amount
    ) {
      this.price = this.user.lowPriceBudget.price.amount;
    } else if (
      this.user.highPriceBudget &&
      this.user.highPriceBudget.price.amount
    ) {
      this.price = this.user.highPriceBudget.price.amount;
    } else if (
      this.user.company.lowPriceBudget &&
      this.user.company.lowPriceBudget.price.amount
    ) {
      this.price = this.user.company.lowPriceBudget.price.amount;
    } else if (
      this.user.company.highPriceBudget &&
      this.user.company.highPriceBudget.price.amount
    ) {
      this.price = this.user.company.highPriceBudget.price.amount;
    }
  }
}

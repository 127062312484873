import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@shared/guards/auth.guard';
import { RoleGuard } from '@shared/guards/role.guard';
import { UnAuthGuard } from '@shared/guards/un-auth.guard';
import { PageNotFoundComponent } from '@shared/pages/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import(`./auth/auth.module`).then(m => m.AuthModule),
    canLoad: [UnAuthGuard],
    canActivate: [UnAuthGuard],
  },
  {
    path: 'system',
    loadChildren: () =>
      import(`./system/system.module`).then(m => m.SystemModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'console',
    loadChildren: () =>
      import(`./console/console.module`).then(m => m.ConsoleModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: 1,
    },
  },
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
})
export class AppRouting {
}

export const RESPONSE_STATUSES = {
  NOT_UNIQUE: 409,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  INSERTED: 201,
  OK: 200,
};

export const DEFAULT_SEARCH_SET = {
  page: 1,
  limit: 4200,
  search: '',
  sortBy: 'created_at',
  sortMethod: 'DESC',
  certificated: false,
};

export const ROLES_ARRAY = [
  'Master',
  'Company Admin',
  'Operators Admin',
  'Operator',
];

export const COMPANY_TYPES = ['COMPANY', 'INDEPENDENT'];

export const COMPANY_PAYMENT_STATUSES = [
  'INVOICE',
  'CARD',
  'CREDIT',
  'ADVANCE',
];

export const SEARCH_PLACEHOLDER = {
  '/system/list': 'search.placeholders.course-database',
  '/console/courses': 'search.placeholders.course-database',
  '/system/signed': 'search.placeholders.passing-process',
  '/console/invoice': 'Search in invoices',
  '/system/users': 'search.placeholders.users',
  '/system/user-list': 'search.placeholders.users',
  '/console/operators': 'Search in operators',
  '/console/roles': 'search.placeholders.users',
  '/system/companies': 'Search in companies',
  '/console/companies': 'Search in companies',
};

export const ALL_ROLES = [
  {
    id: 1,
    type: 'Oslo Admin',
    checked: false,
    translate: 'roles-container.roles.master',
  },
  {
    id: 2,
    type: 'Company Admin',
    checked: false,
    translate: 'roles-container.roles.admin',
  },
  {
    id: 4,
    type: 'Operator',
    checked: false,
    translate: 'roles-container.roles.operator',
  },
];

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSubjectService } from '../../../../service/language.subject.service';
import { ApiMainService } from '@shared/services/api.main.service';
import { Router } from '@angular/router';

interface ILangConf {
  visible: boolean;
  languages: {
    title: string;
    value: string;
  }[];
}

@Component({
  selector: 'ec-language-changer',
  templateUrl: './language-changer.component.html',
  styleUrls: ['./language-changer.component.scss'],
})
export class LanguageChangerComponent implements OnInit {
  i18n: ILangConf = {
    visible: true,
    languages: [
      { title: 'Norsk (Norwegian)', value: 'no' },
      { title: 'English (United Kingdom)', value: 'en' },
    ],
  };

  constructor(
    private translate: TranslateService,
    private languageSubjectService: LanguageSubjectService,
    private apiMain: ApiMainService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  selectLanguage(language: string): void {
    this.translate.use(language);
    this.i18n.languages.push(this.i18n.languages.shift());
    language === 'en'
      ? this.languageSubjectService.setEng()
      : this.languageSubjectService.setNor();
    const currentUrl = this.router.url.split('/');
    if (currentUrl.includes('system')) {
      this.apiMain
        .userLanguage({ lang: language === 'en' ? 'en' : 'no' })
        .subscribe(() => {});
    }
  }

  show(): void {
    this.i18n.visible = true;
  }

  hide(): void {
    this.i18n.visible = false;
  }
}

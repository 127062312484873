import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular/main';

@Component({
  selector: 'ec-indicator-cell',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent implements ICellRendererAngularComp {
  status: number;
  private params: any;

  agInit(params: any): void {
    this.params = params;
    if (this.params && this.params.value) {
      this.status = this.params.value;
    }
  }

  refresh(): boolean {
    return false;
  }
}

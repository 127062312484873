import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ApiMainService } from '@shared/services/api.main.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '@shared/services/notification.service';
import { RoleService } from '@shared/services/role.service';
import { LanguageSubjectService } from 'app/service/language.subject.service';

@Component({
  selector: 'ec-deleting-action',
  templateUrl: './deleting-action.component.html',
  styleUrls: ['./deleting-action.component.scss'],
})
export class DeletingActionComponent implements ICellRendererAngularComp {
  private params: any;
  public value;
  private language: string = localStorage.getItem('Language')
  ? localStorage.getItem('Language')
  : 'no';
  constructor(
    private apiMainService: ApiMainService,
    private notification: NotificationService,
    private roleService: RoleService,
    private languageSubjectService: LanguageSubjectService
  ) {}

  agInit(params: any): void {
    this.params = params.value;
    if (this.params) {
      this.value = this.params.deleted_at;
    }
    this.storageChange();
  }

  valueChanged(e) {
    console.log(this.value);
    this.value = this.value ? null : new Date();
    this.value ? this.deleteAction() : this.returnAction();
  }

  refresh(params: any): boolean {
    return false;
  }

  permissions() {
    return (
      this.roleService.masterRole() ||
      (this.roleService.adminRole() &&
        +this.roleService.user.companyId === +this.params.companyId)
    );
  }

  self() {
    return +this.roleService.user.id !== +this.params.id;
  }

  private deleteAction() {
    this.apiMainService.suspendUser(this.params.id).subscribe((res) => {
      if (!(res instanceof HttpErrorResponse)) {
        this.notification.send(
          this.language === 'en'
            ? `User ${this.params.firstName} ${this.params.lastName} suspended...`
            : `Brukeren ${this.params.firstName} ${this.params.lastName} er suspendert`,
          'warning'
        );
      }
    });
  }

  private returnAction() {
    this.apiMainService.returnUser(this.params.id).subscribe((res) => {
      if (!(res instanceof HttpErrorResponse)) {
        this.notification.send(
          this.language === 'en'
            ? `User ${this.params.firstName} ${this.params.lastName} returned successfully!`
            : `Brukeren ${this.params.firstName} ${this.params.lastName} er tilbakesatt`,
          'info'
        );
      }
    });
  }
  private storageChange() {
    this.languageSubjectService.watchStorage().subscribe((lang: string) => {
      this.language = lang;
      console.log('shluha');
    });
  }
}
